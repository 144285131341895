// TODO: comment
// @ts-nocheck

import React from 'react';

export const ValidationContext = React.createContext();

export const useValidationContext = () => React.useContext(ValidationContext);

export const ValidationProvider = ({ children, schema }) => {
  const [describedSchema, setDescribedSchema] = React.useState();
  // Not used in the demo, but maybe usefull
  // if we ever need the described schema
  const describeSchema = React.useCallback(() => {
    schema && setDescribedSchema(schema.describe());
  }, [schema]);

  return (
    <ValidationContext.Provider
      value={{ schema, describeSchema, describedSchema }}
    >
      {children}
    </ValidationContext.Provider>
  );
};
