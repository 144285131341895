// TODO: comment
export const MESSAGE_BREAKER: string =
  '---------------------------------------------';

/**
 * There is the ability to autorefresh the list, if a value is defined, the list will refresh every 'x' milliseconds. Setting it to zero will cause autorefresh to be disabled
 */
export const LIST_REFRESH_INTERVAL: number = 1 * 60 * 1000;

export const BrandColours = [
  '#0057B7',
  '#73206D',
  '#008164',
  '#E24082',
  '#000000',
  '#D9D9D6',
  '#00205B',
  '#41263B',
  '#BC0056',
  '#EBECEE',
];

export const GREY_MEDIUM: string = '#EBECEE';
export const GREY_LIGHT: string = '#F5F5F5';
export const GREY_DARK: string = '#eee';

export const DEFAULT_CURRENCY_CODE: string = 'GBP';

// TODO: comment
export const STATUS_FAILED: string = 'failed';
export const STATUS_SUCCESS: string = 'success';
export const STATUS_LOADING: string = 'loading';
//
export const STATUS_ERROR: string = 'error';
export const STATUS_INIT_ERROR: string = 'init-error';
export const STATUS_DATA_LOADING: string = 'data-loading';
export const STATUS_SENT: string = 'sent';
export const STATUS_SENDING: string = 'sending';

export enum AppState {
  Error = 'error',
  Failed = 'failed',
  Loading = 'loading',
  Success = 'success',
}

export enum States {
  Error = 'error',

  Idle = 'idle',
  Loaded = 'loaded',
  Loading = 'loading',
  Success = 'success',
}
