// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest, withQuery } from '../utils';

/**
 *
 * @param clientId
 * @param valuationDate
 * @param format
 * @returns
 */
export const getClientAccountAccountsDownload = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId?: string | null | undefined,
  /**
   * Gets or sets the start date.
   */
  valuationDate?: Date | null | undefined,
  /**
   * Gets or sets the format. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
): Promise<any> => {
  const response = await sendApiRequest<any>(
    getClientAccountAccountsDownloadUri(
      clientId,
      undefined,
      valuationDate,
      format
    )
  );

  return response;
};

/**
 *
 * @param clientId
 * @param valuationDate
 * @param format
 * @returns
 */
export const getClientAccountAccountsDownloadUri = (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,

  /**
   * Gets or sets the account identifier.
   */
  accountId?: string | null | undefined,

  /**
   * Gets or sets the start date.
   */
  valuationDate?: Date | null | undefined,

  /**
   *
   */
  currencyCode?: string,

  /**
   * Gets or sets the format. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
) => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/download`;

  const data: any = {
    accountId,
    format: format.toUpperCase(),
  };

  if (currencyCode) {
    data.currencyCode = currencyCode;
  }

  if (valuationDate) {
    data.valuationDate = dayjs(valuationDate).format('YYYY-MM-DD');
  }

  return withQuery(endpoint, data);
};
