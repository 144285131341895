// TODO: comment
import { sendApiRequest } from '../utils';

export const getUnreadMessageCount = async (
  /**
   *
   */
  max: number = 10
) => {
  const response = await sendApiRequest<any>(
    `${process.env.REACT_APP_API_URL}api/messages/received/unread-count`,
    {
      maximumRows: max,
    }
  );

  return response;
};
