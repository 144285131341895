// TODO: comment

import { Box, Container, Paper, Typography } from '@material-ui/core';
import { ThemeDark, ThemeDefault } from 'styles';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { selectUserDetails } from 'redux/reducers/account';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    zIndex: 0,
    background: 'no-repeat 50% 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 400,
      zIndex: 8,
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);',
    },
  },
  heading: { fontSize: 64 },
  main: { position: 'relative', zIndex: 10 },
}));

interface Props {
  children?: React.ReactNode;
}

export const Banner = ({ children }: Props) => {
  const { t } = useTranslation(['common']);

  const classes = useStyles();

  const { forename } = useSelector(selectUserDetails);

  const images = [
    'images/backgrounds/hero/hero-1.jpg',
    'images/backgrounds/hero/hero-2.jpg',
    'images/backgrounds/hero/hero-3.jpg',
    'images/backgrounds/hero/hero-4.jpg',
    'images/backgrounds/hero/hero-5.jpg',
    'images/backgrounds/hero/hero-6.jpg',
    'images/backgrounds/hero/hero-7.jpg',
  ];

  const dayOfTheWeek = new Date().getDay();

  return (
    <ThemeDark>
      <Paper>
        <Box
          className={classes.banner}
          paddingY={8}
          style={{ backgroundImage: `url(${images[dayOfTheWeek]})` }}
        >
          <Container>
            <Box paddingTop={10} className={classes.main}>
              <Box>
                <Typography
                  component="h2"
                  variant="h5"
                  style={{ marginBottom: 15 }}
                >
                  Hello {forename}
                </Typography>
                <Typography component="h1" variant="h3">
                  {t('common:titles.home')}
                </Typography>
              </Box>
              <ThemeDefault>{children}</ThemeDefault>
            </Box>
          </Container>
        </Box>
      </Paper>
    </ThemeDark>
  );
};
