// TODO: comment
import {
  Box,
  Breadcrumbs as MaterialBreadcrumbs,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Anchor } from 'components/core';
import React from 'react';
import { ThemeDark } from 'styles';

interface Item {
  href?: string;
  label: string;
}
interface Props {
  inBanner?: boolean;
  trail: Item[];
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      position: 'relative',
      // float: 'left',
      display: 'inline-block',
      paddingTop: 5,
      paddingRight: 10,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -999,
        right: '100%',
        bottom: 0,
        zIndex: 1,
        background: '#000',
      },
    },
  };
});

export const Breadcrumbs = ({ trail, inBanner = false }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const style = {
    marginTop: -theme.spacing(7),
    marginBottom: theme.spacing(7),
  };

  return (
    <ThemeDark>
      <Box style={inBanner ? style : undefined}>
        <Container disableGutters={inBanner}>
          <Paper className={classes.paper}>
            <Box padding={1}>
              <MaterialBreadcrumbs separator={'•'}>
                {trail.map(({ label, href }: Item, index: number) => {
                  if (!label) {
                    return null;
                  }

                  if (href) {
                    return (
                      <Typography
                        variant="body2"
                        component="span"
                        key={`t-${index}`}
                      >
                        <Anchor color="inherit" to={href} key={label}>
                          {label}
                        </Anchor>
                      </Typography>
                    );
                  }

                  return (
                    <Typography
                      color="textPrimary"
                      key={`t-${index}`}
                      variant="body2"
                      component="span"
                    >
                      {label}
                    </Typography>
                  );
                })}
              </MaterialBreadcrumbs>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeDark>
  );
};
