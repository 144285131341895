import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';

import { EmbeddedVideo } from '@abrdn-latest/core';
import { ExternalAnchor } from 'components/core';
import { PictureAsPdfIcon } from 'icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  border: {
    border: '1px solid #D9D9D6',
  },
}));

export const SustainableInvesting = () => {
  const classes = useStyles();

  // const { data, error } = useFetch(
  //   'https://www.abrdn.com/en/uk/adviser/articlesapi/articles/thinkingaloudrelatedarticlesdata?dataSourceItemId=%7b2C63E96A-9D17-443C-A5E4-07B31002EAAD%7d&currentArticleItemId=&articleTagsIds=%7b06365395-3B00-4F45-A826-73C16DBC438D%7d&articleCategoriesIdsParam=&top=2&skip=0',
  //   { mode: 'cors' }
  // );

  const documents = [
    {
      title: 'Sustainable Investing – our approach',
      url: 'https://www.abrdn.com/docs?editionId=957c6ea4-1d86-4610-8559-82f883342f59',
    },
    // {
    //   title: 'Read our Sustainable Investing Story',
    //   url: 'https://www.abrdn.com/docs?editionId=5ebe6c65-5535-409a-a92e-8ed6aaeb9008',
    // },
  ];

  return (
    <Paper>
      <Container>
        <Box paddingY={8}>
          {/* video */}
          <Grid container spacing={3} wrap="wrap">
            <Grid item xs={12} md={6}>
              <Typography variant="h4">
                Investing for a sustainable tomorrow
              </Typography>

              <Typography paragraph>
                We have elevated our sustainability efforts across the company.
                That’s because we believe the consideration of ESG factors
                results in more constructive engagement and better informed
                investment decisions – helping our clients achieve their
                financial objectives
              </Typography>

              {documents.map(({ title, url }, index: number) => (
                <Box key={`doc-${index}`} marginY={3}>
                  <Paper className={classes.border}>
                    <Box padding={2}>
                      <Grid
                        container
                        spacing={3}
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <Typography variant="h5" style={{ margin: 0 }}>
                            <ExternalAnchor
                              to={url}
                              label={title}
                              icon={false}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box textAlign="right">
                            <PictureAsPdfIcon />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Box>
              ))}
            </Grid>

            <Grid item xs={12} md={6}>
              <EmbeddedVideo
                title=""
                src="https://www.youtube.com/embed/RcSHk1khAvo"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};
