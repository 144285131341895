// TODO: comment
import { Box, BoxProps, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
  elevation?: number;
  paper?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid #D9D9D6',
  },
}));

export const AbrdnBox = ({
  paper = true,
  children,
  elevation = 0,
  ...rest
}: Props) => {
  const classes = useStyles();

  if (paper) {
    return (
      <Paper className={classes.paper} elevation={elevation}>
        <Box padding={3} {...rest}>
          {children}
        </Box>
      </Paper>
    );
  }

  return (
    <Box padding={3} {...rest}>
      {children}
    </Box>
  );
};
