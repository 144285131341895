// TODO: comment
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@abrdn';
import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FundHolding, FundResponse, FundState } from 'api/types';
import { ChartKeySwatch } from 'components/chart';
import { Pagination } from 'components/pagination';
import { ArrowDropDownIcon } from 'icons';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { formatNumber } from 'utils';

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof FundHolding;
  label: string;
  numeric: boolean;
  sortable?: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'instrumentAssetClass',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Asset class',
  },
  {
    id: 'securityDescription',
    numeric: false,
    disablePadding: false,
    label: 'Security description',
  },
  { id: 'isin', numeric: false, disablePadding: false, label: 'ISIN' },
  {
    id: 'marketValueAmountFundCurrency',
    numeric: true,
    disablePadding: false,
    sortable: true,
    label: 'Mkt value',
  },
  {
    id: 'marketValuePercentage',
    numeric: true,
    disablePadding: false,
    label: 'Mkt value %',
  },
  {
    id: 'countryOfIssueCode',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Country of issue',
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof FundHolding
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof FundHolding) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                IconComponent={ArrowDropDownIcon}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <b>{headCell.label}</b>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      border: 'none',
    },
    footercell: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    totalValue: {
      color: '#000',
    },
  })
);

interface Props {
  data: FundResponse;
  fundCode: string;
  mapping?: any;
  onChange(state: FundState): void;

  search?: string | undefined;
}

export const PortfolioHoldingsTable = ({
  data,
  fundCode,
  onChange,
  mapping = {},
  search,
}: Props) => {
  const results = data.data.holdings;

  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof FundHolding>(
    'instrumentAssetClass'
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof FundHolding
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const lookup = {
      instrumentAssetClass: 'assetclass',
      marketValueAmountFundCurrency: 'marketvalue',
      countryOfIssueCode: 'countryofissue',
    };

    onChange({
      pageSortOrder: isAsc ? 'desc' : 'asc',
      // @ts-ignore
      pageSortBy: lookup[property],
    });
  };

  const onPagination = (pageNumber: number, rows: number): void => {
    onChange({ pageNumber });
  };

  const onPageChange = (pageSize: number) => {
    onChange({ pageSize });
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} size={'medium'}>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {results.map((row: FundHolding, index: number) => {
              const string = search
                ? search.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&')
                : search;

              const regEx: any = new RegExp(string || '', 'i');

              return (
                <TableRow tabIndex={-1} key={`i-${index}`}>
                  <TableCell component="th" scope="row">
                    {mapping && mapping[row.instrumentAssetClass] && (
                      <ChartKeySwatch
                        colour={mapping[row.instrumentAssetClass]}
                        size="small"
                      />
                    )}

                    {row.instrumentAssetClass}
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      spacing={3}
                      wrap="nowrap"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: search
                              ? row.securityDescription.replace(
                                  regEx,
                                  (match: string) => {
                                    return `<b>${match}</b>`;
                                  }
                                )
                              : row.securityDescription,
                          }}
                        />
                      </Grid>

                      {row.securityFundCode &&
                        row.securityDescription !== fundCode && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{
                                whiteSpace: 'nowrap',
                              }}
                              to={`/portfolio-holdings/${row.securityFundCode}`}
                              component={RouterLink}
                            >
                              View Holdings
                            </Button>
                          </Grid>
                        )}
                    </Grid>

                    {row.securityFundCode}
                  </TableCell>
                  <TableCell>
                    {row.isin ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: search
                            ? row.isin.replace(regEx, (match: string) => {
                                return `<b>${match}</b>`;
                              })
                            : row.isin,
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {typeof row.marketValueAmountFundCurrency === 'number'
                      ? formatNumber(
                          row.marketValueAmountFundCurrency,
                          data.data.fundBaseCurrencyCode
                        )
                      : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {typeof row.marketValuePercentage === 'number'
                      ? `${(row.marketValuePercentage * 100).toFixed(2)}%`
                      : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {row.countryOfIssueCode || '-'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {data.paging.pageNumber === data.paging.totalPages - 1 && (
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="right"
                  className={classes.footercell}
                >
                  <Typography className={classes.totalValue}>
                    Total Mkt value{' '}
                    <b>
                      {data.data.fundBaseCurrencyCode}{' '}
                      {formatNumber(
                        data.data.totalMarketValueAmountFundCurrency,
                        data.data.fundBaseCurrencyCode
                      )}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell
                  className={classes.footercell}
                  colSpan={2}
                ></TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      <Pagination
        onPagination={onPagination}
        onPageChange={onPageChange}
        rowsPerPage={data.paging.pageSize}
        startRowIndex={data.paging.pageNumber * data.paging.pageSize}
        maximumRows={data.paging.pageSize}
        minimumTotalRows={data.paging.totalRecords}
        totalRows={data.paging.totalRecords}
        rowsPerPageOptions={[25, 50, 75, 100]}
      />
    </div>
  );
};
