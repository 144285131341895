// TODO: comment
export const formatNumber = (
  /**
   *
   */
  value: number,
  /**
   *
   */
  currency: string = 'GBP',
  /**
   *
   */
  decimalPlaces: number = 2
): string => {
  return value.toLocaleString('en-GB', {
    currency,
    minimumFractionDigits: decimalPlaces,
  });
};
