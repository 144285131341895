// TODO: comment
import { Error403, Error404, Error500 } from '@abrdn-latest/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface Params {
  code?: string;
}

const PageError = () => {
  const { t } = useTranslation(['common']);

  // get the possible routes
  const params: Params = useParams();
  const code: string = params?.code || '404';

  return (
    <>
      <Helmet>
        <title>{t(`common:titles.error-${code}`)}</title>
      </Helmet>

      {code === '404' && <Error404 />}
      {code === '403' && <Error403 />}
      {code === '500' && <Error500 />}
    </>
  );
};

export default PageError;
