// TODO: comment
import { Progress } from 'components/ui';
import { toast } from 'material-react-toastify';
import React from 'react';
import { sendApiRequest } from '../utils';

export const createDocument = async (
  /**
   *
   */
  file: any,
  /**
   *
   */
  description: string,
  /**
   *
   */
  filename: string,
  /**
   *
   */
  mimeType: string
) => {
  const toastId = toast.dark(<Progress label={`Uploading ${filename}`} />, {
    autoClose: false,
  });

  try {
    const response = await sendApiRequest<any>(
      `${process.env.REACT_APP_API_URL}api/documents`,
      {
        description,
        filename,
        mimeType,
      },
      {
        method: 'POST',
        body: file,
        headers: { 'Content-Type': 'application/octet-stream' },
      }
    );

    // update the toast message to show the success message
    toast.update(toastId, {
      type: toast.TYPE.SUCCESS,
      render: `Uploaded ${filename}`,
      autoClose: 5000,
    });

    return response;
  } catch (e) {
    // there has been an error uploading the document; update the toast message
    toast.update(toastId, {
      type: toast.TYPE.ERROR,
      render: `Error uploading ${filename}`,
      autoClose: 5000,
    });

    // pass on the error
    throw new Error('DOCUMENT UPLOAD ERROR');
  }
};
