// TODO: comment
import { SentMessage } from '../types';
import { sendApiRequest } from '../utils';
/**
 * Get a message from the users sent items
 */
export const getSentMessage = async (
  /**
   * Message ID
   */
  id: string
): Promise<SentMessage> => {
  const response = await sendApiRequest<SentMessage>(
    `${process.env.REACT_APP_API_URL}api/messages/sent/${id}`
  );
  return response;
};
