// TODO: comment
import {
  STATUS_FAILED,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { sendOTP, verifyOTP } from 'api';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { ValidationProvider } from 'components/file-selector';
import { Form, Input } from 'components/form';
import { Progress } from 'components/ui';
import { ChevronLeftIcon } from 'icons';
import { FormMessage } from 'interfaces';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AuthProps } from './';

interface Props extends AuthProps {}

const useStyles = makeStyles(() => ({
  secondary: {
    textDecoration: 'underline',
    borderRadius: 0,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
  },
}));

export const OneTimePasswordForm = ({ hideTitle, onComplete }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation(['common']);

  const { authState, authMethods } = useSecurity();

  // router
  const router = useRouter();

  const OTP_MESSAGE: FormMessage = {
    type: 'info',
    message:
      'We have sent a one time verification code to your registered email. Please enter this code below to complete your login.',
  };

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(OTP_MESSAGE);

  const [formStatus, setFormStatus] = useState('');

  //
  //
  // one time password functionality

  const handleSendOneTimePassword = async () => {
    try {
      // request a one time password to be sent to the user
      const result = await sendOTP();

      if (result) {
        setMessage(OTP_MESSAGE);

        toast.success(
          'Please check your registered email for your one time verification code.'
        );
      } else {
        setMessage({
          type: 'error',
          message: `There was an error sending your one time code, please try again`,
        });
      }
    } catch (e: any) {}
  };

  //
  //

  const schema = yup.object().shape({
    otp_code: yup
      .string()
      .required('Code is required')
      .length(6, 'The code should be 6 digits'),
  });

  const [data, setData] = useState({
    otp_code: '',
  });

  const methods = useForm({
    defaultValues: {
      otp_code: data.otp_code,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors } = methods;

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    verifyOTP(data.otp_code)
      .then((res) => {
        toast.success(t('common:forms.login.messages.toast-success'));

        setMessage({
          type: 'success',
          message: t('common:forms.otp.messages.success'),
        });

        setFormStatus(STATUS_SENT);

        if (onComplete) {
          onComplete();
        }

        authMethods.setScreens(res);
      })
      .catch((err) => {
        setFormStatus(STATUS_FAILED);

        setMessage({
          type: 'error',
          message: t('common:forms.otp.messages.error'),
        });
      });
  };

  useEffect(() => {
    toast.success(
      'Please check your registered email for your one time verification code.'
    );
  }, []);

  const handlePaste = (ev: React.KeyboardEvent) => {
    // setTimeout(() => {
    //   try {
    //     // @ts-ignore
    //     ev.target.value = String(ev.target.value).trim();
    //   } catch (e) {}
    // }, 4);
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  if (authState.isFullyAuthenticated) {
    router.history.replace('/');
    //  return null;
  }

  const handleOTPCancel = async (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    await authMethods.signOut();
  };

  return (
    <Fragment>
      {!hideTitle && (
        <Typography variant="h4" component="h3">
          {t('common:forms.otp.title')}
        </Typography>
      )}

      {message && (
        <Box marginY={2}>
          <Alert severity={message.type}>{message.message}</Alert>
        </Box>
      )}

      {formStatus !== STATUS_SENT && (
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Box marginY={4}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Input
                      ref={register}
                      id="otp_code"
                      type="text"
                      label="One Time Code"
                      name="otp_code"
                      error={!!errors.otp_code}
                      helperText={errors?.otp_code?.message}
                      autoComplete="off"
                      onPaste={handlePaste}
                      inputProps={{
                        pattern: '[0-9]*',
                        inputmode: 'numeric',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      disabled={formStatus === STATUS_SENDING}
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      Verify code{' '}
                      {formStatus === STATUS_SENDING && <Progress />}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormProvider>
        </ValidationProvider>
      )}

      <Typography variant="body2" paragraph>
        If you have not received an email with the one time verification code,{' '}
        <Anchor
          onClick={handleSendOneTimePassword}
          style={{ fontSize: 'inherit' }}
        >
          click here
        </Anchor>{' '}
        for a new code to be sent.
      </Typography>

      <Button
        size="small"
        onClick={handleOTPCancel}
        startIcon={<ChevronLeftIcon />}
        className={classes.secondary}
      >
        Back
      </Button>
    </Fragment>
  );
};
