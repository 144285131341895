// TODO: comment
import { sendApiRequest, withQuery } from '../utils';

export const getPortfolioHoldingDownload = async (
  /**
   * The fund code
   */
  fundCode: string,
  /**
   * The valuation date. Optional. Defaults to latest available
   */
  valuationDate?: string,
  /**
   * The download format. Optional. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
): Promise<any> => {
  const response = await sendApiRequest<any>(
    getPortfolioHoldingDownloadUri(fundCode, valuationDate, format)
  );
  return response;
};

export const getPortfolioHoldingDownloadUri = (
  /**
   * The fund code
   */
  fundCode: string,
  /**
   * The valuation date. Optional. Defaults to latest available
   */
  valuationDate?: string,
  /**
   * The download format. Optional. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
) => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/portfolio-holdings/funds/${fundCode}/download`;

  const data: any = {
    valuationDate,
    format,
  };

  return withQuery(endpoint, data);
};
