// TODO: comment
import { getReceivedMessage } from 'api';
import { ReceivedMessage } from '../types';

/**
 * Get the received message from the action required section
 */
export const getActionRequiredMessage = async (
  /**
   * Message ID
   */
  id: string
): Promise<ReceivedMessage> => {
  return getReceivedMessage(id);
};
