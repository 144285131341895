// TODO: comment
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface DebugProps {
  data: any;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  pre: {
    padding: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
}));

export const Debug = ({ title, data }: DebugProps) => {
  const classes = useStyles();

  if (process.env.NODE_ENV === 'development' && data) {
    if (data.constructor === Object && Object.entries(data).length === 0) {
      return null;
    }

    return (
      <Box paddingY={2}>
        <Container>
          {title && <Typography variant="h5">{title}</Typography>}
          <pre className={classes.pre}>{JSON.stringify(data, null, 2)}</pre>
        </Container>
      </Box>
    );
  }

  return null;
};
