// TODO: comment
// https://github.com/leecade/react-native-swiper/issues/163
import { useRouter } from '@abrdn-latest/use';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ForgottenPasswordForm, LoginForm, RegisterForm } from './';
import { useSecurity } from './SecurityContext';

interface Params {
  view?: string;
}

export const AuthenticationWidget = () => {
  const useSlider: boolean = false;
  const [view, setView] = useState<number>(0);

  const [registered, setRegistered] = useState<boolean>(false);

  const params: Params = useParams();

  const { authState } = useSecurity();

  // router
  const router = useRouter();

  const views = {
    login: 0,
    register: 1,
    'forgotten-password': 2,
    'change-password': 3,
  };
  // @ts-ignore
  const page: number =
    // @ts-ignore
    params.view && views[params.view] !== undefined
      ? // @ts-ignore
        views[params.view]
      : views.login;

  let instance: any;

  useEffect(() => {
    if (instance && useSlider) {
      instance.slideTo(page, 0);
    }
  }, []);

  useEffect(() => {
    if (authState.isFullyAuthenticated) {
      router.history.replace('/');
    }
  }, [authState.isFullyAuthenticated]);

  const handleLoginClick = (ev: React.SyntheticEvent): void => {
    ev.preventDefault();

    if (instance && useSlider) {
      instance.slideTo(0);
    } else {
      setView(0);
    }
  };

  const handleRegisterClick = (ev: React.SyntheticEvent): void => {
    ev.preventDefault();

    if (instance && useSlider) {
      instance.slideTo(1);
    } else {
      setView(1);
    }
  };

  const handleResetClick = (ev: React.SyntheticEvent): void => {
    ev.preventDefault();

    if (instance && useSlider) {
      instance.slideTo(2);
    } else {
      setView(2);
    }
  };

  const handleLoginSuccess = () => {
    // TODO: maybe logic
    router.history.replace('/');
  };

  const handleRegisterSuccess = () => {
    setRegistered(true);

    if (instance && useSlider) {
      instance.slideTo(0);
    } else {
      setView(0);
    }
  };
  const handleForgottenPasswordSuccess = () => {};

  if (!useSlider) {
    return (
      <Fragment>
        {view === 0 && (
          <LoginForm
            registered={registered}
            onResetClick={handleResetClick}
            onRegisterClick={handleRegisterClick}
            onComplete={handleLoginSuccess}
          />
        )}

        {view === 1 && (
          <RegisterForm
            onLoginClick={handleLoginClick}
            onComplete={handleRegisterSuccess}
          />
        )}

        {view === 2 && (
          <ForgottenPasswordForm
            onLoginClick={handleLoginClick}
            onComplete={handleForgottenPasswordSuccess}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Swiper
      onSwiper={(swiper) => {
        if (!instance) {
          instance = swiper;
        }
      }}
      autoHeight
      spaceBetween={0}
      slidesPerView={1}
      allowTouchMove={false}
    >
      <SwiperSlide>
        <LoginForm
          registered={registered}
          onResetClick={handleResetClick}
          onRegisterClick={handleRegisterClick}
          onComplete={handleLoginSuccess}
        />
      </SwiperSlide>
      <SwiperSlide>
        <RegisterForm
          onLoginClick={handleLoginClick}
          onComplete={handleRegisterSuccess}
        />
      </SwiperSlide>
      <SwiperSlide>
        <ForgottenPasswordForm
          onLoginClick={handleLoginClick}
          onComplete={handleForgottenPasswordSuccess}
        />
      </SwiperSlide>
    </Swiper>
  );
};
