export const isIE = (): boolean => {
  // @ts-ignore
  if (navigator.userAgentData) {
    return false;
  } else {
    return (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1
    );
  }
};
