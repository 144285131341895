// TODO: comment
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

/**
 * Jump the user to the top of the page on page refresh, this fixes an issue
 * where page navigation results in the scrollbar staying in the same place
 */
const Component = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

const ScrollToTop = withRouter(Component);

export { ScrollToTop };
