// TODO: comment
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  active?: boolean;
  formatter?(label: string, payload: any): string;
  label?: string;
  payload?: any[];
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 3,
    color: '#fff',
    background: '#000',
    // background:
    //   'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00205B',
  },
}));

export const CustomTooltip = ({ active, payload, label, formatter }: Props) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <Paper elevation={2} className={classes.paper}>
        <Box padding={2} maxWidth={230}>
          <Typography variant="body2">
            <span
              dangerouslySetInnerHTML={{
                __html: formatter
                  ? formatter(label || '', payload[0])
                  : `${payload[0].payload.title}<br />${Number(
                      payload[0].value
                    ).toFixed(2)}%`,
              }}
            />
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};
