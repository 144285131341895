// TODO: comment
import { getMessagesPerPage } from '../constants';
import { GetReceivedMessagesResponse } from '../types';
import { sendApiRequest } from '../utils';
/**
 * Get an array of received messages from the users inbox
 */
export const getReceivedMessages = async (
  /**
   * Row offset of accounts
   */
  start: number = 0,
  /**
   * Maximum items to return in the request
   */
  max: number = getMessagesPerPage()
): Promise<GetReceivedMessagesResponse> => {
  const response = await sendApiRequest<GetReceivedMessagesResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/received`,
    {
      startRowIndex: start,
      maximumRows: max,
    }
  );
  return response;
};
