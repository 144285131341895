import {
  DocumentOnlyClientDetails, getAllParties,
  getClientSummary,
  getDocumentOnlyClientDetails,
  UserClientDetail,
  UserClientDetails
} from 'api';
import { ClientAccountSummary } from 'api/types';
import { useSecurity } from 'authentication';
import React, { useEffect, useState } from 'react';
import { ClientHoldingsProvider } from './';
import { States } from '../../../@abrdn-latest/config';

interface Props {
  /**
   *
   */
  children: React.ReactNode;
}

let cache: any = {};

let clientList: UserClientDetails;

/**
 * Client Holdings universal methods
 */
export const ClientHoldings = ({ children }: Props) => {
  const { authState, settings } = useSecurity();

  // object to hold details on the
  const clientDetails: { [key: string]: Promise<ClientAccountSummary> } = {};

  // all the client holdings that the user has
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<UserClientDetails>([]);
  const [documentOnlyClients, setDocumentOnlyClients] =
    useState<DocumentOnlyClientDetails>([]);

  // action to keep track of the account currencies available for all funds an holdings
  const [clientCurrencies, setClientCurrenciesState] = useState<any>({});

  const setClientCurrencies = (
    clientId: string,
    accountCurrencies: string[],
    prepend: boolean = false
  ): void => {
    // get any currencies that have already been populated
    const stored = cache[clientId] || [];

    // combine the currencies
    const combined = [
      ...new Set(
        prepend
          ? [...accountCurrencies, ...stored]
          : [...stored, ...accountCurrencies]
      ),
    ];

    // update the currencies
    cache = {
      ...clientCurrencies,
      ...{
        [clientId]: combined,
      },
    };

    if (cache !== clientCurrencies[clientId]) {
      setClientCurrenciesState(cache);
    }
  };

  /**
   * This is only supposed to be called on the homepage for the overview,
   * we do it this way so the data can be stored and doesn't need to be re-requested
   * @param clientId
   */
  const getClientHoldingSummary = async (
    /**
     * Gets or sets the client identifier.
     */
    clientId: string,
    /**
     * Gets or sets the valuation date.
     */
    valuationDate?: Date | null | undefined,
    /**
     * Gets or sets the currency code.
     */
    currencyCode?: string | null
  ): Promise<ClientAccountSummary | null> => {
    try {
      const key = `${clientId}-${valuationDate}-${currencyCode}`;

      if (clientDetails[key]) {
        return clientDetails[key];
      }

      // get the client details
      const details = getClientSummary(
        clientId,
        valuationDate,
        currencyCode
      );

      clientDetails[key] = details;

      return details;
    } catch (e) {}

    // return no details
    return null;
  };

  const getUsersClientDetails = async (): Promise<UserClientDetails> => {
    if (clientList) {
      return clientList;
    }

    // get all the parties
    const parties = await getAllParties();

    //
    const blankList: UserClientDetail = {
      ...{ id: '', preferredName: '', currencyCodes: [], clientReference: '' },
      ...{
        state: States.Idle,
        accounts: [],
        client: {
          id: '',
          name: '',
        },
        currencyCodes: [],
        valuation: {
          currencyCode: '',
          date: '',
          value: 0,
        },
      },
    };

    clientList = [];

    // loop through all the parties and populate them with blank data
    for (let counter = 0; counter < parties.length; counter++) {
      clientList.push({ ...blankList, ...parties[counter] });
    }

    return clientList;
  };

  const getPartiallyPopulatedClients = async (): Promise<any> => {
    // get all the parties
    const clients = await getUsersClientDetails();
    const partiallyPopulatedClients = []

    const clientDetails = await Promise.allSettled(
      clients.map(client => {
        return client.state === States.Idle
          ? getClientHoldingSummary(client.id)
          : null;
      })
    );

    // loop through the parties, to populate
    for (let i = 0; i < clients.length; i++) {
      const client = clients[i];
      const detail = clientDetails[i];

      if (detail.status === 'fulfilled') {
        if (detail.value) {
          partiallyPopulatedClients.push({
            ...client,
            ...detail.value,
            state: States.Success,
          });
        } else {
          partiallyPopulatedClients.push(client);
        }
      } else {
        partiallyPopulatedClients.push({
          ...client,
          state: States.Error,
        });
      }
    }

    clientList = partiallyPopulatedClients;

    return partiallyPopulatedClients;
  };


  // on load, load the holdings, it's important to know how many holdings the
  // client has, so we can hide navigation if there are none, or make the loading
  // on the homepage a little more efficient
  useEffect(() => {
    const load = async () => {
      try {
        // feature detection
        if (settings['ClientHoldings.Enabled']) {
          // load all the client holdings assiciated with the user
          const [clients, documentOnlyClients] = await Promise.all([
            getUsersClientDetails(),
            getDocumentOnlyClientDetails(),
          ]);

          setClients(clients);
          setDocumentOnlyClients(documentOnlyClients);
        }
      } catch (e) {} finally {
        setLoading(false);
      }
    };

    if (authState.isFullyAuthenticated) {
      load();
    }
  }, [authState.isFullyAuthenticated]);

  useEffect(() => {
    const load = async () => {
      try {
        // feature detection
        if (settings['ClientHoldings.Enabled']) {
          // load all the client holdings assiciated with the user with details
          const clients = await getPartiallyPopulatedClients();

          setClients(clients);
        }
      } catch (e) {} finally {
        setLoading(false);
      }
    };

    if (!loading) {
      load();
    }
  }, [loading]);

  // wrapping component
  return (
    <ClientHoldingsProvider
      value={{
        loading,
        clients,
        documentOnlyClients,
        getClientHoldingSummary,
        clientCurrencies,
        setClientCurrencies,
      }}
    >
      {children}
    </ClientHoldingsProvider>
  );
};
