import { Button, Container } from '@material-ui/core';
import { PopupApp, PopupState } from 'components/popup-app';
import React, { Fragment, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { InfoIcon } from 'icons';
import { ThemeDefault } from 'styles';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: '-20px 0 0 0',
      padding: '3px 8px 3px 14px',
      fontSize: 16,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' && '#3f3f3f',
      },
    },
  })
);

export const AdditionalInfo = ({ children, className }: Props) => {
  const classes = useStyles();

  const [uploadPopupState, setUploadPopupState] =
    useState<PopupState>('closed');

  const handleClickOpen = () => {
    setUploadPopupState(uploadPopupState === 'open' ? 'closed' : 'open');
  };

  return (
    <Fragment>
      <Button
        className={`${classes.button} ${className}`}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        endIcon={<InfoIcon style={{ fontSize: 30 }} />}
      >
        Info
      </Button>

      <ThemeDefault>
        <PopupApp
          state={uploadPopupState}
          title={'Additional Info'}
          showUIMinimise={false}
          maxHeight={600}
          disableScreen
          onClose={() => {
            setUploadPopupState('closed');
          }}
          onStateChange={(state) => {
            setUploadPopupState(state);
          }}
        >
          <Container disableGutters maxWidth="xs">
            <Fragment>{children}</Fragment>
          </Container>
        </PopupApp>
      </ThemeDefault>
    </Fragment>
  );
};
