// TODO: comment
import { useWidth } from '@abrdn-latest/use';
import { Box, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { ThemeDark } from 'styles';

interface Props {
  children: React.ReactNode;
  imagePath?: string;
}

export const Banner = ({ children, imagePath }: Props) => {
  const { isSmall } = useWidth();

  const useStyles = makeStyles(() => ({
    banner: {
      position: 'relative',
      zIndex: 0,
      background: `#1C2939`,
    },

    bannerImage: {
      background: `#1C2939 url(${imagePath}) no-repeat 50% 50%`,
      backgroundSize: 'cover',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: '40%',
        bottom: 0,
        zIndex: 10,
        background:
          'linear-gradient(90deg, #1C2939 66.15%, rgba(28, 41, 57, 0) 100%)',
      },
    },
    bannerMobile: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9,
        background: 'rgba(28, 41, 57, .5)',
      },
    },
    main: { position: 'relative', zIndex: 10 },
  }));

  const classes = useStyles();

  return (
    <ThemeDark>
      <Paper>
        <Box
          paddingY={7}
          className={clsx(classes.banner, {
            [classes.bannerImage]: !!imagePath,
            [classes.bannerMobile]: isSmall,
          })}
        >
          <Container>
            <Box className={classes.main}>{children}</Box>
          </Container>
        </Box>
      </Paper>
    </ThemeDark>
  );
};
