// TODO: comment
import { useRouter } from '@abrdn-latest/use';
import { useSecurity } from 'authentication';
import React from 'react';
import { Route, RouteProps, useRouteMatch } from 'react-router-dom';

const SecureRoute: React.FC<
  {} & RouteProps & React.HTMLAttributes<HTMLDivElement>
> = ({ ...routeProps }) => {
  const match = useRouteMatch(routeProps);
  const { authState } = useSecurity();
  const router = useRouter();

  React.useEffect(() => {
    if (!match) {
      return;
    }

    if (!authState.isFullyAuthenticated) {
      router.history.replace('/auth/login');
    }
  }, [match, authState.isFullyAuthenticated]);

  if (!authState.isFullyAuthenticated) {
    return null;
  }

  return <Route {...routeProps} />;
};

export { SecureRoute };
