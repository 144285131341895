// TODO: comment
import {
  STATUS_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { isIE } from '@abrdn/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { createMessage, uploadDocuments } from 'api';
import { DocumentUploadRestrictionsResponse } from 'api/types';
import { useSecurity } from 'authentication';
import { Confirmation } from 'components/confirmation';
import { FileSelector, ValidationProvider } from 'components/file-selector';
import { Form, Select, Textarea } from 'components/form';
import { SendIcon } from 'icons';
import { toast } from 'material-react-toastify';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  getDocumentRestrictions,
  selectDocumentRestrictions,
} from 'redux/reducers/documents';
import {
  getMessageSubjects,
  selectMessageSubjects,
} from 'redux/reducers/messages';
import { delayFire } from 'utils';
import * as validation from 'validation';
import * as yup from 'yup';

interface Props {
  subjectDisplayText?: string;
}

export const NewMessageForm = ({ subjectDisplayText = '' }: Props) => {
  const { t } = useTranslation(['common']);

  const { authState } = useSecurity();
  const router = useRouter();

  const [formStatus, setFormStatus] = useState('');
  const subjects = useSelector(selectMessageSubjects);
  const [redirect, setRedirect] = useState('');

  const restrictions: DocumentUploadRestrictionsResponse = useSelector(
    selectDocumentRestrictions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.isFullyAuthenticated) {
      delayFire(() => {
        // TODO: change this maybe
        if (subjects.length === 0) {
          dispatch(getMessageSubjects());
        }
        // TODO: maybe change this
        if (restrictions.fileSizeLimitBytes === 0) {
          dispatch(getDocumentRestrictions());
        }
      });
    }
  }, [authState.isFullyAuthenticated]);

  const schema = yup.object().shape({
    subject: yup.string().required('Subject is a required field'),
    message: yup
      .mixed()
      .required('Message is a required field')
      .test('notblank', 'Message is a required field', (value) => {
        return String(value) !== '';
      })
      .test('html', 'Please enter valid characters only', validation.noHTML),
    files: yup.array().of(
      yup.object().shape({
        description: yup
          .mixed()
          .required('Document description is required')
          .test('notblank', 'Document description is required', (value) => {
            return String(value) !== '';
          })
          .test(
            'validCharacters',
            'Please enter valid characters only',
            validation.validCharacters
          ),
        file: yup
          .mixed()
          .required('A file is required')
          .test('fileRequired', 'Please select a file', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return true;
            } catch (e) {
              return true;
            }
          })
          .test('fileSize', 'The file is too large', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size < restrictions.fileSizeLimitBytes;
            } catch (e) {
              return true;
            }
          })
          .test('fileSizeSmall', `The file has not contents`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size > 0;
            } catch (e) {
              return true;
            }
          })
          .test('fileMIMEType', `Invalid file format`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return restrictions.allowedMimeTypes.includes(value[0].type);
            } catch (e) {
              return true;
            }
          })
          .test('fileExtension', `Unsupported file extension`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              const extension = value[0].name.match(/.([0-9a-z]+)$/i);

              return restrictions.allowedFileExtensions.includes(
                extension[1].toLowerCase()
              );
            } catch (e) {
              return true;
            }
          }),
      })
    ),
  });

  const [data, setData] = useState({
    subject: '',
    message: '',
    files: [],
  });

  const methods = useForm({
    defaultValues: {
      subject: data.subject,
      message: data.message,
      files: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    if (subjects && subjects.length && subjectDisplayText) {
      const filtered = subjects.filter(
        (item: any) => item.displayText === subjectDisplayText
      );

      if (filtered.length === 1) {
        // TODO: check that the field gets populated
        setData({ ...data, ...{ subject: filtered[0].id } });

        setValue('subject', filtered[0].id);
      }
    }

    // subjectDisplayText
  }, [subjects]);

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    try {
      const fileIds = await uploadDocuments(data.files);

      createMessage(data.subject, data.message, fileIds)
        .then((json: any) => {
          toast.success(t('common:forms.new-message.messages.toast-success'));

          setFormStatus(STATUS_SENT);

          // TODO: possibly clear the form message after x seconds
          reset();

          // if popup, minimise, and hide status messages
        })
        .catch(function () {
          setFormStatus(STATUS_ERROR);
        });
    } catch (e) {
      setFormStatus(STATUS_ERROR);
    }
  };

  const isPhysicalPage = (): boolean => {
    return router.pathname.indexOf('/new') > -1;
  };

  const goBack = () => {
    if (isPhysicalPage()) {
      if (router.history.length) {
        router.history.goBack();
      } else {
        setRedirect('../');
      }
    }
  };

  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);
  const onConfirm = () => {
    goBack();
  };
  const onReject = () => {
    setConfirmMessageOpen(false);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const isFormFilled = (): boolean => {
    const values = getValues();

    return !(
      values.message === '' &&
      values.subject === '' &&
      values.files !== null
    );
  };

  return (
    <Fragment>
      <Confirmation
        isOpen={confirmMessageOpen}
        confirmText="Yes, cancel"
        onConfirm={onConfirm}
        rejectText="No, continue"
        onReject={onReject}
      >
        <Typography paragraph>
          {t('common:forms.new-message.messages.cancel')}
        </Typography>
      </Confirmation>

      {formStatus === STATUS_SENT && (
        <Alert severity="success">
          {t('common:forms.new-message.messages.success')}
        </Alert>
      )}

      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {formStatus === STATUS_ERROR && (
              <Alert severity="error">
                {t('common:forms.new-message.messages.error')}
              </Alert>
            )}
            <Select
              ref={register}
              control={control}
              // @ts-ignore
              id="subject"
              label="Subject"
              name="subject"
              options={subjects.map((item: any, index: number) => {
                return { displayText: item.displayText, value: item.id };
              })}
              error={!!errors.subject}
              helperText={errors?.subject?.message}
            />

            <Textarea
              ref={register}
              id="message"
              type="text"
              label="Message"
              name="message"
              variant={isIE() ? 'filled' : 'outlined'}
              error={!!errors.message}
              helperText={errors?.message?.message}
            />
            <FileSelector />
            <Grid container spacing={3} alignItems="stretch">
              {isPhysicalPage() && (
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Button
                      size="large"
                      variant="outlined"
                      disabled={formStatus === STATUS_SENDING}
                      onClick={() => {
                        if (isFormFilled()) {
                          setConfirmMessageOpen(true);
                        } else {
                          goBack();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={isPhysicalPage() ? 6 : 12}>
                <Box textAlign="right">
                  <Button
                    size="large"
                    variant="contained"
                    disabled={formStatus === STATUS_SENDING}
                    type="submit"
                    color="primary"
                    endIcon={<SendIcon />}
                  >
                    Send Message
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </ValidationProvider>
    </Fragment>
  );
};
