// TODO: comment
const SI_SYMBOL = ['', 'k', 'mn', 'bn', 'T', 'P', 'E'];

export const abbreviateNumber = (
  number: number,
  decimalPlaces: number = 1
): string => {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) {
    return String(number);
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  const value = String(scaled.toFixed(decimalPlaces)).replace(
    /([0-9])([0]+)$/g,
    '$1'
  );

  // format number and add suffix
  return `${value}${suffix}`;
};
