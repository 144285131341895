// TODO: comment
import { on } from '@abrdn/utils/events';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Unauthorized = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    on('api:unauthorized', () => {
      setOpen(true);
    });
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Paper>
        <Box padding={4} textAlign="center">
          <Typography variant="h4">You have been signed out</Typography>
          <Typography paragraph>
            This may be due to inactivity or you might have another instance of
            abrdn Connect open – to log back in please use the below
          </Typography>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            variant="contained"
            color="primary"
            size="large"
          >
            Reload the page
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};
