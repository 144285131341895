// TODO: comment
import { Banner } from '@abrdn/banner';
import { Box, Container, Typography } from '@material-ui/core';
import { IFrame } from 'components/iframe';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PageFundCentre = () => {
  const { t } = useTranslation(['common']);

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.fund-centre')}</title>
      </Helmet>

      <Banner imagePath="images/backgrounds/fund-center.jpg">
        <Typography variant="h3" component="h1">
          {t('common:titles.fund-centre')}
        </Typography>
      </Banner>

      <Box paddingY={6}>
        <Container>
          <IFrame src="https://www.abrdn.com/en/clientengagementhub/fund-centre-landing-page" />
        </Container>
      </Box>
    </Fragment>
  );
};

export default PageFundCentre;
