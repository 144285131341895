import { Box, Grid, Typography } from '@material-ui/core';
import { FundSummaryResponse } from 'api/types';
import { BarChart, ChartData, PieChart } from 'components/chart';
import React from 'react';

const content = {
  title: 'Fund composition',
  assetClassTitle: 'By Asset Class',
  countryOfIssueTitle: 'By Country of Issue',
};

interface Props {
  assetMapping: any;
  summary: FundSummaryResponse | undefined;
}

export const FundSummary = ({ summary, assetMapping = {} }: Props) => {
  const drawAssetClass: boolean = !!(
    summary &&
    summary.instrumentAssetClassSummary &&
    summary.instrumentAssetClassSummary.length > 0
  );

  const drawCountry: boolean = !!(
    summary &&
    summary.countryOfIssueSummary &&
    summary.countryOfIssueSummary.length > 0
  );

  if (!drawAssetClass && !drawCountry) {
    return null;
  }

  return (
    <Box paddingBottom={2}>
      <Typography variant="h4">{content.title}</Typography>
      <Grid container spacing={8}>
        {summary && drawAssetClass && (
          <Grid item xs={12} md={6}>
            <BarChart
              title={content.assetClassTitle}
              mapping={assetMapping}
              data={
                summary.instrumentAssetClassSummary.map((item) => {
                  return {
                    title: item.instrumentAssetClass,
                    value: item.totalMarketValuePercentage * 100,
                  };
                }) as ChartData[]
              }
            />
          </Grid>
        )}
        {summary && drawCountry && (
          <Grid item xs={12} md={6}>
            <PieChart
              title={content.countryOfIssueTitle}
              data={
                summary.countryOfIssueSummary.map((item) => {
                  return {
                    title: item.countryOfIssueName,
                    value: item.totalMarketValuePercentage * 100,
                  };
                }) as ChartData[]
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
