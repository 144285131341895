// TODO: comment
import clsx from 'clsx';
import React from 'react';
import './DataList.scss';

type List = {
  [key: string]: string | number | boolean | null | undefined;
};

interface Props {
  horizontal?: boolean;
  items: List;
}

export const DataList = ({ items, horizontal = false }: Props) => {
  return (
    <dl
      className={clsx('datalist', {
        'datalist--h': horizontal,
        'datalist--v': !horizontal,
      })}
    >
      {Object.keys(items).map((key: any, index: number) => (
        <div key={`item-${index}`}>
          <dt>{key}</dt>
          <dd>{items[key]}</dd>
        </div>
      ))}
    </dl>
  );
};
