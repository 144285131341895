// TODO: comment

import { sendApiRequest } from '../utils';

/**
 * Represents the response to a request to mark a received message as being "read"
 */
export interface MarkReceivedMessageAsReadResponse {
  /**
   * Gets or sets the identifier of the message
   */
  id: string;
}

/**
 * Mark a received message as read
 */
export const setMessageAsRead = async (
  /**
   * Message ID
   */
  id: string
): Promise<MarkReceivedMessageAsReadResponse> => {
  const response = await sendApiRequest<MarkReceivedMessageAsReadResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/received/${id}/mark-as-read`,
    null,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return response;
};
