// TODO: comment
import { LineClamp } from '@abrdn-latest/core';
import { Box, Chip, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExternalAnchor } from 'components/core';
import React from 'react';
import { getFormattedDate } from 'utils';

const useStyles = makeStyles(() => ({
  tile: {
    border: '1px solid #D9D9D6',
  },

  imageBanner: {
    position: 'relative',
  },

  chips: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },

  chip: {
    fontSize: '12px',
    padding: 12,
    backgroundColor: '#0057B7',
    marginRight: 8,
  },
}));

interface Props {
  date: string;
  excerpt?: string;
  image?: string;
  readTime: string;
  title: string;
  url: string;
}

export const ArticlePreview = ({
  image,
  title,
  url,
  excerpt,
  readTime,
  date,
}: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.tile}>
      <Box className={classes.imageBanner}>
        <img src={image} alt={title} />
        <Box className={classes.chips} padding={2}>
          <Chip
            className={classes.chip}
            size="small"
            color="secondary"
            label={getFormattedDate(date)}
          />
          <Chip
            className={classes.chip}
            size="small"
            color="secondary"
            label={`${readTime}`}
          />
        </Box>
      </Box>

      <Box padding={2}>
        <Typography variant="h4" style={{ marginBottom: 15 }}>
          <ExternalAnchor to={url} icon={false} label={title} />
        </Typography>

        {excerpt && (
          <Typography variant="body2">
            <LineClamp count={3}>{excerpt}</LineClamp>
          </Typography>
        )}
      </Box>
    </Paper>
  );
};
