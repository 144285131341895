// TODO: comment

import { Badge, IconButton, List, Tooltip } from '@material-ui/core';
import { getUnreadCount, selectUnreadCount } from 'redux/reducers/messages';
import { useDispatch, useSelector } from 'react-redux';

import { ListItemLink } from 'components/navigation';
import { MailIcon } from 'icons';
import { NavLink } from 'react-router-dom';
import { UserDetails } from 'components/user-details';
import { delayFire } from 'utils';
import { useClientHoldings } from 'pages/client-holdings/data';
import { useEffect } from 'react';
import { useSecurity } from 'authentication';
import { useTranslation } from 'react-i18next';

interface Props {
  isMobile?: boolean;
}

export const PrimaryNavigation = ({ isMobile }: Props) => {
  // TODO
  const { clients } = useClientHoldings();

  const { t } = useTranslation(['common']);

  const unreadCount = useSelector(selectUnreadCount);

  const dispatch = useDispatch();
  const { authState, settings } = useSecurity();

  useEffect(() => {
    if (authState.isFullyAuthenticated) {
      delayFire(() => {
        dispatch(getUnreadCount());
      });
    }
  }, [authState.isFullyAuthenticated]);

  return (
    <List>
      <ListItemLink exact={true} to="/" primary={t('nav.home')} />
      <ListItemLink
        isTopLevel
        to="/our-thinking"
        primary={t('nav.our-thinking')}
      />
      <ListItemLink isTopLevel to="/esg" primary={t('nav.esg')} />
      <ListItemLink
        isTopLevel
        to="/fund-centre"
        primary={t('nav.fund-centre')}
      />
      <ListItemLink
        isTopLevel
        to="/portfolio-holdings"
        primary={t('nav.portfolio-holdings')}
      />
      <ListItemLink isTopLevel to="/documents" primary={t('nav.documents')} />
      {/* if client holdings is enabled, and there are accounts to show */}
      {settings['ClientHoldings.Enabled'] && clients.length > 0 && (
        <ListItemLink
          isTopLevel
          to={`/accounts/${clients[0].id}/accounts`}
          primary={t('nav.accounts')}
        />
      )}

      {isMobile ? (
        <ListItemLink
          isTopLevel
          to="/messages/inbox"
          primary={t('nav.messages')}
          iconEnd={
            unreadCount ? (
              <Badge badgeContent={unreadCount} color="error">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )
          }
        />
      ) : (
        <Tooltip title="Messages">
          <IconButton
            component={NavLink}
            to="/messages/inbox"
            style={{ color: 'inherit' }}
          >
            {unreadCount ? (
              <Badge badgeContent={unreadCount} color="error">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )}
          </IconButton>
        </Tooltip>
      )}

      {!isMobile && <UserDetails />}
    </List>
  );
};
