// TODO: comment
import { FundSummaryResponse } from 'api/portfolio-holdings/types';
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';

/**
 * Gets a summary of holdings for the specified client/account, optionally at the specified valuation date.
 */
export const getClientAccountSummary = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId: string,
  /**
   * Gets or sets the valuation date.
   */
  valuationDate?: Date | null | undefined,
  /**
   * Gets or sets the size of the instrument asset class.
   */
  instrumentAssetClassSize?: number,
  /**
   * Gets or sets the size of the country of issue.
   */
  countryOfIssueSize?: number
): Promise<FundSummaryResponse> => {
  // endpoint
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}/holdings/summary`;

  // data to post
  const data: any = {};

  if (valuationDate) {
    data.valuationDate = dayjs(valuationDate).format('YYYY-MM-DD');
  }

  if (instrumentAssetClassSize) {
    data.instrumentAssetClassSize = instrumentAssetClassSize;
  }

  if (countryOfIssueSize) {
    data.countryOfIssueSize = countryOfIssueSize;
  }

  // send the request, and wait for the response
  const response = await sendApiRequest<FundSummaryResponse>(endpoint, data);

  return response;
};
