// TODO: comment
import { Menu, MenuItem } from '@material-ui/core';
import { ExportButton } from 'components/ui';
import { InfoIcon } from 'icons';
import React, { useState } from 'react';

interface Props {
  /**
   *
   */
  formats?: string[];

  /**
   *
   * @param type
   */
  onSelect(type: string): void;
}

/**
 *
 */
export const Exporter = ({ onSelect, formats = ['XLSX'] }: Props) => {
  const label = 'Download';

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (type: string) => {
    handleClose();

    onSelect(type);
  };

  if (formats.length === 1) {
    return (
      <ExportButton
        label={label}
        onClick={() => {
          onSelect(formats[0]);
        }}
      />
    );
  }

  return (
    <span>
      <ExportButton label={label} onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {formats.map((type) => (
          <MenuItem
            key={type}
            value={type}
            onClick={() => {
              handleDownload(type);
            }}
          >
            <InfoIcon />
            {type}
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};
