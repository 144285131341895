// TODO: comment
import { BrandColours } from '@abrdn-latest/config';
import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Cell, Pie, PieChart as Chart, Tooltip } from 'recharts';
import { ChartKey, ChartProps, CustomTooltip } from './';
import './Chart.scss';

// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
// }: any) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline="central"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

export const PieChart = ({ title, data, showKey = true }: ChartProps) => {
  const theData = data.map((item, index: number) => {
    item.color = item.color || BrandColours[index % BrandColours.length];

    return item;
  });

  return (
    <Box paddingY={2}>
      <Typography variant="h5">{title}</Typography>

      <Grid container spacing={3}>
        {showKey && (
          <Grid item xs={12} sm={4}>
            <ChartKey data={theData} />
          </Grid>
        )}
        <Grid item xs={12} sm={showKey ? 8 : 12}>
          <Box position="relative" maxWidth={250}>
            <Chart width={250} height={250}>
              <Tooltip content={<CustomTooltip />} />
              <Pie
                data={theData}
                cx="50%"
                cy="50%"
                labelLine={false}
                //label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                nameKey="title"
              >
                {theData.map((item, index) => (
                  <Cell key={`cell-${index}`} fill={item.color} />
                ))}
              </Pie>
            </Chart>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
