// TODO: comment
// @ts-nocheck

import { PopupState } from 'components/popup-app';
import React, { createContext, useContext, useReducer } from 'react';

interface State {
  props?: any;

  state: PopupState;
}

const MessageComposerContext = createContext<State>({ state: 'closed' });
const MessageComposerDispatch = createContext(null);

const messageComposerReducer = (state, action) => {
  switch (action.type) {
    case 'open': {
      return { state: 'open', props: action.props || {} };
    }
    case 'closed': {
      return { state: 'closed' };
    }
    case 'minimised': {
      return { state: 'minimised' };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

interface Props {
  children: React.ReactNode;
}

const MessageComposerProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(messageComposerReducer, {
    state: 'closed',
  });
  return (
    <MessageComposerContext.Provider value={state}>
      <MessageComposerDispatch.Provider value={dispatch}>
        {children}
      </MessageComposerDispatch.Provider>
    </MessageComposerContext.Provider>
  );
};

const useMessageComposerState = () => {
  const context = useContext(MessageComposerContext);
  if (context === undefined) {
    throw new Error(
      'useMessageComposerState must be used within a MessageComposerProvider'
    );
  }
  return context;
};

const useMessageComposerDispatch = () => {
  const context = useContext(MessageComposerDispatch);
  if (context === undefined) {
    throw new Error(
      'useMessageComposerDispatch must be used within a MessageComposerProvider'
    );
  }
  return context;
};

export {
  MessageComposerProvider,
  useMessageComposerState,
  useMessageComposerDispatch,
};
