// TODO: comment
import { BrandColours } from '@abrdn-latest/config';
import { isIE } from '@abrdn/utils';
import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useMeasure } from 'react-use';
import {
  Bar,
  BarChart as Chart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartKey, ChartProps, CustomTooltip } from './';
import './Chart.scss';

export const BarChart = ({
  title,
  data,
  showKey = true,
  mapping = {},
}: ChartProps) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const theData = data.map((item, index: number) => {
    item.color =
      item.color ||
      mapping[item.title] ||
      BrandColours[index % BrandColours.length];

    return item;
  });

  // get the smallest value in the array
  const minValue = Math.min.apply(
    null,
    theData.map((item) => item.value)
  );

  // get the smallest value in the array
  const maxValue = Math.max.apply(
    null,
    theData.map((item) => item.value)
  );

  const range = Math.abs(minValue) + Math.abs(maxValue);
  const dec = Math.ceil(range / 100) * 100;

  const increments: any = {
    '100': 20,
    '200': 40,
    '300': 50,
    '400': 100,
    '500': 100,
    '600': 100,
  };

  const INCREMENT: number = increments[dec.toString()] || 100;

  const min: number =
    minValue < 0
      ? -Math.ceil((Math.abs(minValue) + 1) / INCREMENT) * INCREMENT
      : 0;
  const max: number = Math.ceil((maxValue + 1) / INCREMENT) * INCREMENT;

  let ticks: number[] = [];

  for (let counter = min; counter < max; counter += INCREMENT) {
    ticks.push(counter);
  }

  const IS_IE: boolean = isIE();

  return (
    <Box paddingY={2}>
      <Typography variant="h5">{title}</Typography>
      <div ref={ref}>
        <Grid container spacing={3}>
          {showKey && (
            <Grid item xs={12} sm={4}>
              <ChartKey data={theData} />
            </Grid>
          )}
          <Grid item xs={12} sm={showKey ? 8 : 12}>
            <Box position="relative">
              <Chart
                width={IS_IE ? 300 : width / 2}
                height={250}
                data={theData}
                margin={{
                  top: 15,
                  right: 0,
                  left: 0,
                  bottom: 15,
                }}
              >
                <CartesianGrid stroke="#e5e5eb" vertical={false} />
                <XAxis
                  dataKey="title"
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={() => {
                    return '';
                  }}
                />
                <YAxis
                  ticks={ticks}
                  interval={0}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(v) => {
                    return `${v}%`;
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="value" barSize={12} radius={[6, 6, 0, 0]}>
                  {data.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={BrandColours[index % BrandColours.length]}
                    />
                  ))}
                </Bar>
              </Chart>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
