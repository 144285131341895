// TODO: comment
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Help } from 'icons';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#DDE5ED',

    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    transform: 'translateY(8px)',
  },
}));

interface Props {
  children: React.ReactNode;
  title: string;
}
export const HelpBox = ({ title, children }: Props) => {
  const classes = useStyles();

  return (
    <Box padding={3} marginY={2} borderRadius={2} className={classes.root}>
      {title && (
        <Typography variant="h5" style={{ marginBottom: 15 }}>
          <Help className={classes.icon} /> {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
