// TODO: comment
import { deleteReceivedMessage } from 'api';
import { toast } from 'material-react-toastify';

export const deleteReceivedMessages = async (
  /**
   *
   */
  ids: string[]
): Promise<string[]> => {
  const deletedIds: string[] = [];

  for (let counter = 0; counter < ids.length; counter++) {
    try {
      const resp: any = await deleteReceivedMessage(ids[counter], false);

      if (resp) {
        deletedIds.push(resp.id);
      } else {
      }
    } catch (e) {}
  }

  if (ids.length > 1) {
    toast.success(`Messages deleted`);
  } else {
    toast.success(`Message deleted`);
  }

  return new Promise((resolve, reject) => {
    if (!ids || deletedIds.length === ids.length) {
      resolve(deletedIds);
    } else {
      reject('Failed to delete message(s)');
    }
  });
};
