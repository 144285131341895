// TODO: comment
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow as MuiTableRow,
  TableSortLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableSortLabel,
};

export const TableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(MuiTableCell);

export const TableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: '#eee',
    },
    '&:nth-of-type(even)': {
      //backgroundColor: theme.palette.action.hover,
      backgroundColor: '#E0E0E0',
    },
  },
}))(MuiTableRow);
