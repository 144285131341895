import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ExcelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path
        fill="black"
        d="M16.5006 21.4998L14.4006 21.3998L13.2006 18.3998L12.0006 21.1998L10.1006 21.0998L12.2006 15.9998L10.2006
         10.9998H12.0006L13.2006 13.7998L14.4006 10.8998L16.5006 10.7998L14.3006 16.0998L16.5006 21.4998Z"
      />
      <path
        fill="black"
        d="M26.0004 6.3999H21.7004C21.6004 5.5999 21.0004 4.8999 20.2004 4.8999L6.30039 5.9999C5.50039 5.9999 4.90039
         6.6999 4.90039 7.6999V24.2999C4.90039 25.1999 5.50039 25.9999 6.20039 25.9999L20.2004 26.9999C20.9004 26.9999
         21.5004 26.3999 21.6004 25.6999H26.0004C26.6004 25.6999 27.0004 25.2999 27.0004 24.6999V7.3999C27.0004 6.8999
         26.5004 6.3999 26.0004 6.3999ZM25.0004 15.3999H21.7004V12.5999H25.0004V15.3999ZM21.7004
         16.9999H25.0004V19.6999H21.7004V16.9999ZM25.0004 10.9999H21.7004V8.3999H25.0004V10.9999ZM19.7004
         24.9999L6.90039 23.9999V7.9999L19.7004 6.9999V24.9999ZM21.7004 23.6999V21.2999H25.0004V23.6999H21.7004Z"
      />
    </SvgIcon>
  );
};
