// TODO: comment
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Anchor } from 'components/core';
import { PageErrorIcon } from 'icons';
import React, { Fragment } from 'react';

interface Props {}

export const Error404 = ({}: Props) => {
  return (
    <Fragment>
      <Box paddingY={6}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Typography variant="h2" component="h1">
                It looks like we couldn't locate this page on our server
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <PageErrorIcon style={{ width: 150, height: 150 }} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h2">
            Error code: 404
          </Typography>

          <Typography variant="h4" component="h3">
            Unfortunately this url is not recognised, please try again or return
            to home
          </Typography>
          <Typography paragraph>
            We couldn't find the page you were looking for, this may be becuse
            the link isn't working or the page has been moved.
          </Typography>

          <Typography paragraph>
            If you typed the website address (URL) you could check the spelling
            and try again, or visit our homepage and try your search again
          </Typography>

          <Anchor to={`/`}>Back to home</Anchor>
        </Container>
      </Box>
    </Fragment>
  );
};
