import { Box, makeStyles } from '@material-ui/core';

import React from 'react';

interface Props {
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline' | 'strech';
  children: React.ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';

  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  spacing?: 0 | 0.5 | 1 | 2 | 3 | 4 | 8 | 12;
}

export const Stack = ({
  alignItems = 'center',
  children,
  direction = 'row',
  justifyContent,
  spacing = 0,
}: Props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems,
      flexDirection: direction,
      justifyContent,

      '& > :not(style) + :not(style)': {
        margin:
          direction === 'row'
            ? `0 0 0 ${theme.spacing(spacing)}px`
            : `${theme.spacing(spacing)}px 0 0 0`,
      },
    },
  }));

  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};
