// TODO: comment
import { DocumentType } from 'api/types';

export const getDocumentCategory = (
  key: string,
  categories: DocumentType[]
): string => {
  if (key === 'CUD') {
    return 'Uploaded';
  }

  if (categories) {
    const match: DocumentType | undefined = categories.find((item: any) => {
      return item.value === key;
    });

    if (match) {
      return match.displayText;
    }
  }

  return key;
};
