// TODO: comment
import {
  STATUS_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { uploadDocuments } from 'api';
import { DocumentUploadRestrictionsResponse } from 'api/types';
import { Confirmation } from 'components/confirmation';
import { FileSelector, ValidationProvider } from 'components/file-selector';
import { Form } from 'components/form';
import { PublishIcon } from 'icons';
import { FormMessage } from 'interfaces';
import React, { Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectDocumentRestrictions } from 'redux/reducers/documents';
import * as validation from 'validation';
import * as yup from 'yup';

export const NewDocumentForm = () => {
  const { t } = useTranslation(['common']);

  const [formStatus, setFormStatus] = useState('');
  const [redirect, setRedirect] = useState('');
  const router = useRouter();

  const [message, setMessage] = useState<FormMessage | null>(null);

  const restrictions: DocumentUploadRestrictionsResponse = useSelector(
    selectDocumentRestrictions
  );

  const schema = yup.object().shape({
    files: yup.array().of(
      yup.object().shape({
        description: yup
          .mixed()
          .required('Document description is required')
          .test('notblank', 'Document description is required', (value) => {
            return String(value) !== '';
          })
          .test('notblank', 'Document description is required', (value) => {
            return String(value) !== '';
          })
          .test(
            'validCharacters',
            'Please enter valid characters only',
            validation.validCharacters
          ),
        file: yup
          .mixed()
          .required('A file is required')
          .test('fileRequired', 'Please select a file', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return true;
            } catch (e) {
              return true;
            }
          })
          .test('fileSize', 'The file is too large', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size < restrictions.fileSizeLimitBytes;
            } catch (e) {
              return true;
            }
          })
          .test('fileSizeSmall', `The file has not contents`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size > 0;
            } catch (e) {
              return true;
            }
          })
          .test('fileMIMEType', `Invalid file format`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return restrictions.allowedMimeTypes.includes(value[0].type);
            } catch (e) {
              return true;
            }
          })
          .test('fileExtension', `Unsupported file extension`, (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              const extension = value[0].name.match(/.([0-9a-z]+)$/i);

              return restrictions.allowedFileExtensions.includes(
                extension[1].toLowerCase()
              );
            } catch (e) {
              return true;
            }
          }),
      })
    ),
  });

  const [data, setData] = useState({
    files: [],
  });

  const methods = useForm({
    defaultValues: {
      files: [],
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: any) => {
    setData(data);

    if (data.files && data.files.length > 0) {
      setFormStatus(STATUS_SENDING);

      try {
        const fileIds = await uploadDocuments(data.files);

        if (fileIds) {
          setFormStatus(STATUS_SENT);

          setMessage({
            type: 'success',
            message: 'Your document has been successfully uploaded',
          });

          // TODO: possibly clear the form message after x seconds
          reset();
        }
      } catch (err) {
        setFormStatus(STATUS_ERROR);

        setMessage({
          type: 'error',
          message: t('common:forms.document-upload.messages.error'),
        });
      }
    } else {
      setMessage({
        type: 'error',
        message: t('common:forms.document-upload.messages.error'),
      });
    }
  };

  const isPhysicalPage = (): boolean => {
    return router.pathname.indexOf('/new') > -1;
  };

  const goBack = () => {
    if (isPhysicalPage()) {
      if (router.history.length) {
        router.history.goBack();
      } else {
        setRedirect('../');
      }
    }
  };

  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

  const onConfirm = () => {
    goBack();
  };

  const onReject = () => {
    setConfirmMessageOpen(false);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Fragment>
      <Confirmation
        isOpen={confirmMessageOpen}
        confirmText="Yes, cancel"
        onConfirm={onConfirm}
        rejectText="No, continue"
        onReject={onReject}
      >
        <Typography paragraph>
          {t('common:forms.document-upload.messages.cancel')}
        </Typography>
      </Confirmation>

      {message && (
        <Box marginBottom={2}>
          <Alert severity={message.type}>{message.message}</Alert>
        </Box>
      )}

      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FileSelector minRows={1} />
            <Grid container spacing={3} alignItems="stretch">
              {isPhysicalPage() && (
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Button
                      size="large"
                      variant="outlined"
                      disabled={formStatus === STATUS_SENDING}
                      onClick={() => {
                        setConfirmMessageOpen(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={isPhysicalPage() ? 6 : 12}>
                <Box textAlign="right">
                  <Button
                    size="large"
                    variant="contained"
                    disabled={formStatus === STATUS_SENDING}
                    type="submit"
                    color="primary"
                    endIcon={<PublishIcon />}
                  >
                    Upload Document
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </ValidationProvider>
    </Fragment>
  );
};
