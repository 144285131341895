// TODO: comment

import { sendApiRequest } from '../utils';

interface UpdateActionRequiredRequest {
  actionRequired: boolean;
}

interface MarkReceivedMessageAsReadResponse {
  id: string;
}

/**
 *
 */
export const setActionRequired = async (
  /** Message ID */
  id: string,

  /** ... */
  payload: UpdateActionRequiredRequest
): Promise<MarkReceivedMessageAsReadResponse> => {
  const response = await sendApiRequest<MarkReceivedMessageAsReadResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/received/${id}/set-actionrequired`,
    null,
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
