// TODO: comment
export const SERVER_ACTION_DELAY: number = 4500;
export const DEFAULT_MESSAGES_PER_PAGE: number = 10;

export const getMessagesPerPage = (): number => {
  if (typeof Storage !== 'undefined') {
    const items = localStorage.getItem('messagesPerPage');

    if (items) {
      return JSON.parse(items);
    }
  }

  return DEFAULT_MESSAGES_PER_PAGE;
};

export const setMessagesPerPage = (items: number) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem('messagesPerPage', JSON.stringify(items));
  }
};

export const DEFAULT_PARTIES_PER_PAGE: number = 25;

export const getPartiesPerPage = (): number => {
  if (typeof Storage !== 'undefined') {
    const items = localStorage.getItem('partiesPerPage');

    if (items) {
      return JSON.parse(items);
    }
  }

  return DEFAULT_PARTIES_PER_PAGE;
};

export const setPartiesPerPage = (items: number) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem('partiesPerPage', JSON.stringify(items));
  }
};

export const DEFAULT_DOCUMENTS_PER_PAGE: number = 10;

export const getDocumentsPerPage = (): number => {
  if (typeof Storage !== 'undefined') {
    const items = localStorage.getItem('documentsPerPage');

    if (items) {
      return JSON.parse(items);
    }
  }

  return DEFAULT_DOCUMENTS_PER_PAGE;
};

export const setDocumentsPerPage = (items: number) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem('documentsPerPage', JSON.stringify(items));
  }
};
