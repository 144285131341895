// TODO: comment
import { sendApiRequest } from '../utils';

/**
 * Log the user out of the Focus Solutions 360 session
 */
export const setLogout = async (): Promise<boolean> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/logout`;

  const response = await sendApiRequest<boolean>(endpoint, null, {
    method: 'POST',
  });
  return response;
};
