// TODO: comment
// https://material-ui.com/components/skeleton/
import { TableCell } from '@abrdn';
import { AbrdnTable, TableDataProvider, TableState } from '@abrdn-latest/core';
import { Box, Button, Chip, Grid } from '@material-ui/core';
import { AccountDetailFund } from 'api/types';
import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { escapeStringForRegEx, formatNumber } from 'utils';

interface Props {
  accountId: string;
  clientId: string;
  funds?: AccountDetailFund[];
  isSegregated: boolean;
}

export const AccountFundTable = ({
  funds,
  isSegregated,
  clientId,
  accountId,
}: Props) => {
  // table state
  const [state, setState] = useState<TableState>({
    order: 'desc',
    orderBy: 'valuation',
    page: 0,
    pageSize: 10,
    filters: {},
  });

  const [data, setData] = useState<AccountDetailFund[] | undefined>(funds);
  const [rows, setRows] = useState<number>(state.pageSize);

  const loadHoldings = async (state: TableState) => {
    // filter funds, and return

    const data = funds
      ?.filter((item) => {
        if (!state.filters.name) {
          return true;
        }

        const regEx: any = new RegExp(
          escapeStringForRegEx(state.filters.name) || '',
          'i'
        );

        return item.name.match(regEx) !== null;
      })
      .sort((a: AccountDetailFund, b: AccountDetailFund) => {
        // @ts-ignore
        const check =
          state.orderBy === 'valuation'
            ? a[state.orderBy].value < b[state.orderBy].value
            : // @ts-ignore
              a[state.orderBy] < b[state.orderBy];

        if (state.order === 'desc') {
          return check ? 1 : -1;
        }

        return !check ? 1 : -1;
      });

    setData(data);
  };

  useEffect(() => {
    loadHoldings(state);
  }, [funds]);

  if (!funds || (funds && funds.length === 0)) {
    return null;
  }

  const results = (data || []).slice(0, rows);

  return (
    <Box marginTop={3}>
      <TableDataProvider
        value={{
          pagination: undefined,
          results,
          state,
          setState,
        }}
      >
        <AbrdnTable
          name="a"
          onStateChange={(state: any) => {
            loadHoldings(state);
          }}
          head={[
            { id: 'name', label: 'Fund', filterable: funds.length > 1 },
            { id: 'reference', label: 'Reference' },
            {
              id: 'shareClassName',
              label: 'Share Class',
            },
            { id: 'units', label: 'Units', numeric: true },
            { id: 'unitPrice', label: 'Price', numeric: true },
            { id: 'proportion', label: 'Proportion', numeric: true },
            {
              id: 'valuation',
              label: 'Value',
              numeric: true,
              sortable: funds.length > 1,
            },
          ]}
          noResults="There are currently no holdings to display for this account"
        >
          {(row: AccountDetailFund, index: number) => (
            <Fragment>
              <TableCell component="th" scope="row">
                {row.holdingBreakdownAvailable || isSegregated ? (
                  <Grid
                    container
                    spacing={3}
                    wrap="nowrap"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>{row.name}</Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                        to={
                          isSegregated
                            ? `/accounts/${clientId}/account/${accountId}/holdings`
                            : `/portfolio-holdings/${row.holdingBreakdownFundCode}/?client=${clientId}`
                        }
                        component={RouterLink}
                      >
                        View Holdings
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell>{row.reference}</TableCell>
              <TableCell>{row.shareClassName || '-'}</TableCell>
              <TableCell align="right">{row.units || '-'}</TableCell>
              <TableCell align="right">{row.unitPrice || '-'}</TableCell>
              <TableCell align="right">
                {typeof row.proportion !== 'undefined'
                  ? `${(row.proportion * 100).toFixed(2)}%`
                  : '-'}
              </TableCell>
              <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                {row.valuation.value
                  ? formatNumber(
                      row.valuation.value,
                      row.valuation.currencyCode
                    )
                  : '-'}

                {typeof row.valuation.value === 'number' && (
                  <Chip
                    label={row.valuation.currencyCode}
                    size="small"
                    style={{
                      minWidth: 50,
                      marginLeft: 15,
                      backgroundColor: index % 2 !== 0 ? '#eee' : undefined,
                    }}
                  />
                )}
              </TableCell>
            </Fragment>
          )}
        </AbrdnTable>

        {data && results && results.length < data.length && (
          <Box paddingY={2}>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                1 - {results.length} of {funds.length}
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setRows(rows + state.pageSize);
                  }}
                  style={{ fontSize: 12, paddingLeft: 30, paddingRight: 30 }}
                >
                  Load more
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </TableDataProvider>
    </Box>
  );
};
