// TODO: comment

import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getDocumentTypes,
  getDocumentUploadRestrictions,
  getDocuments,
} from 'api';

import { getDocumentsPerPage } from 'api/constants';

export interface DocumentsRequest {
  clientId?: string;
  documentTypeCode?: string;
  max?: number;
  start: number;
}

export const getAllDocuments = createAsyncThunk(
  'documents/all',
  async ({
    start = 0,
    max = getDocumentsPerPage(),
    documentTypeCode,
    clientId,
  }: DocumentsRequest) => {
    const items = await getDocuments(start, max, documentTypeCode, clientId);

    return items;
  }
);

export const getDocumentCategories = createAsyncThunk(
  'documents/types',
  async (_, { getState }) => {
    const items = await getDocumentTypes();
    return items;
  }
);

export const getDocumentRestrictions = createAsyncThunk(
  'documents/upload-restrictions',
  async (_, { getState }) => {
    const items = await getDocumentUploadRestrictions();
    return items;
  }
);

export const documentSlice = createSlice({
  name: 'documents',
  initialState: {
    status: '',
    documents: {
      pagination: {},
      documents: [],
    },
    categories: [],
    restrictions: {
      fileSizeLimitBytes: 0,
      fileSizeLimitDisplayName: '',
      allowedMimeTypes: [],
      allowedFileExtensions: [],
    },
  },
  reducers: {
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    readDocuments: (state, action) => {
      state.documents = action.payload;
    },
    deleteDocuments: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDocuments.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getAllDocuments.fulfilled, (state, action) => {
      state.documents = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getAllDocuments.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });

    // Get Action Required Messages
    builder.addCase(getDocumentCategories.pending, (state, action) => {});
    builder.addCase(getDocumentCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(getDocumentCategories.rejected, (state, action) => {});
    // End Action Required Messages

    // Get Action Required Messages
    builder.addCase(getDocumentRestrictions.pending, (state, action) => {});
    builder.addCase(getDocumentRestrictions.fulfilled, (state, action) => {
      // @ts-ignore
      state.restrictions = action.payload;
    });
    builder.addCase(getDocumentRestrictions.rejected, (state, action) => {});
  },
});

export const { setDocuments } = documentSlice.actions;

export const selectDocuments = (state: any) => {
  return state.documents.documents;
};

export const selectDocumentsStatus = (state: any) => {
  return state.documents.status;
};

export const selectDocumentCategories = (state: any) => {
  return state.documents.categories;
};

export const selectDocumentRestrictions = (state: any) => {
  return state.documents.restrictions;
};

export default documentSlice.reducer;
