// TODO: comment
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import 'custom-event-polyfill';
import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { abrdnDefault } from 'styles';
import 'styles/globals.scss';
import { MessageComposerProvider } from 'utils/message-context';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback="loading">
    <Provider store={store}>
      <CookiesProvider>
        <StylesProvider injectFirst>
          <ThemeProvider theme={abrdnDefault}>
            <MessageComposerProvider>
              <App />
            </MessageComposerProvider>
          </ThemeProvider>
        </StylesProvider>
      </CookiesProvider>
    </Provider>
  </Suspense>,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
