// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';
import { ClientAccountSummary, ValuationsResponse } from './types';

export type ClientResponse = ClientAccountSummary[];

/**
 * Gets the account valuations for the specified client/account/date range.
 */
export const getValuations = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,

  /**
   * Gets or sets the account identifier.
   */
  accountId: string,

  /**
   *
   */
  currencyCode?: string,

  /**
   * Gets or sets the start date.
   */
  startDate?: Date | null | undefined,

  /**
   * Gets or sets the end date
   */
  endDate?: Date | null | undefined
): Promise<ValuationsResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}/valuations`;

  const data: any = {};

  if (currencyCode) {
    data.currencyCode = currencyCode;
  }

  if (startDate) {
    data.startDate = dayjs(startDate).format('YYYY-MM-DD');
  }

  if (endDate) {
    data.endDate = dayjs(endDate).format('YYYY-MM-DD');
  }

  const response = await sendApiRequest<ValuationsResponse>(endpoint, data);

  return response;
};
