export const reverseLookup = (
  items: any[],
  value: string,
  search: string,
  respond: string
) => {
  const match = items.filter((item) => {
    return item[search] === value;
  });

  if (match && match.length > 0) {
    return match[0][respond];
  }

  return value;
};
