import { Pagination } from 'api/types';
import { createContext, useContext } from 'react';

export type Order = 'asc' | 'desc';

type KeyValuePairs = {
  [key: string]: string;
};

export interface TableState {
  /**
   *
   */
  filters: KeyValuePairs;

  /**
   *
   */
  order: Order;

  /**
   *
   */
  orderBy: string;

  /**
   *
   */
  page: number;

  /**
   *
   */
  pageSize: number;
}

interface Context {
  /**
   *
   */
  pagination?: Pagination;

  /**
   *
   */

  results: any[];

  /**
   *
   */
  setState: any;

  /**
   *
   */
  state: TableState;
}

const TableDataContext = createContext<Context>({
  pagination: {
    maximumRows: 0,
    minimumTotalRows: 0,
    startRowIndex: 0,
    totalRows: 0,
  },
  results: [],

  state: {
    order: 'asc',
    orderBy: '',
    page: 0,
    pageSize: 50,
    filters: {},
  },
  setState: (value: any) => {},
});

/**
 *
 * @returns
 */
export const useTableData = (): Context => useContext(TableDataContext);

/**
 *
 */
export const TableDataProvider = TableDataContext.Provider;
