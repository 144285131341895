// TODO: comment
// TODO: apply grid
import { FormControl, Select } from '@material-ui/core';
import PaginationItem from '@material-ui/lab/PaginationItem';
import React, { Fragment } from 'react';
import './Pagination.scss';

interface Props {
  maximumRows: number;
  minimumTotalRows: number;
  onPageChange(items: number): void;
  onPagination(page: number, rowsPerPage: number): void;
  pagesToShow?: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  showSelector?: boolean;
  startRowIndex: number;
  totalRows: number;
}

export const Pagination = ({
  onPagination,
  onPageChange,
  rowsPerPage,
  startRowIndex,
  maximumRows,
  minimumTotalRows,
  pagesToShow = 7,
  showSelector = true,
  rowsPerPageOptions = [5, 10, 25],
  totalRows,
}: Props) => {
  const currentPage: number = Math.floor(startRowIndex / maximumRows);
  const lastPage: number = Math.ceil(minimumTotalRows / maximumRows);

  let start: number =
    lastPage > pagesToShow
      ? Math.max(currentPage - Math.floor(pagesToShow / 2), 0)
      : 0;

  const end: number = Math.min(start + pagesToShow, lastPage);

  //
  if (end - start < pagesToShow) {
    start = Math.max(0, end - pagesToShow);
  }

  const pages = [];

  for (let i: number = start; i < end; i++) {
    pages.push(
      <PaginationItem
        color="primary"
        page={i + 1}
        variant="text"
        key={`page-${i}`}
        onClick={() => {
          onPagination(i, rowsPerPage);
        }}
        selected={i === currentPage}
      />
    );
  }

  return (
    <Fragment>
      <div className="pagination">
        {minimumTotalRows > maximumRows && (
          <div className="pagination__pages">
            <PaginationItem
              variant="text"
              type="previous"
              color="primary"
              page={currentPage - 1}
              onClick={() => {
                onPagination(currentPage - 1, rowsPerPage);
              }}
              disabled={currentPage - 1 < 0}
            />

            {pages}

            <PaginationItem
              variant="text"
              type="next"
              color="primary"
              page={currentPage + 1}
              onClick={() => {
                onPagination(currentPage + 1, rowsPerPage);
              }}
              disabled={currentPage + 1 >= lastPage}
            />
          </div>
        )}

        {showSelector && minimumTotalRows > rowsPerPageOptions[0] && (
          <div className="pagination__selector">
            <label>Rows per page</label>
            <FormControl variant="outlined">
              <Select
                native
                value={rowsPerPage}
                onChange={(ev: any) => {
                  onPageChange(Number(ev.target.value));
                }}
                inputProps={{
                  name: 'page',
                  id: 'page-size',
                }}
              >
                {rowsPerPageOptions.map((item: number) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    </Fragment>
  );
};
