import * as CryptoJS from 'crypto-js';

export const hash = (s: string, prefix: string = 'usr'): string => {
  let h: number = 0;

  for (let i = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return `${prefix}-${Math.abs(h)}`;
};

export function sha256(input: string) {
  return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
}
