import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { GREY_MEDIUM } from '@abrdn-latest/config';
import { LightBulbIcon } from 'icons';
import { useWidth } from '../../@abrdn-latest/use';

const useStyles = makeStyles<{}, { isSmall: boolean }>(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: props => props.isSmall ? 'initial' : '100vh',
  },
  connectHeading: {
    marginTop: 20,
    marginBottom: props => props.isSmall ? 24 : 80,
    fontSize: 28,
    textAlign: 'center',
  },
  box: {
    position: 'relative',
    width: props => props.isSmall ? '100%' : 340,
    maxWidth: 600,
    margin: props => props.isSmall ? '24px 0 40px' : 0,
    background: GREY_MEDIUM,
    borderRadius: 3,
  },
  icon: {
    position: 'absolute',
    top: 20,
    right: 25,
  },
  heading: {
    marginBottom: 16,
    fontSize: 16,
  },
  paragraph: {
    fontSize: 14,
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 700,
    color: 'black',
  },
}));

function InfoBox() {
  const classes = useStyles(useWidth());
  return (
    <Box padding={3} className={classes.box}>
      <LightBulbIcon className={classes.icon} />

      <Typography variant="h4" className={classes.heading}>
        What is abrdn Connect?
      </Typography>

      <Typography
        paragraph
        className={classes.paragraph}
        style={{ marginBottom: 24 }}
      >
        abrdn Connect is a servicing tool<br/>
        designed for our institutional clients,<br/>
        with content intended for that audience.<br/>
        The tool provides all users with information on our publicly available funds.
      </Typography>

      <Typography variant="h4" className={classes.heading}>
        Looking for your personal investments?
      </Typography>

      <Typography
        paragraph
        className={classes.paragraph}
        style={{ marginBottom: 0 }}
      >
        Details on your abrdn personal investments
        can be found <a href="https://www.abrdn.com/" className={classes.link}>here</a> in
        the Individual section.
      </Typography>
    </Box>
  );
}

export function InfoPanel() {
  const { isSmall } = useWidth();
  const classes = useStyles({ isSmall });
  return (
    <Box className={classes.container}>
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {isSmall && <InfoBox />}
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src="images/abrdn-logo-dark.svg"
            alt="abrdn Connect"
            width={276}
            height={120}
          />
          <Typography variant="h4" className={classes.connectHeading}>
            Connect
          </Typography>
        </Container>
        {!isSmall && <InfoBox />}
      </Container>
    </Box>
  );
}
