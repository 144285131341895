// TODO: comment
import { Box, Button, Container, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { CloseIcon, ErrorIcon } from 'icons';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    zIndex: 100,
  },
  paper: {
    color: '#fff',
    backgroundColor: '#8B8C8E',
  },
  button: {
    color: '#fff',
    borderRadius: 0,
    backgroundColor: '#8B8C8E',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  alert: {
    backgroundColor: 'transparent',
  },
}));

export const RiskWarning = () => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box className={classes.wrapper}>
      {open ? (
        <Paper className={classes.paper}>
          <Box paddingY={1}>
            <Container>
              <Alert
                variant="filled"
                icon={false}
                className={classes.alert}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Risk warning – Investment involves risk. The value of
                investments, and the income from them, can go down as well as up
                and an investor may get back less than the amount invested. Past
                performance is not a guide to future results.
              </Alert>
            </Container>
          </Box>
        </Paper>
      ) : (
        <Button
          startIcon={<ErrorIcon />}
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
          className={classes.button}
        >
          Risk warning
        </Button>
      )}
    </Box>
  );
};
