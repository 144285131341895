// TODO: comment
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMemo } from 'react';

export const useWidth = () => {
  // get the theme settings
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      isXSmall,
      isSmall,
      isMobile,
      isDesktop: !isMobile,
    };
  }, [isMobile, isXSmall, isSmall]);
};
