// TODO: comment
import { toast } from 'material-react-toastify';
import { sendApiRequest } from '../utils';

export const deleteDocument = async (
  /**
   *
   */
  id: string,
  /**
   *
   */
  showToast: boolean = true
) => {
  const response = await sendApiRequest<any>(
    `${process.env.REACT_APP_API_URL}api/documents/${id}`,
    null,
    {
      method: 'DELETE',
    }
  );

  if (showToast) {
    toast.dark('Document Deleted');
  }

  return response;
};
