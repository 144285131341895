// TODO: comment
import { MyDetailsResponse } from 'api/types';
import { sendApiRequest } from '../utils';

/**
 * Get details for the current logged in user
 * @returns
 */
export const getUserDetails = async (): Promise<MyDetailsResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/my-details`;

  const response = await sendApiRequest<MyDetailsResponse>(
    endpoint,
    null,
    {},
    true
  );

  return response;
};
