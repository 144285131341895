// TODO: comment
import { Box, Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect, useState } from 'react';
interface Props {
  /**
   * The body of the popup, this will most likely be paragraph copy
   */
  children: React.ReactNode;
  /**
   * Text for the 'confirm/yes' button
   */
  confirmText?: string;
  /**
   * is the confirmation message open or closed
   */
  isOpen?: boolean;
  /**
   * Function called whwn the user pressed the 'confirm' button
   */
  onConfirm(): void;
  /**
   * Function called whwn the user pressed the 'reject' button
   */
  onReject(): void;
  /**
   * Text for the 'reject/no' button
   */
  rejectText?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Confirmation = (props: Props) => {
  const {
    isOpen = false,
    onReject,
    onConfirm,
    confirmText = 'Yes',
    rejectText = 'No',
    children,
  } = props;

  //
  const [open, setOpen] = useState(isOpen);
  const [confirmed, setConfirmed] = useState(false);

  /**
   *
   */
  const handleReject = () => {
    setOpen(false);
    setConfirmed(false);
  };

  /**
   *
   */
  const handleConfirm = () => {
    setOpen(false);
    setConfirmed(true);
  };

  /**
   * handle closing of the modal
   */
  const handleClose = () => {
    handleReject();
  };

  // watch for the modals state/props changing
  useEffect(() => {
    setOpen(props.isOpen || false);
  }, [props.isOpen]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      onExited={() => {
        if (confirmed) {
          onConfirm();
        } else {
          onReject();
        }
      }}
      disableBackdropClick
    >
      <Box padding={3}>
        <DialogContent>
          <Box textAlign="center">{children}</Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3} direction="row" justify="space-between">
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleReject}
                size="large"
              >
                {rejectText}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConfirm}
                size="large"
              >
                {confirmText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
