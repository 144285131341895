// TODO: comment
import { DocumentUploadRestrictionsResponse } from 'api/types';
import { sendApiRequest } from '../utils';

export const getDocumentUploadRestrictions =
  async (): Promise<DocumentUploadRestrictionsResponse> => {
    const endpoint: string = `${process.env.REACT_APP_API_URL}api/documents/document-upload-restrictions`;

    const response = await sendApiRequest<DocumentUploadRestrictionsResponse>(
      endpoint,
      null,
      {},
      true
    );

    return response;
  };
