// TODO: comment
import { sendApiRequest } from '../utils';
import { isAuthenticatedResponse } from './types';

export const userStatus = async (): Promise<isAuthenticatedResponse> => {
  const response = await sendApiRequest<isAuthenticatedResponse>(
    `${process.env.REACT_APP_API_URL}api/account/is-authenticated`
  );

  // response.oneTimePasswordRequired = false;

  return response;
};
