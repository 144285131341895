// TODO: comment
// https://material-ui.com/components/skeleton/
import { TableCell } from '@abrdn';
import { STATUS_ERROR, STATUS_LOADING } from '@abrdn-latest/config';
import {
  Abrdn,
  AbrdnTable,
  TableDataProvider,
  TableState,
} from '@abrdn-latest/core';
import { FundTypes } from '@abrdn-latest/types';
import { useDeepCompareEffect, useDidMountEffect } from '@abrdn-latest/use';
import {
  Box,
  Chip,
  Collapse,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import {
  getClientAccountAccountsDownloadUri,
  getClientAccountDetail,
  getClientAccountTransactions,
  getClientAccountTransactionsDownloadUri,
  getValuations,
} from 'api';
import {
  AccountDetail,
  AccountTransaction,
  AccountTransactions,
  ValuationsResponse,
} from 'api/types';
import { downloadFile } from 'api/utils';
import { LineChart } from 'components/chart';
import { CurrencySelector } from 'components/form';
import { ExportButton } from 'components/ui';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber, getDateFormat, getFormattedDate } from 'utils';
import { useClientHoldings } from '../data';
import { AccountFundTable } from './AccountFundTable';

interface Props {
  accountId: string;
  clientId: string;
  open?: boolean;
  order: number;
  overview?: boolean;
  transactionRange: {
    end: Date;
    start: Date;
  };
  valuationDate: Date | null | undefined;
}

export const AccountDetailAccordion = ({
  clientId,
  accountId,
  open = true,
  order,
  overview = true,
  valuationDate,
  transactionRange,
}: Props) => {
  const { t } = useTranslation(['common', 'client']);

  const { setClientCurrencies } = useClientHoldings();

  // the currencies available in this account
  const [accountCurrencies, setAccountCurrencies] = useState<string[]>([]);
  const [selectedAccountCurrency, setSelectedAccountCurrency] =
    useState<string>('');
  const [accountValuation, setAccountValuation] = useState<number>(0);

  /**
   *
   */
  const fetchAccountDetail = useCallback(async () => {
    try {
      // get the client account detail
      const detail = await getClientAccountDetail(
        clientId,
        accountId,
        valuationDate,
        selectedAccountCurrency
      );

      if (detail && clientAccount) {
        detail.name = clientAccount?.name || '';
      }

      setClientAccount(detail);

      // set the account valuation
      setAccountValuation(detail.valuation.value);
    } catch (e) {}
  }, [selectedAccountCurrency]);

  // when the account currency changes, get the new valuation
  useDidMountEffect(() => {
    fetchAccountDetail();
  }, [fetchAccountDetail]);

  //
  //
  //

  const [clientAccount, setClientAccount] = useState<null | AccountDetail>(
    null
  );

  const [transactions, setTransactions] = useState<null | AccountTransactions>(
    null
  );

  const [valuations, setValuations] = useState<null | ValuationsResponse>(null);

  const [status, setStatus] = useState<string>('');
  const [transactionsStatus, setTransactionsStatus] = useState<string>('');
  const [valuationsState, setValuationsState] = useState<string>('');

  // when the valuation date changes, refresh the data
  useDeepCompareEffect(() => {
    const load = async () => {
      try {
        setStatus(STATUS_LOADING);

        // get the client account detail
        const detail = await getClientAccountDetail(
          clientId,
          accountId,
          valuationDate,
          selectedAccountCurrency
        );

        if (detail && clientAccount) {
          detail.name = clientAccount?.name || '';
        }

        setClientAccount(detail);

        // work out the account currencies this fund has
        if (detail) {
          if (detail.funds) {
            // get a list of unique currencies
            const currencies: string[] = detail.funds.map(
              (fund) => fund.valuation.currencyCode
            );

            // add base currency
            currencies.unshift(detail.baseCurrency);

            // make sure that EUR is included in the list when the account is SICAV
            if (detail.productType === FundTypes.SICAV) {
              currencies.push('EUR');
            }

            const uniqueCurrencies = [...new Set(currencies)];

            setClientCurrencies(
              clientId,
              detail.baseCurrency
                ? [...[detail.baseCurrency], ...uniqueCurrencies]
                : uniqueCurrencies,
              order === 0
            );

            // set the list
            setAccountCurrencies(uniqueCurrencies);

            // set the selected currency to the initial one we receive
            setSelectedAccountCurrency(detail.valuation.currencyCode);

            // set the account valuation
            setAccountValuation(detail.valuation.value);
          }
        }

        setStatus('');
      } catch (e) {
        setStatus(STATUS_ERROR);
      }
    };

    load();
  }, [valuationDate]);

  /**
   *
   */
  const fetchAccountValuations = useCallback(async () => {
    try {
      if (clientAccount && clientAccount.dataIssue) {
        return;
      }

      setValuationsState(STATUS_LOADING);

      const response = await getValuations(
        clientId,
        accountId,
        selectedAccountCurrency
      );

      if (response) {
        setValuations(response);
      }

      setValuationsState('');
    } catch (e) {
      setValuationsState(STATUS_ERROR);
      setValuations(null);
    }
  }, [selectedAccountCurrency]);

  // ...
  useEffect(() => {
    fetchAccountValuations();
  }, [fetchAccountValuations]);

  const [transactionState, setTransactionState] = useState<TableState>({
    order: 'asc',
    orderBy: 'transactiondate',
    page: 0,
    pageSize: 10,
    filters: {},
  });

  useDeepCompareEffect(() => {
    setTransactionState({ ...transactionState, ...{ page: 0 } });
  }, [transactionRange]);

  /**
   *
   */
  const loadTransactions = async (state: any) => {
    try {
      setTransactionsStatus(STATUS_LOADING);

      // get the client transaction
      const transactions = await getClientAccountTransactions(
        clientId,
        accountId,
        transactionRange.start,
        transactionRange.end,
        state.orderBy,
        state.order,
        state.filters.fundname || '',
        state.page,
        state.pageSize
      );

      setTransactions(transactions);

      setTransactionsStatus('');
    } catch (e) {
      setTransactionsStatus(STATUS_ERROR);
    }
  };

  /**
   *
   */
  const handleDownloadAccount = (ev: React.MouseEvent) => {
    ev.preventDefault();

    if (clientAccount) {
      downloadFile(
        getClientAccountAccountsDownloadUri(
          clientId,
          accountId,
          valuationDate,
          selectedAccountCurrency
        ),
        `${clientAccount.name}-(${selectedAccountCurrency}).xlsx`
      );
    }
  };

  /**
   *
   */
  const handleDownload = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (clientAccount) {
      const start = getDateFormat(transactionRange.start);
      const end = getDateFormat(transactionRange.end);

      downloadFile(
        getClientAccountTransactionsDownloadUri(
          clientId,
          accountId,
          transactionRange.start,
          transactionRange.end
        ),
        `${clientAccount.name}-transactions (${start}-${end}).xlsx`
      );
    }
  };

  const isSegregated = clientAccount?.productType === FundTypes.Segregated;

  return (
    <Fragment>
      <Abrdn.Accordion defaultExpanded={open}>
        <Abrdn.AccordionSummary>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Abrdn.AccordionToggle>
                <Box paddingY={2} paddingLeft={2} paddingRight={3}>
                  <Typography variant="h4" style={{ margin: 0 }}>
                    {clientAccount ? (
                      clientAccount.name || 'Unknown'
                    ) : (
                      <Skeleton width={140} />
                    )}
                  </Typography>
                </Box>
              </Abrdn.AccordionToggle>
            </Grid>

            <Grid item>
              <Fragment>
                {overview && status !== STATUS_ERROR && (
                  <Typography variant="h4" style={{ margin: 0 }}>
                    <Box style={{ display: 'inline-block', marginRight: 10 }}>
                      <CurrencySelector
                        currencies={accountCurrencies}
                        onChange={(currencyCode: string) => {
                          setSelectedAccountCurrency(currencyCode);
                        }}
                      />
                    </Box>

                    {status === STATUS_LOADING || !clientAccount ? (
                      <Skeleton
                        width={100}
                        style={{ display: 'inline-block' }}
                      />
                    ) : accountValuation && selectedAccountCurrency ? (
                      <Fragment>
                        {/* TODO: implement */}

                        {formatNumber(
                          accountValuation,
                          selectedAccountCurrency
                        )}
                      </Fragment>
                    ) : (
                      ''
                    )}
                  </Typography>
                )}
                {!overview &&
                  transactions &&
                  transactions.transactions.length > 0 && (
                    <ExportButton label="Download" onClick={handleDownload} />
                  )}
              </Fragment>
            </Grid>
          </Grid>
        </Abrdn.AccordionSummary>
        <Abrdn.AccordionDetail>
          <Box>
            <Collapse in={overview}>
              <Paper>
                <Box padding={2}>
                  {status === STATUS_ERROR ||
                  (clientAccount && clientAccount.dataIssue) ? (
                    <Fragment>
                      {clientAccount && clientAccount.dataIssue ? (
                        <Alert severity="warning">
                          There is an issue with this account data
                        </Alert>
                      ) : (
                        <Alert severity="info">
                          There are no valuations available for the selected
                          date
                        </Alert>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="space-between"
                      >
                        <Grid item xs={12} md={5}>
                          {clientAccount && (
                            <Fragment>
                              <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="space-between"
                                component="dl"
                                wrap="wrap"
                              >
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t('client:accounts.labels.fund-type')}
                                  </dt>
                                  <dd>{clientAccount.productType || '-'}</dd>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t('client:accounts.labels.transfer-agent')}
                                  </dt>
                                  <dd>{clientAccount.transferAgent || '-'} </dd>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t('client:accounts.labels.account-number')}
                                  </dt>
                                  <dd>{clientAccount.accountNumber || ''}</dd>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t('client:accounts.labels.base-currency')}
                                  </dt>
                                  <dd>
                                    {clientAccount.productType ===
                                      FundTypes.SICAV &&
                                    !clientAccount.baseCurrency
                                      ? 'Multiple See Funds'
                                      : clientAccount.baseCurrency || '-'}
                                  </dd>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t(
                                      'client:accounts.labels.initial-investment'
                                    )}
                                  </dt>
                                  <dd>
                                    {getFormattedDate(
                                      clientAccount.initialInvestment
                                    )}
                                  </dd>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <dt>
                                    {t('client:accounts.labels.valuation-date')}
                                  </dt>
                                  <dd>
                                    {clientAccount?.valuation?.date
                                      ? getFormattedDate(
                                          clientAccount.valuation.date
                                        )
                                      : '-'}
                                  </dd>
                                </Grid>
                              </Grid>

                              <Box marginTop={3}>
                                <ExportButton
                                  label={`Download${
                                    selectedAccountCurrency
                                      ? ` (${selectedAccountCurrency})`
                                      : ''
                                  }`}
                                  onClick={handleDownloadAccount}
                                />
                              </Box>

                              <Box marginTop={3}>
                                <Typography variant="body2" paragraph>
                                  Valuations displayed are indicative. Audited
                                  valuations are available in the periodic
                                  reporting provided. Download to confirm the
                                  valuation pricing point. For further details
                                  see page information.
                                </Typography>
                              </Box>
                            </Fragment>
                          )}
                        </Grid>

                        <Grid item xs={12} md={7}>
                          {valuationsState === STATUS_LOADING ? (
                            <Skeleton variant="rect" width={650} height={250} />
                          ) : (
                            <Fragment>
                              {valuations && valuations.length > 0 ? (
                                <LineChart data={valuations} />
                              ) : (
                                <Alert severity="info">
                                  No chart data available
                                </Alert>
                              )}
                            </Fragment>
                          )}
                        </Grid>
                      </Grid>

                      <AccountFundTable
                        funds={
                          clientAccount && clientAccount.funds
                            ? clientAccount.funds
                            : undefined
                        }
                        isSegregated={isSegregated}
                        clientId={clientId}
                        accountId={accountId}
                      />
                    </Fragment>
                  )}
                </Box>
              </Paper>
            </Collapse>

            <Collapse in={!overview}>
              <Paper>
                {(transactions || !overview) && (
                  <TableDataProvider
                    value={{
                      pagination: transactions?.pagination,
                      results: transactions?.transactions || [],
                      state: transactionState,
                      setState: setTransactionState,
                    }}
                  >
                    <AbrdnTable
                      name="a"
                      onStateChange={(state: any) => {
                        loadTransactions(state);
                      }}
                      rowOptions={[10, 25, 50, 75, 100]}
                      head={[
                        {
                          id: 'transactiondate',
                          label: 'Date',
                          sortable: true,
                        },
                        {
                          id: 'fundname',
                          label: 'Fund Name',
                          filterable:
                            clientAccount?.productType !== FundTypes.SICAV,
                          style: { minWidth: 170 },
                        },
                        {
                          id: 'transactiontype',
                          label: 'Transaction',
                          sortable:
                            clientAccount?.productType !== FundTypes.SICAV,
                        },
                        { id: 'shareclass', label: 'Share class' },
                        { id: 'amount', label: 'Amount', numeric: true },
                      ]}
                      noResults="There are currently no transactions to display for this account"
                    >
                      {(row: AccountTransaction, index: number) => (
                        <Fragment>
                          <TableCell component="th" scope="row">
                            {getDateFormat(row.transactionDate, 'DD/MM/YYYY')}
                          </TableCell>
                          <TableCell>{row.fundName || '-'}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.shareClassName || '-'}</TableCell>
                          <TableCell
                            align="right"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {typeof row.value === 'number'
                              ? formatNumber(row.value, row.currencyCode)
                              : '-'}
                            {typeof row.value === 'number' && (
                              <Chip
                                label={row.currencyCode}
                                size="small"
                                style={{
                                  minWidth: 50,
                                  marginLeft: 15,
                                  backgroundColor:
                                    index % 2 !== 0 ? '#eee' : undefined,
                                }}
                              />
                            )}
                          </TableCell>
                        </Fragment>
                      )}
                    </AbrdnTable>
                  </TableDataProvider>
                )}
              </Paper>
            </Collapse>
          </Box>
        </Abrdn.AccordionDetail>
      </Abrdn.Accordion>
    </Fragment>
  );
};
