// TODO: comment
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DocumentUploadRestrictionsResponse } from 'api/types';
import React from 'react';

interface Props {
  restrictions: DocumentUploadRestrictionsResponse;
}

export const Restrictions = ({ restrictions }: Props) => {
  return (
    <Box marginBottom={2}>
      <Alert severity="info">
        <AlertTitle>Upload limitations</AlertTitle>
        Files can not exceed <b>{restrictions.fileSizeLimitDisplayName}</b> in
        size. Allowed documents: {restrictions.allowedFileExtensions.join(', ')}
      </Alert>
    </Box>
  );
};
