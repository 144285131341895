// TODO: comment
// @ts-nocheck
// https://github.com/satansdeer/completed/tree/master/src

import TextField from '@material-ui/core/TextField';
import React, { forwardRef } from 'react';

export const Input = forwardRef((props: any, ref) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      inputRef={ref}
      fullWidth
      {...props}
    />
  );
});
