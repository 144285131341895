import dayjs from 'dayjs';

export const getLocalisedDate = (
  date: string | Date | undefined = undefined
) => {
  try {
    return dayjs(date).tz('Europe/London');
  } catch (e) {
    return dayjs(date);
  }
};
