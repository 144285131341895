// TODO: comment
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { ChartData } from './';
import './Chart.scss';

interface Props {
  data: ChartData[];
}

interface ChartKeySwatchProps {
  colour?: string;
  size?: 'small' | 'default';
}

export const ChartKeySwatch = ({
  colour,
  size = 'default',
}: ChartKeySwatchProps) => {
  if (colour) {
    return (
      <span
        className={`chart__swatch s-${size}`}
        style={{ backgroundColor: colour }}
      ></span>
    );
  }
  return null;
};

export const ChartKey = ({ data }: Props) => {
  return (
    <Box className="chart__key" style={{ marginTop: 15 }}>
      <ul>
        {data.map(({ title, color }: ChartData, index: number) => {
          return (
            <li key={`key-${index}`}>
              <ChartKeySwatch colour={color} />
              <Typography variant="caption">{title}</Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
