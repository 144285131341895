// TODO: comment
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GetAppIcon } from 'icons';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontWeight: 700,
      padding: 0,
      textAlign: 'left',
      textDecoration: 'underline',
      fontSize: 14,
      borderRadius: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'none',
      },
    },
  })
);

interface Props {
  /**
   *
   */
  label: string;

  /**
   *
   */
  onClick?: any;
}

export const ExportButton = ({ label, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Button
      variant="text"
      color="primary"
      className={classes.button}
      onClick={onClick}
      endIcon={<GetAppIcon />}
    >
      {label}
    </Button>
  );
};
