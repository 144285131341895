// TODO: comment
import { getLocalisedDate } from './';

export const getDateFormat = (
  date: string | Date,
  format: string = 'DD MMM YYYY'
): string => {
  // get the parsed date
  return getLocalisedDate(date).format(format);
};
