// TODO: comment

import './Banner.scss';

import {
  Box,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { FilterListIcon, SearchIcon } from 'icons';
import React, { Fragment, useEffect, useState } from 'react';

import { AdditionalInfo } from './AdditionalInfo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { Filter } from './';
import { SlideOut } from 'components/slideout';
import TextField from '@material-ui/core/TextField';
import { ThemeDefault } from 'styles';
import { isIE } from '@abrdn/utils';
import { makeStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useMedia } from 'react-use';
import { usePortfolioHoldings } from '../data';

const useStyles = makeStyles(() => ({
  searchButton: {
    borderRadius: 0,
    borderTop: '1px solid #fff',
    borderBottom: '1px solid #fff',
    borderRight: '1px solid #fff',
  },
  infoButton: {
    marginBottom: 4,
  },
}));

export interface SearchFilters {
  assetFundClass: string[];
  country: string;
  countryDisplay: string;
  fundRange: string[];
  investorType: string;
  investorTypeDisplay: string;
  productType: string[];
  search: string;
}

const Info = ({ className }: { className?: string }) => {
  return (
    <AdditionalInfo className={className}>
      <Typography paragraph>
        Funds displayed are based on the Investor Type and Country selected
        during registration.
      </Typography>
      <Typography paragraph>
        Use the edit function on this page to display funds available to other
        Investor Types and / or in other Countries. Note: by editing your
        Investor Type and / or Country the funds that are displayed may not be
        available to you.
      </Typography>
      <Typography paragraph>
        Use the Search panel and Filters to identify the funds that you are
        interested in.
      </Typography>
      <Typography paragraph>
        Selecting the fund name will take you to the underlying Portfolio data
        of that fund.
      </Typography>
      <Typography paragraph>
        You may favourite a fund by selecting the star icon beside the fund
        name. Favourite funds will appear at the top of your Portfolio Holdings
        view.
      </Typography>
      <Typography paragraph>
        Selecting the checkbox beside the fund name allows you to export the
        latest available fund holdings data. You can select up to five funds to
        export the data at one time.
      </Typography>
      <Typography paragraph>
        The data sourced to display valuations is regularly validated and
        updated with applicable corrections to quantities and prices to provide
        accurate information. However, this may not reconcile with the audited
        valuations provided in periodic reporting due to timing differences and
        the data sources pricing methodology, such as pricing points, pricing
        hierarchy, rounding and/or fx rates.
      </Typography>
    </AdditionalInfo>
  );
};

export const Banner = () => {
  const styles = useStyles();
  const {
    state,
    filters,
    setAssetFunds,
    setFundRanges,
    setProductTypes,
    setSearch,
  } = usePortfolioHoldings();

  // width check for showing filter views
  const isWide = useMedia('(min-width: 800px)');

  // mobile view
  const [drawerOpen, setDrawerOpen] = useState(false);

  // open or close the drawer on mobile
  const toggleDrawer = (open: boolean) => (ev: any) => {
    if (ev.type === 'keydown' && (ev.key === 'Tab' || ev.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  // handle filter changes
  const onAssetFilterChange = (filters: string[]) => {
    setAssetFunds(filters);
  };

  const onFundFilterChange = (filters: string[]) => {
    setFundRanges(filters);
  };

  const onProductFilterChange = (filters: string[]) => {
    setProductTypes(filters);
  };

  // handle seach changing
  const onSearch = (value: string): void => {
    setSearch(value);
  };

  return (
    <Fragment>
      <Box className="portfolio-banner">
        <Box marginBottom={3}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h3" component="h1">
                Portfolio Holdings
              </Typography>
            </Grid>
            <Grid item>
              <Info className={!isWide ? styles.infoButton : undefined} />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Search onChange={onSearch} />
        </Box>
        <Box>
          {filters && !isWide && (
            <>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={true}>
                  <Button
                    fullWidth
                    onClick={toggleDrawer(true)}
                    endIcon={<FilterListIcon />}
                    variant="outlined"
                    size="large"
                  >
                    Filter Holdings
                  </Button>
                </Grid>
              </Grid>

              <ThemeDefault>
                <SlideOut
                  title="Filter Holdings"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                >
                  <Box>
                    <Filter
                      label="Asset class"
                      filters={filters.fundAssetClasses}
                      selected={state.assetFundClass}
                      displayAsAccordion
                      onChange={onAssetFilterChange}
                    />
                  </Box>

                  <Box>
                    <Filter
                      label="Fund range"
                      filters={filters.fundRanges}
                      selected={state.fundRange}
                      displayAsAccordion
                      onChange={onFundFilterChange}
                    />
                  </Box>

                  <Box>
                    <Filter
                      label="Product type"
                      filters={filters.productTypes}
                      selected={state.productType}
                      displayAsAccordion
                      onChange={onProductFilterChange}
                    />
                  </Box>
                </SlideOut>
              </ThemeDefault>
            </>
          )}

          {filters && isWide && (
            <Box>
              <Filter
                label="Asset class"
                filters={filters.fundAssetClasses}
                selected={state.assetFundClass}
                onChange={onAssetFilterChange}
              />
              <Filter
                label="Fund range"
                filters={filters.fundRanges}
                selected={state.fundRange}
                onChange={onFundFilterChange}
              />
              <Filter
                label="Product type"
                filters={filters.productTypes}
                selected={state.productType}
                onChange={onProductFilterChange}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

interface SearchProps {
  onChange(value: string): void;
}

const PaperComponent = ({ children }: any) => (
  <ThemeDefault>
    <Paper>{children}</Paper>
  </ThemeDefault>
);

export const Search = ({ onChange }: SearchProps) => {
  const classes = useStyles();

  const { funds, state } = usePortfolioHoldings();

  // track the input value
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleSearch = () => {
    if (value.length >= 2 && value.length <= 100) {
      onChange(value);
    } else {
      setError(true);
    }
  };

  const IS_IE: boolean = isIE();

  useEffect(() => {
    if (error && value.length >= 2 && value.length <= 100) {
      setError(false);
    }
  }, [value]);

  return (
    <ThemeDefault>
      <Box className="portfolio-banner__search">
        <Paper>
          <Autocomplete
            freeSolo
            value={state.search}
            options={funds ? funds.map((fund) => fund.fundName) : []}
            onInputChange={(ev: React.ChangeEvent<{}>, newInputValue: any) => {
              const theValue = newInputValue.replace(/([\\<>]+)/gi, '').trim();

              if (theValue === '' && value !== '') {
                onChange('');
              }

              setValue(theValue);

              if (ev?.type === 'click') {
                onChange(theValue);
              }
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            PaperComponent={PaperComponent}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);

              return (
                <div>
                  {parts.map((part: any, index: number) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                        textDecoration:
                          part.highlight && IS_IE ? 'underline' : undefined,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder="Search by fund name"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              );
            }}
          />
        </Paper>
        <Button
          variant="contained"
          color="primary"
          aria-label="Search"
          onClick={handleSearch}
          className={classes.searchButton}
        >
          <SearchIcon />
        </Button>
      </Box>
      {error && (
        <Box marginBottom={2}>
          <FormHelperText error={error}>
            'Fund Name' must be between 2 and 100 characters. You entered{' '}
            {value.length} character(s).
          </FormHelperText>
        </Box>
      )}
    </ThemeDefault>
  );
};
