// TODO: comment

import * as validation from 'validation';
import * as yup from 'yup';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import {
  DocumentUploadRestrictionsResponse,
  ReceivedMessage,
  SentMessage,
} from 'api/types';
import { FileSelector, ValidationProvider } from 'components/file-selector';
import { Form, Textarea } from 'components/form';
import { FormProvider, useForm } from 'react-hook-form';
import { Fragment, useEffect, useState } from 'react';
import {
  MESSAGE_BREAKER,
  STATUS_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { createReply, uploadDocuments } from 'api';
import { delayFire, getLocalisedDate } from 'utils';
import {
  getDocumentRestrictions,
  selectDocumentRestrictions,
} from 'redux/reducers/documents';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import { Confirmation } from 'components/confirmation';
import { Redirect } from 'react-router-dom';
import { SendIcon } from 'icons';
import { toast } from 'material-react-toastify';
import { useRouter } from '@abrdn-latest/use';
import { useSecurity } from 'authentication';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

interface ReplyFormProps {
  message: ReceivedMessage | SentMessage;
  messageId: string;
  showForm?: boolean;
}

export const ReplyForm = ({
  messageId,
  showForm = false,
  message,
}: ReplyFormProps) => {
  const { t } = useTranslation(['common']);

  const [redirect, setRedirect] = useState('');
  const router = useRouter();

  const restrictions: DocumentUploadRestrictionsResponse = useSelector(
    selectDocumentRestrictions
  );

  const dispatch = useDispatch();
  const { authState } = useSecurity();

  useEffect(() => {
    if (authState.isFullyAuthenticated) {
      delayFire(() => {
        if (restrictions.fileSizeLimitBytes === 0) {
          dispatch(getDocumentRestrictions());
        }
      });
    }
  }, [authState.isFullyAuthenticated]);

  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);
  const onConfirm = () => {
    setConfirmMessageOpen(false);
    handleCancel();
  };
  const onReject = () => {
    setConfirmMessageOpen(false);
  };

  const messageBody = message.body;

  const [showReply, setShowReply] = useState(showForm);

  const [formStatus, setFormStatus] = useState('');

  const clickReply = () => {
    setShowReply(true);
  };

  const schema = yup.object().shape({
    message: yup
      .mixed()
      .required('Message is a required field')
      .test('notblank', 'Message is a required field', (value) => {
        return String(value) !== '';
      })
      .test('html', 'Please enter valid characters only', validation.noHTML),

    files: yup.array().of(
      yup.object().shape({
        description: yup
          .mixed()
          .required('Document description is required')
          .test('notblank', 'Document description is required', (value) => {
            return String(value) !== '';
          })
          .test(
            'validCharacters',
            'Please enter valid characters only',
            validation.validCharacters
          ),
        file: yup
          .mixed()
          .required('A file is required')
          .test('fileRequired', 'Please select a file', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return true;
            } catch (e) {
              return true;
            }
          })
          .test('fileSize', 'The file is too large', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size < restrictions.fileSizeLimitBytes;
            } catch (e) {
              return true;
            }
          })
          .test('fileSizeSmall', 'The file has not contents', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return value[0].size > 0;
            } catch (e) {
              return true;
            }
          })
          .test('fileMIMEType', 'Invalid file format', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              return restrictions.allowedMimeTypes.includes(value[0].type);
            } catch (e) {
              return true;
            }
          })
          .test('fileExtension', 'Unsupported file extension', (value) => {
            try {
              if (value.length === 0) {
                return false;
              }

              const extension = value[0].name.match(/.([0-9a-z]+)$/i);

              return restrictions.allowedFileExtensions.includes(
                extension[1].toLowerCase()
              );
            } catch (e) {
              return true;
            }
          }),
      })
    ),
  });

  const [data, setData] = useState({
    message: '',
    files: [],
  });

  const methods = useForm({
    defaultValues: {
      message: data.message,
      files: [],
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, reset, getValues } = methods;

  const handleCancel = () => {
    reset();
  };

  const isFormFilled = (): boolean => {
    const values = getValues();

    return !(values.message === '' && values.files !== null);
  };

  const onSubmit = async (data: any) => {
    setFormStatus(STATUS_SENDING);

    setData(data);

    const sentDate = getLocalisedDate().format('DD/MM/YYYY HH:mm:ss');

    const body = `\n
${data.message}
${MESSAGE_BREAKER}
From: ${message.source.name}
Sent: ${sentDate}

${messageBody}
${MESSAGE_BREAKER}
`;

    try {
      const documentIds = await uploadDocuments(data.files);

      createReply(messageId, message.subject, body, documentIds)
        .then((res) => {
          toast.success(t('common:forms.reply.messages.toast-success'));

          // TODO: possibly clear the form message after x seconds
          reset();
          setFormStatus(STATUS_SENT);
        })
        .catch(function () {
          setFormStatus(STATUS_ERROR);
        });
    } catch (e) {
      setFormStatus(STATUS_ERROR);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Fragment>
      <Confirmation
        isOpen={confirmMessageOpen}
        confirmText="Yes, cancel"
        onConfirm={onConfirm}
        rejectText="No, continue"
        onReject={onReject}
      >
        <Typography paragraph>
          {t('common:forms.reply.messages.cancel')}
        </Typography>
      </Confirmation>

      <div>
        {formStatus === STATUS_SENT && (
          <Alert severity="success">
            {t('common:forms.reply.messages.success')}
          </Alert>
        )}

        {formStatus !== STATUS_SENT && (
          <Fragment>
            {showReply ? (
              <ValidationProvider schema={schema}>
                <FormProvider {...methods}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    {formStatus === STATUS_ERROR && (
                      <Alert severity="error">
                        {t('common:forms.reply.messages.error')}
                      </Alert>
                    )}

                    <Textarea
                      ref={register}
                      id="message"
                      type="text"
                      label="Reply"
                      name="message"
                      error={!!errors.message}
                      helperText={errors?.message?.message}
                    />

                    <FileSelector />

                    <Grid container spacing={3} alignItems="stretch">
                      <Grid item xs>
                        <Box>
                          <Button
                            size="large"
                            variant="outlined"
                            disabled={formStatus === STATUS_SENDING}
                            onClick={() => {
                              if (isFormFilled()) {
                                setConfirmMessageOpen(true);
                              } else {
                                handleCancel();
                              }
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs>
                        <Box textAlign="right">
                          <Button
                            size="large"
                            disabled={formStatus === STATUS_SENDING}
                            variant="contained"
                            type="submit"
                            color="primary"
                            endIcon={<SendIcon />}
                          >
                            Reply
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </FormProvider>
              </ValidationProvider>
            ) : (
              <Box textAlign="right">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={clickReply}
                >
                  Reply
                </Button>
              </Box>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
