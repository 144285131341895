// TODO: comment
import { sendApiRequest } from '../utils';
import { ChangePasswordResponse } from './types';

export const changePassword = async (
  /**
   * Gets or sets the current password.
   */
  currentPassword: string,
  /**
   * Gets or sets the new password.
   */
  newPassword: string
): Promise<ChangePasswordResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/change-password`;

  try {
    await sendApiRequest<ChangePasswordResponse>(endpoint, null, {
      method: 'POST',
      body: JSON.stringify({
        currentPassword,
        newPassword,
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    return {
      success: true,
    };

    // return response;
  } catch (e: any) {
    return {
      success: false,
      errorMessage: e.message,
    };
  }
};
