import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from '@abrdn';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Pagination } from 'components/pagination';
import React, { Fragment, useEffect } from 'react';
import { HeadCell, TableHeader, useTableData } from './';

/**
 *
 */

interface Props {
  /**
   *
   */
  children: any;

  /**
   *
   */
  footer?: any;

  /**
   *
   */
  head: HeadCell[];

  /**
   *
   */
  name: string;

  /**
   *
   */
  noResults: string;

  /**
   *
   */
  onStateChange: (state: any) => void;

  /**
   *
   */
  rowOptions?: number[];
}

export const AbrdnTable = ({
  children,
  head,
  name,
  noResults,
  onStateChange,
  rowOptions = [25, 50, 75, 100],
}: // request,
Props) => {
  // get data from the provider
  const { results, pagination, state, setState } = useTableData();

  // TODO: get any saved values for pagination settings from localstorage

  /**
   *
   * @param page
   * @param rowsPerPage
   */
  const handlePaginationChange = (page: number, pageSize?: number) => {
    setState({ ...state, ...{ page } });
  };

  /**
   *
   * @param pageSize
   */
  const handlePaginationPageChange = (pageSize: number) => {
    setState({ ...state, ...{ pageSize, page: 0 } });
  };

  /**
   *
   * @param event
   * @param property
   */
  const onSortRequest = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    // work out how the table should be ordered
    const isAsc = state.orderBy === property && state.order === 'asc';

    // update the state
    setState({
      ...state,
      ...{
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
        // reset the page number
        page: 0,
      },
    });
  };

  let timer: any = null;

  const onFilterRequest = (field: string, value: string) => {
    //
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    const filters = state.filters;
    filters[field] = value;

    timer = setTimeout(() => {
      setState({
        ...state,
        ...{
          filters,
        },
      });

      clearTimeout(timer);
      timer = null;
    }, 500);
  };

  // when the state changes, make a new async request to get the updated data
  useEffect(() => {
    onStateChange(state);
  }, [state]);

  const hasFiltersApplied = (): boolean => {
    return Object.keys(state.filters).length > 0;
  };

  /**
   *
   * @returns
   */
  const hasResults = (): boolean => {
    return results && results.length > 0;
  };

  return (
    <Box>
      {!hasResults() && !hasFiltersApplied() && (
        <Alert severity="info">{noResults}</Alert>
      )}

      {(hasResults() || hasFiltersApplied()) && (
        <Fragment>
          <TableContainer>
            <Table size={'medium'} style={{ border: 'none' }} className="tbl">
              {/* header */}
              <TableHeader
                cells={head}
                order={state.order}
                orderBy={state.orderBy}
                onSortRequest={onSortRequest}
                onFilterRequest={onFilterRequest}
              />

              {/* body */}
              <TableBody>
                {results.map((result: any, index: number) => (
                  <TableRow key={`result-${index}`}>
                    {children(result, index)}
                  </TableRow>
                ))}

                {!hasResults() && hasFiltersApplied() && (
                  <TableRow>
                    <TableCell colSpan={head.length + 1} align="center">
                      <Typography>
                        There are no results to show for the current filter
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {/* footer */}
              {false && (
                <TableFooter>
                  <TableRow
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                  >
                    <TableCell
                      colSpan={head.length + 1}
                      align="right"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                    >
                      [table footer]
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>

          {/* pagination */}
          {pagination && (
            <Pagination
              onPagination={handlePaginationChange}
              onPageChange={handlePaginationPageChange}
              rowsPerPage={state.pageSize}
              startRowIndex={pagination.startRowIndex * state.pageSize}
              maximumRows={pagination.maximumRows}
              minimumTotalRows={pagination.minimumTotalRows}
              totalRows={pagination.totalRows}
              rowsPerPageOptions={rowOptions}
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};
