// TODO: comment
import { sendApiRequest } from '../utils';
import { ForgottenPasswordResponse } from './types';

export const forgottenPassword = async (
  /**
   *
   */
  username: string
): Promise<ForgottenPasswordResponse> => {
  const response = await sendApiRequest<ForgottenPasswordResponse>(
    `${process.env.REACT_APP_API_URL}api/account/forgotten-password`,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        username,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
