// TODO: comment

import { isObjectEmpty } from 'utils';
import { trigger } from '@abrdn/utils/events';
import { toast } from 'material-react-toastify';

export type QueryData = {
  [key: string]: string | number | boolean | undefined | any[];
};

export const withQuery = (
  /**
   *
   */
  uri: string,
  /**
   *
   */
  data: QueryData | null = null
): string => {
  if (data) {
    const query = Object.keys(data)
      .filter((k) => {
        return data[k] !== undefined;
      })
      // @ts-ignore
      .map((k) => {
        if (Array.isArray(data[k])) {
          // @ts-ignore
          if (data[k].length) {
            // @ts-ignore
            return (data[k] as Array)
              .map((item: any) => {
                return `${encodeURIComponent(k)}=${encodeURIComponent(item)}`;
              })
              .join('&');
          }
          return '';
        } else {
          // @ts-ignore
          return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
        }
      })
      .join('&');

    if (query) {
      return `${uri}?${query}`;
    }
  }

  return uri;
};

export type Response = {
  headers: Headers;
  json: <T>() => Promise<T>;
  status: number;
};

// TODO: check that this works, and update
const getCookieValue = (
  /**
   *
   */
  key: string
) => {
  var equalities = document.cookie.split('; ');

  for (var i = 0; i < equalities.length; i++) {
    if (!equalities[i]) {
      continue;
    }

    var splitted = equalities[i].split('=');
    if (splitted.length !== 2) {
      continue;
    }

    if (decodeURIComponent(splitted[0]) === key) {
      return decodeURIComponent(splitted[1] || '');
    }
  }

  return null;
};

export const handleResponse = async <T>(
  /**
   *
   */
  response: Response,
  /**
   *
   */
  url?: string
): Promise<T> => {
  const headers = response.headers;

  const sessionHeader: string | null = headers.get(
    'fsg.focus360.cehsite.f360session'
  );

  if (sessionHeader) {
    setSessionHeader(sessionHeader);
  }

  // handling errors
  if (response.status < 200 || response.status > 299) {
    if (response.status === 401) {
      if (url && url.indexOf('login') === -1) {
        trigger('api:unauthorized');
      }
      throw new Error('Unauthorized');
    }

    if (response.status === 404) {
      throw new Error('404');
    }

    if (response.status === 500) {
      throw new Error('Server Error');
    }

    //
    try {
      // get the actual json response
      const res: any = await response.json();

      if (res) {
        let errors = '';

        if (res.errors && res.errors.ValidationErrors) {
          if (res.detail) {
            errors += `<p>${res.detail}</p>`;
          }

          if (res.errors.ValidationErrors.length > 0) {
            errors += `<ul><li>${res.errors.ValidationErrors.join(
              '</li><li>'
            )}</li></ul>`;
          } else {
            errors += res.errors.ValidationErrors.join(', ');
          }
        }

        if (res.title) {
          throw new Error(`${res.title}${errors ? ` ${errors}` : ''}`);
        }
      }
    } catch (e: any) {
      throw new Error(e.message);
    }

    throw new Error('An unknown error occurred');
  }

  // in some instances an empty reponse is sent back, with a successful header
  // so we may not always be able to parse it as json
  try {
    const res: T = await response.json();

    return res;
  } catch (e) {
    // send back an empty json response
    return {} as T;
  }
};

export const downloadFile = async (
  uri: string,
  filename: string,
  notification?: any
): Promise<boolean> => {
  const toastId = notification && toast.dark(notification, {
    autoClose: false,
  });

  try {
    const fileData = await fetch(withQuery(uri), withHeaders({}));

    if (fileData.status >= 200 && fileData.status < 300) {
      const data = await fileData.blob();

      // It is necessary to create a new blob object with mime-type explicitly
      // set otherwise only Chrome works like it should
      const blob = new Blob([data], {
        type: data.type || 'application/octet-stream',
      });

      // @ts-ignore
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE doesn't allow using a blob object directly as link href.
        // Workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."

        // @ts-ignore
        window.navigator.msSaveBlob(blob, filename);

        return true;
      }

      // Other browsers
      // Create a link pointing to the ObjectURL containing the blob
      const blobURL = window.URL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);

      tempLink.click();

      document.body.removeChild(tempLink);
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobURL);
      }, 100);

      // update the toast message to show the success message
      if (toastId) {
        toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          render: `Downloaded ${filename}`,
          autoClose: 5000,
        });
      }

      return true;
    }
  } catch (e) {
    if (toastId) {
      toast.update(toastId, {
        type: toast.TYPE.ERROR,
        render: `Error downloading ${filename}`,
        autoClose: 5000,
      });
    }
  }

  return false;
};

// for logging
const REQUESTS: any = {};
const CACHE: any = {};

/**
 * Generates a timestamp
 * @returns string
 */
const getTimeStamp = (): string => {
  // get the current date
  const now: Date = new Date();

  return `${String(now.getHours()).padStart(2, '0')}:${String(
    now.getMinutes()
  ).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
};

let REQUEST_QUEUE: string[] = [];

export const sendApiRequest = async <T>(
  url: string,
  query?: QueryData | null,
  request: any = {},
  cache: boolean | number = false
): Promise<T> => {
  const currentTime: number = new Date().getTime();

  // get the current timestamp
  const timestamp = getTimeStamp();

  // make a nice url by replacing http(s)://...
  const niceUrl: string = url.replace(process.env.REACT_APP_API_URL || '', '');

  // work out if there are query params sent in the request, if there are, stringify
  // them to build up an ideitifier for the request
  const params =
    query && !isObjectEmpty(query) ? ` ${JSON.stringify(query)}` : '';

  // work out the type of request
  const requestType: string = (request?.method || 'GET').toUpperCase();

  // build an identifier for the request (url & query)
  const requestId: string = `${requestType} ${niceUrl}${params}`;

  // can we cache the result
  const cacheable = cache && requestType === 'GET';

  // if we can cache the data and the data has been stored
  if (cache && CACHE[requestId]) {
    // check that the cache item is still valid
    if (
      typeof cache === 'number' &&
      CACHE[requestId].timestamp + cache < currentTime
    ) {
      if (process.env.NODE_ENV !== 'production') {
        console.info(`%cInvalid cache  ${requestId}`, 'color: #ffa200');
      }
    } else {
      // debugging
      if (process.env.NODE_ENV !== 'production') {
        console.log(
          `%c[${timestamp}](--) ${requestId} (cached)`,
          'color: #0042ff'
        );
      }

      // return the cached contents
      return CACHE[requestId].data;
    }
  }

  // debugging
  if (process.env.NODE_ENV === 'development') {
    // if the request has recently been made
    if (REQUEST_QUEUE.indexOf(requestId) > -1) {
      console.warn(`Multiple Requests ${requestId} `);
    }

    // add the latest request to the front of the queue
    REQUEST_QUEUE.unshift(requestId);

    // trim the request queue
    REQUEST_QUEUE = REQUEST_QUEUE.slice(0, 3);
  }

  // get the headers to send in the request
  const headers: any = withHeaders(request);

  try {
    // make the request
    const response: Response = await fetch(withQuery(`${url}`, query), headers);

    // get the data
    const data = await handleResponse<T>(response, url);

    // debugging
    if (process.env.NODE_ENV !== 'production') {
      // set up a blank/empty request count
      if (!REQUESTS[requestId]) {
        REQUESTS[requestId] = 0;
      }

      // increase the request count
      REQUESTS[requestId]++;

      // get the count for the current request
      const count: string = REQUESTS[requestId].toString().padStart(2, '0');

      // start a group to debug all the information
      console.groupCollapsed(
        `%c[${timestamp}](${count}) ${requestId}${
          cacheable ? ' (cacheable)' : ''
        }`,
        cacheable ? 'color: green' : ''
      );

      // if there is a query/request, output that data
      if (params) {
        console.log('req', query);
      }

      // output any headers
      if (headers && !isObjectEmpty(headers)) {
        console.log('hed', headers);
      }

      // output the result
      console.log('res', data);

      // close the group
      console.groupEnd();
    }

    // if the data can be cached, and the request type is a GET, store the result for future
    if (cacheable) {
      CACHE[requestId] = { timestamp: new Date().getTime(), data };
    }

    return data;
  } catch (e: any) {
    // debugging
    if (process.env.NODE_ENV !== 'production') {
      console.groupCollapsed(`%c[${timestamp}] ${requestId}`, 'color: red');

      // if there is a query/request, output that data
      if (params) {
        console.log('req', query);
      }

      // output any headers
      if (headers && !isObjectEmpty(headers)) {
        console.log('hed', headers);
      }

      // log the actual error
      console.error(e);

      // close the group
      console.groupEnd();
    }

    throw new Error(e.message);
  }
};

const getSessionHeader = (): string => {
  if (typeof Storage !== 'undefined') {
    const session = localStorage.getItem('session');

    if (session) {
      return JSON.parse(session);
    }
  }

  return '';
};

const setSessionHeader = (key: string) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem('session', JSON.stringify(key));
  }
};

export const withHeaders = (data: any = {}) => {
  data = {
    ...data,
    ...{
      mode: 'cors',
    },
  };

  if (!data['headers']) {
    data['headers'] = {};
  }

  data['headers']['accept'] = '*/*';
  data['headers']['user-agent'] = navigator.userAgent;

  const sessionHeader = getSessionHeader();
  if (sessionHeader) {
    data['headers']['fsg.focus360.cehsite.f360session'] = sessionHeader;
  }

  if (process.env.NODE_ENV !== 'development') {
    // X-CSRF-TOKEN
    // transfer the anti-forgery cookie to a header
    const csrfCookie = getCookieValue('XSRF-TOKEN');

    if (csrfCookie) {
      data['headers']['X-XSRF-TOKEN'] = csrfCookie;
    }
  }

  return data;
};
