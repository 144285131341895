// TODO: comment
import { useDidMountEffect, useRouter } from '@abrdn-latest/use';
import {
  getAppSettings,
  getInvestorTypes,
  getUserDetails,
  setLogout,
  userStatus,
} from 'api';
import { AuthenticationActions, isAuthenticatedResponse } from 'api/auth/types';
import { deleteActionRequiredCookie } from 'components/action-required';
import { canSetPerformanceCookies } from 'components/cookies';
import { Splash } from 'components/splash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { Fragment, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { deleteSessionStorageItems, sha256 } from 'utils';
import { SecurityModal, SecurityProvider } from './';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  brand: any;
  children: React.ReactNode;
}

let lastTrack: string = '';
let isGAInitialized = false;

export const Security = ({ children, brand }: Props) => {
  const router = useRouter();

  const [settings, setSettings] = useState<any | null>(null);

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [fullyAuthenticated, setFullyAuthenticated] = useState<boolean>(false);

  const [authScreens, setAuthScreens] = useState<AuthenticationActions>({
    memorableQuestionsResetRequired: false,
    passwordResetRequired: false,
    oneTimePasswordRequired: false,
  });

  const [authMessage, setAuthMessage] = useState<string | null>(null);

  const setupScreens = (actions: isAuthenticatedResponse) => {
    setAuthScreens({
      memorableQuestionsResetRequired: actions.memorableQuestionsResetRequired,
      passwordResetRequired: actions.passwordResetRequired,
      oneTimePasswordRequired: actions.oneTimePasswordRequired,
    });

    if (actions.authenticationStateMessage) {
      setAuthMessage(actions.authenticationStateMessage);
    }
  };

  const trackPage = async () => {
    // if we're on the homepage and not authenticated, don't track, as there will be a redirect
    if (
      (router.location.pathname === '/' && !authenticated) ||
      !canSetPerformanceCookies()
    ) {
      return;
    }

    if (settings) {
      //
      initializeTagManager(settings);

      const dataLayer = (window.dataLayer = window.dataLayer || []);
      let details = null;

      if (fullyAuthenticated) {
        details = await getUserDetails();
      }

      var payload = {
        environment: {
          setting: settings['Environment'],
          appType: 'web',
          timestamp: dayjs.utc().local().format(),
        },
        site: {
          country: 'uk',
          region: 'emea',
          language: 'en',
          vector: 'investment',
        },
        user: {
          loginId: details ? details.partyReference || '' : '',
          emailAddressHash: details?.emailAddress ? sha256(details.emailAddress) : '',
        },
        page: {
          pageUrl: router.location.pathname + router.location.search,
          pageTitle: document.title,
        },
        event: 'pageLoaded',
      };

      const id: string = JSON.stringify(payload);

      if (id !== lastTrack) {
        dataLayer.push(payload);
      }

      lastTrack = id;
    }
  };

  const initializeTagManager = (settings: any) => {
    // Google Analytics
    if (
      settings['GoogleAnalytics.Enabled'] &&
      settings['GoogleAnalytics.TrackingId'] &&
      canSetPerformanceCookies() &&
      !isGAInitialized
    ) {
      const tagManagerArgs = {
        gtmId: settings['GoogleAnalytics.TrackingId'],
      };

      TagManager.initialize(tagManagerArgs);

      isGAInitialized = true;
    }
  };

  useEffect(() => {
    const load = async () => {
      const status = await userStatus();

      const settings = await getAppSettings();

      await getInvestorTypes();

      // start tracking
      initializeTagManager(settings);

      if (status.isAuthenticated) {
        setAuthenticated(true);
        setupScreens(status);

        if (!status.oneTimePasswordRequired) {
          setFullyAuthenticated(true);
        }
      }

      setSettings(settings);
    };

    load();
  }, []);

  useDidMountEffect(() => {
    trackPage();
  }, [settings]);

  // Analytics
  useEffect(() => {
    setTimeout(() => {
      trackPage();
    }, 1);
  }, [router.location]);

  if (settings === null) {
    return <Splash {...brand} />;
  }

  const handleAuthRequired = () => {
    if (router.location.pathname !== '/auth/login' && !authenticated) {
      router.history.push('/auth/login');
    }
  };

  if (!fullyAuthenticated) {
    handleAuthRequired();
  }

  return (
    <Fragment>
      <Helmet></Helmet>
      <Splash {...brand} fadeOut={true} />
      <SecurityProvider
        value={{
          authState: {
            isAuthenticated: authenticated,
            // possibly set this to true in only when screens.oneTimePasswordRequired = false
            isFullyAuthenticated: fullyAuthenticated,
            isPending: false,
            screens: authScreens,
            authMessage,
          },
          settings,
          authMethods: {
            onLogin: async (response: isAuthenticatedResponse) => {
              const screens: isAuthenticatedResponse =
                response as isAuthenticatedResponse;

              // set the screens up
              setupScreens(screens);

              // set the authenticated state
              setAuthenticated(screens.isAuthenticated);

              const fullyAuthenticated =
                screens.isAuthenticated === true &&
                !screens.oneTimePasswordRequired;

              if (fullyAuthenticated) {
                // await dispatch(getUserDetails());
              }

              // oneTimePassword may be undefined of a boolean, so do not perform a strict check
              setFullyAuthenticated(fullyAuthenticated);
            },
            setScreens: (actions: isAuthenticatedResponse) => {
              // set the screens up
              setupScreens(actions);

              if (typeof actions.isAuthenticated === 'boolean') {
                // oneTimePassword may be undefined of a boolean, so do not perform a strict check
                setFullyAuthenticated(
                  actions.isAuthenticated === true &&
                    !actions.oneTimePasswordRequired
                );
              }
            },
            signOut: async () => {
              // only if the user is fully authenticated cam they call the logout endpoint
              // without generating an unauthorized error
              if (authenticated) {
                await setLogout();
              }

              // remove anction required flag cookie
              deleteActionRequiredCookie();

              // remove the session storage token
              if (typeof Storage !== 'undefined') {
                localStorage.removeItem('session');
              }

              // clear all session storage items
              deleteSessionStorageItems();

              // on logout, make another request to the anti-forgery token is refreshed
              await userStatus();

              if (fullyAuthenticated) {
                // reload the app to clear all the data down
                window.location.reload();
              }

              // fully sign the user out of the app
              setAuthenticated(false);
              setFullyAuthenticated(false);

              //
              // window.location.reload();
            },
          },
        }}
      >
        {children}
        <SecurityModal />
      </SecurityProvider>
    </Fragment>
  );
};
