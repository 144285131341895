// TODO: comment
// https://material-ui.com/components/skeleton/
import { Box } from '@material-ui/core';
import { ValuationsResponse } from 'api/types';
import dayjs from 'dayjs';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart as Chart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { abbreviateNumber, formatNumber, getFormattedMonth } from 'utils';
import { CustomTooltip } from './';

interface Props {
  data: ValuationsResponse;
}

export const LineChart = ({ data }: Props) => {
  // get the smallest value in the array
  const maxValue = Math.max.apply(
    null,
    data.map((item) => item.value)
  );

  // how many decimal places should it format to
  const decimals: number = 3;

  const maxFormatted = abbreviateNumber(maxValue, decimals).length;

  const tickFormatter = (tickItem: number): string => {
    return abbreviateNumber(tickItem, decimals);
  };

  const tickFormatterX = (tickItem: string): string => {
    const month = Number(dayjs(tickItem).format('M'));

    if (month % 3 === 0) {
      const quarter = Math.floor(month / 3);

      return `Q${quarter} ${dayjs(tickItem).format('YY')}`;
    }

    return '';
  };

  return (
    <div style={{ width: '100%' }}>
      <Box style={{ overflow: 'auto', overflowY: 'hidden' }}>
        <Chart
          width={650}
          height={250}
          data={data}
          margin={{
            top: 10,
            right: 5,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#e5e5eb" vertical={false} />
          <XAxis dataKey="date" tickFormatter={tickFormatterX} />
          <YAxis
            tickFormatter={tickFormatter}
            type="number"
            domain={['auto', 'auto']}
            width={Math.max(80, (maxFormatted + 1) * 10)}
          />
          <Tooltip
            content={
              <CustomTooltip
                formatter={(label, payload): string => {
                  return `${getFormattedMonth(label)} <br />${formatNumber(
                    payload.payload.value
                  )}`;
                }}
              />
            }
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#0057B7"
            strokeWidth={2}
          />
        </Chart>
      </Box>
    </div>
  );
};
