// TODO: comment
import { AllFilters } from 'api/types';
import { sendApiRequest } from '../utils';

/**
 *
 */
export const getFilters = async (
  /**
   * Investor Type
   */
  investorType?: string,
  /**
   * Country
   */
  countryCode?: string
): Promise<AllFilters> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/portfolio-holdings/fund-filters`;

  const data = {
    investorType: investorType ? investorType : undefined,
    countryCode: countryCode ? countryCode : undefined,
  };

  const response = await sendApiRequest<AllFilters>(endpoint, data, {}, true);
  return response;
};
