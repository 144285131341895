// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';
import { ClientAccountSummary } from './types';

/**
 * Gets summary account information for the specified client, optionally at the specified valuation date.
 */
export const getClientSummary = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,

  /**
   * Gets or sets the valuation date.
   * TODO: make required
   */
  valuationDate?: Date | null,

  /**
   * Gets or sets the currency code.
   * TODO: make required
   */
  currencyCode?: string | null
): Promise<ClientAccountSummary> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}`;

  const data: any = {};

  if (valuationDate) {
    data.valuationDate = dayjs(valuationDate).format('YYYY-MM-DD');
  }

  if (currencyCode) {
    data.currencyCode = currencyCode;
  }

  const response = await sendApiRequest<ClientAccountSummary>(
    endpoint,
    data,
    {},
    10000
  );

  return response;
};
