// TODO: comment

export const THOUSAND_UNIT: string = 'k';
export const MILLION_UNIT: string = 'mn';
export const BILLION_UNIT: string = 'bn';

const MoneyDefaults = {
  billion: {
    decimal: 1,
    unit: BILLION_UNIT,
  },
  million: {
    decimal: 1,
    unit: MILLION_UNIT,
  },
  thousand: {
    decimal: 1,
    unit: THOUSAND_UNIT,
  },
};

export const roundMoney = (value: number, options: any = MoneyDefaults) => {
  let number = Math.abs(Number(value));

  // Nine zeros for Billions
  if (Number(number) >= 1.0e9) {
    return (
      (number / 1.0e9).toFixed(options.billion.decimal) +
      `${options.billion.unit}`
    );
  }

  // Six zeros for Millions
  if (Number(number) >= 1.0e6) {
    return (
      (number / 1.0e6).toFixed(options.million.decimal) +
      `${options.million.unit}`
    );
  }

  // Thrhee zeros for Thousands
  if (Number(number) >= 1.0e3) {
    return (
      (number / 1.0e3).toFixed(options.thousand.decimal) +
      `${options.thousand.unit}`
    );
  }

  return number;
};
