// TODO: comment
import { toast } from 'material-react-toastify';
import { sendApiRequest } from '../utils';

export const deleteReceivedMessage = async (
  /**
   *
   */
  id: string,
  /**
   *
   */
  showToast: boolean = true
) => {
  const response = await sendApiRequest<any>(
    `${process.env.REACT_APP_API_URL}api/messages/received/${id}`,
    null,
    { method: 'DELETE' }
  );

  if (showToast) {
    toast.dark('Message Deleted');
  }

  return response;
};
