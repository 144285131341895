// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReceivedMessages as api_getReceivedMessages } from 'api';
import { getMessagesPerPage } from 'api/constants';
import { MessageListStateProps, MessagesRequest } from './types';
export const getReceivedMessages = createAsyncThunk(
  'message/get-received',
  async ({ start = 0, max = getMessagesPerPage() }: MessagesRequest) => {
    const messages = await api_getReceivedMessages(start, max);

    return messages;
  }
);

const initialState: MessageListStateProps = {
  status: '',
  items: {
    pagination: {},
    messages: [],
  },
};

const messageSlice = createSlice({
  name: 'messages-received',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReceivedMessages.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getReceivedMessages.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getReceivedMessages.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });
  },
});

export const selectReceivedMessages = (state: any) => {
  return state['messages-received'].items;
};

export const selectReceivedStatus = (state: any): string => {
  return String(state['messages-received'].status);
};

export default messageSlice.reducer;
