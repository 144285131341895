// TODO: comment
import { sendApiRequest } from '../utils';

export const getDocumentTypes = async () => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/documents/document-types`;

  const response = await sendApiRequest<any>(endpoint, null, {}, true);

  return response;
};
