// TODO: comment
import { SuccessResponse } from 'api/types';
import { sendApiRequest } from '../utils';

export const setMemorableQuestions = async (
  currentPassword: string,
  /**
   *
   */
  question_one: number,
  /**
   *
   */
  answer_one: string,
  /**
   *
   */
  question_two: number,
  /**
   *
   */
  answer_two: string
): Promise<SuccessResponse> => {
  const data = {
    currentPassword,
    question1: {
      id: question_one,
      answer: answer_one,
    },
    question2: {
      id: question_two,
      answer: answer_two,
    },
  };

  const response = await sendApiRequest<SuccessResponse>(
    `${process.env.REACT_APP_API_URL}api/account/change-memorable-questions`,
    null,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
