// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAccounts } from 'api';
import { getPartiesPerPage } from 'api/constants';

export interface AccountsRequest {
  max?: number;
  start: number;
}

export const getParties = createAsyncThunk(
  'parties',
  async (
    { start = 0, max = getPartiesPerPage() }: AccountsRequest,
    { getState }
  ) => {
    const result = await getAccounts(start, max);
    return result;
  }
);

export interface partyState {
  items: any;
  status: any;
}

const initialState: partyState = {
  items: {
    pagination: {},
    parties: [],
  },
  status: '',
};

const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Action Required Messages
    builder.addCase(getParties.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getParties.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getParties.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });
  },
});

export const selectParties = (state: any) => {
  return state.party.items;
};

export const selectPartiesStatus = (state: any) => {
  return state.party.status;
};

export default partySlice.reducer;
