// TODO: comment
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { abrdnDark } from './';

interface Props {
  children: React.ReactNode;
}

export const ThemeDark = ({ children }: Props) => {
  return <ThemeProvider theme={abrdnDark}>{children}</ThemeProvider>;
};
