// TODO: comment
import { Box, Container, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const { t } = useTranslation(['common']);

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.error-404')}</title>
      </Helmet>
      <Container>
        <Box paddingY={4}>
          <Typography variant="h3" component="h1">
            {t('common:titles.error-404')}
          </Typography>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Page404;
