// TODO: comment
import { States } from '@abrdn-latest/config';
import { ClientAccountSummary, Party } from 'api/types';
import { sendApiRequest } from '../utils';

export type AllPartiesResponse = Party[];

/**
 * Get a list of the users accounts
 */
export const getAllParties = async (): Promise<AllPartiesResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/parties/all`;

  try {
    return sendApiRequest<AllPartiesResponse>(endpoint);
  } catch (e) {
    return [];
  }
};

export const getDocumentOnlyParties = async (): Promise<AllPartiesResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/parties/documentsonly/all`;

  return await sendApiRequest<AllPartiesResponse>(endpoint);
};

export interface UserClientDetail extends Party, ClientAccountSummary {
  state: string;
}

export type UserClientDetails = UserClientDetail[];

export interface DocumentOnlyClientDetail extends UserClientDetail {
  documentOnly: true;
}

export type DocumentOnlyClientDetails = DocumentOnlyClientDetail[];

let documentOnlyClientList: DocumentOnlyClientDetails;

export const getDocumentOnlyClientDetails = async (): Promise<DocumentOnlyClientDetails> => {
  if (documentOnlyClientList) {
    return documentOnlyClientList;
  }

  // get document only parties
  const documentOnlyParties = await getDocumentOnlyParties();

  //
  const blankList: UserClientDetail = {
    ...{ id: '', preferredName: '', currencyCodes: [], clientReference: '' },
    ...{
      state: States.Idle,
      accounts: [],
      client: {
        id: '',
        name: '',
      },
      currencyCodes: [],
      valuation: {
        currencyCode: '',
        date: '',
        value: 0,
      },
    },
  };

  documentOnlyClientList = [];

  // loop through parties and populate them with blank data
  for (const documentOnlyParty of documentOnlyParties) {
    documentOnlyClientList.push({
      ...blankList,
      ...documentOnlyParty,
      documentOnly: true,
    });
  }

  return documentOnlyClientList;
};
