// TODO: comment
import { ThemeOptions } from '@material-ui/core/styles';

const lightTheme: ThemeOptions = {
  palette: {
    type: 'light',
    text: {
      primary: '#000',
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#141e50',
    },
  },
};

export default lightTheme;
