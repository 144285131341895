// TODO: comment
import { getLocalisedDate } from './';

export const getFormattedDate = (
  date: string | Date,
  showTime: boolean = true,
  showToday: boolean = true
): string => {
  // get todays date
  const today = getLocalisedDate().format('DD MMM YYYY');

  const localized = getLocalisedDate(date);

  // get the parsed date
  const parsed = localized.format('DD MMM YYYY');

  // if the dates match, only show the time
  if (showToday && today === parsed) {
    if (showTime) {
      return `Today ${localized.format('HH:mm')}`;
    }

    return 'Today';
  }

  // return full date
  return parsed;
};
