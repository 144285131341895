// TODO: comment
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getActionRequiredCount,
  getMessageSubjects as api_getMessageSubjects,
  getUnreadMessageCount,
} from 'api';
import { MessageSubject } from 'api/types';

export const getMessageSubjects = createAsyncThunk(
  'message/subjects',
  async (_, { getState }) => {
    const items = await api_getMessageSubjects();
    return items;
  }
);

export const getUnreadCount = createAsyncThunk(
  'message/get-count',
  async () => {
    const count = await getUnreadMessageCount();

    return count;
  }
);

export const getActionRequired = createAsyncThunk(
  'message/get-action-required',
  async () => {
    const response = await getActionRequiredCount();

    return response;
  }
);

interface StateProps {
  action: number;
  deleted: Array<any>;
  important: any;
  inbox: any;
  message: any;
  sent: any;
  status: any;
  subjects: Array<MessageSubject>;
  unreadCount: number;
}

const initialState: StateProps = {
  status: {
    inbox: '',
    important: '',
    sent: '',
  },
  inbox: {
    pagination: {},
    messages: [],
  },
  important: {
    pagination: {},
    messages: [],
  },
  sent: {
    pagination: {},
    messages: [],
  },
  action: 0,
  unreadCount: 0,
  message: {},
  deleted: [],
  subjects: [],
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.inbox = action.payload;
    },
    // create
    // read
    readMessages: (state, action) => {
      state.inbox = action.payload;
    },
    // update
    // delete
    deleteMessages: (state, action) => {},
  },
  extraReducers: (builder) => {
    // Get Action Required Messages
    builder.addCase(getMessageSubjects.pending, (state, action) => {
      // state.status = 'loading';
    });
    builder.addCase(getMessageSubjects.fulfilled, (state, action) => {
      state.subjects = action.payload;
      // state.status = 'success';
    });
    builder.addCase(getMessageSubjects.rejected, (state, action) => {
      // state.status = 'failed';
    });

    // Get Action Required Messages

    builder.addCase(getActionRequired.pending, (state, action) => {
      // state.status = 'loading';
    });
    builder.addCase(getActionRequired.fulfilled, (state, action) => {
      state.action = action.payload.count;
      // state.status = 'success';
    });
    builder.addCase(getActionRequired.rejected, (state, action) => {
      // state.status = 'failed';
    });

    // End Action Required Messages

    // Get Message Count
    builder.addCase(getUnreadCount.pending, (state, action) => {
      // state.status = 'loading';
    });
    builder.addCase(getUnreadCount.fulfilled, (state, action) => {
      state.unreadCount = action.payload.count;
      // state.status = 'success';
    });
    builder.addCase(getUnreadCount.rejected, (state, action) => {
      // state.status = 'failed';
    });
    // End Message Count
  },
});

// export const { setMessages } = messageSlice.actions;

export const selectActionRequiredCount = (state: any) => {
  return state.messages.action;
};

export const selectUnreadCount = (state: any) => {
  return state.messages.unreadCount;
};

export const selectMessage = (state: any) => {
  return state.messages.message;
};

export const selectMessageSubjects = (state: any) => {
  return state.messages.subjects;
};

export default messageSlice.reducer;
