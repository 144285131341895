// TODO: comment

import { getClientSummary } from 'api/client-holdings';
import { getDocumentsPerPage } from '../constants';
import { sendApiRequest } from '../utils';

export const getDocuments = async (
  /** */
  start: number = 0,
  /** */
  max: number = getDocumentsPerPage(),
  /** */
  documentTypeCode?: string,
  /**  */
  clientId?: string
) => {
  const data: any = {
    startRowIndex: start,
    maximumRows: max,
  };

  if (documentTypeCode && documentTypeCode !== 'all') {
    data.documentTypeCode = documentTypeCode;
  }

  if (clientId && clientId !== 'all') {
    // TODO: async await account ids
    const summary = await getClientSummary(clientId);

    if (summary) {
      data.accountIds = summary.accounts.map((account) => account.id);
    }
  }

  try {
    const response = await sendApiRequest<any>(
      `${process.env.REACT_APP_API_URL}api/documents`,
      data
    );

    return response;
  } catch (e: any) {
    return {
      pagination: {
        maximumRows: max,
        minimumTotalRows: 0,
        startRowIndex: start,
        totalRows: 0,
      },
      documents: [],
    };
  }
};
