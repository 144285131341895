// TODO: comment
import { getDocumentUri } from 'api';
import { MessageDocument } from 'api/types';
import { downloadFile } from 'api/utils';
import { Anchor } from 'components/core';
import { DescriptionIcon, GetAppIcon } from 'icons';
import React, { Fragment } from 'react';

interface DocumentListProps {
  documents?: Array<MessageDocument>;
}

export const DocumentList = ({ documents }: DocumentListProps) => {
  if (documents && documents.length > 0) {
    const output = documents.map((item: MessageDocument, i: number) => {
      return (
        <dl key={i} className="document">
          <dt>
            <DescriptionIcon /> {item.description}
          </dt>
          <dd>
            <Anchor
              href={getDocumentUri(item.id)}
              onClick={async (ev: React.MouseEvent) => {
                ev.preventDefault();
                downloadFile(getDocumentUri(item.id), `${item.filename}`);
              }}
              style={{ wordBreak: 'break-all' }}
            >
              {item.filename} <GetAppIcon style={{ marginLeft: 10 }} />
            </Anchor>
          </dd>
        </dl>
      );
    });

    return <Fragment>{output}</Fragment>;
  }

  return <Fragment></Fragment>;
};
