import React from 'react';

export type ActionOverridesType = {
  [messageId: string]: boolean;
};

export type MessageContextType = {
  actionOverrides: ActionOverridesType;
  setOverride(messageId: string, state: boolean): void;
};

const messageContext = React.createContext<MessageContextType | null>(null);

/**
 * returns the context for messages
 * @returns
 */
export const useMessageContext = () => {
  const context = React.useContext(messageContext);

  if (!context) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }

  return context;
};

export const MessageProvider = messageContext.Provider;
