import React, { createContext, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

/**
 *
 */
interface Context {
  isHomepage?: boolean;
}

/**
 *
 */
const initialState: Context = {
  isHomepage: false,
};

/**
 *
 */
const AppContext = createContext<Context>(initialState);

/**
 *
 * @returns
 */
export const useApp = (): Context => useContext(AppContext);

/**
 *
 */
export const AppProvider = AppContext.Provider;

/**
 *
 */
interface Props {
  /**
   *
   */
  children: React.ReactNode;
}

/**
 *
 */
export const AppProviderWrapper = ({ children }: Props) => {
  const match = useRouteMatch({
    path: '/',
    strict: true,
    sensitive: true,
  });

  const value = {
    isHomepage: match?.isExact,
  };

  return <AppProvider value={value}>{children}</AppProvider>;
};
