// TODO: comment
import { FundResponse } from 'api/types';
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';

/**
 * Returns the detail of the latest Client Holdings for the fund code provided, including a paginated list of holdings.
 * Optional valuation date can be supplied to select a specific date for the data.
 */
export const getClientAccountHoldings = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId: string,
  /**
   * Gets or sets the valuation date.
   */
  valuationDate?: Date | null | undefined,
  /**
   * The page number of the holdings to return.
   */
  pageNumber: number = 0,
  /**
   * Size of the page of holdings to return. Optional. Defaults to 50.
   */
  pageSize: number = 50,
  /**
   * Holdings sort clause. Optional. One of: assetclass, marketvalue or countryofissue with optional {_asc} or {_desc} suffix.
   */
  pageSortBy: string = '',
  /**
   * Gets or sets the page sort by clause. Optional. One of: assetclass, marketvalue or countryofissue with optional _asc or _desc suffix.
   */
  pageSortOrder: 'asc' | 'desc' = 'asc',
  /**
   * Holdings filter clause. Optional. Case insensitive.
   */
  pageFilter: string = ''
): Promise<FundResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}/holdings`;

  const data = {
    //
    valuationDate: valuationDate
      ? dayjs(valuationDate).format('YYYY-MM-DD')
      : undefined,
    // the api is not zero based, so adjust it
    pageNumber: pageNumber + 1,
    pageSize,
    pageSortBy: pageSortBy ? `${pageSortBy}_${pageSortOrder}` : undefined,
    pageFilter: pageFilter || undefined,
  };

  const response = await sendApiRequest<FundResponse>(endpoint, data);

  if (response) {
    // adjust the response to page the paging zero based
    response.paging.pageNumber = response.paging.pageNumber - 1;
  }

  return response;
};
