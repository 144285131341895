// TODO: comment
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { ChangePasswordForm, MemorableQuestionsForm } from 'authentication';
import { Breadcrumbs } from 'components/navigation';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PageSettings = () => {
  const { t } = useTranslation(['common']);

  // return the view
  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.settings')}</title>
      </Helmet>

      <Breadcrumbs
        trail={[{ label: 'Home', href: '/' }, { label: 'Settings' }]}
      />
      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h1">
            {t('common:titles.settings')}
          </Typography>

          <Grid container spacing={3} direction="row" justify="space-between">
            <Grid item xs={12} sm={6}>
              <MemorableQuestionsForm persistForm buttonLabel="Save" isPage />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ChangePasswordForm
                persistForm
                buttonLabel="Update Password"
                isPage
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default PageSettings;
