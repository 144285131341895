// TODO: comment
import { useOnlineStatus } from '@abrdn-latest/use';
import { Box, Paper, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { useEffect } from 'react';

let count: number = 0;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Connectivity = () => {
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (count++ > 1 && isOnline === true) {
      window.location.reload();
    }
  }, [isOnline]);

  return (
    <Dialog
      open={!isOnline}
      TransitionComponent={Transition}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Paper>
        <Box padding={4}>
          <Typography>
            Network Error. Please check your connectivity and try again.
          </Typography>
        </Box>
      </Paper>
    </Dialog>
  );
};
