// TODO: comment
import { OurThinkingModel } from './types';

export const getArticles = async () => {
  const data: OurThinkingModel[] = [
    {
      id: 'item-1',
      title: 'Attractive income from listed alternative investments',
      image: 'https://picsum.photos/400/200',
      date: '05-04-2001',
      readTime: '5 mins',
      format: 'doc',
      excerpt:
        'Tony Foster, Investment Director with the Diversified Assets team, retires in April 2021. He looks back at some of the turning points in his fund',
    },
    {
      id: 'item-2',
      title:
        'Macro Matters: what rising bond yields mean for markets and the economy',
      image: 'https://picsum.photos/400/200',
      date: '05-04-2001',
      readTime: '5 mins',
      format: 'doc',
      excerpt:
        'Are higher bond yields a headwind to the economic recovery and financial markets, or justified by the improvement in economic prospects as',
    },
    {
      id: 'item-3',
      title: 'From small- to large-caps – the case for European equities',
      image: 'https://picsum.photos/400/200',
      date: '05-04-2001',
      readTime: '5 mins',
      format: 'doc',
      excerpt:
        'The Covid-19 crisis is unlike anything we have ever faced. However, we are starting to see a glimmer of hope. Markets, too, appear to be over the',
    },
    {
      id: 'item-4',
      title: 'Investing for Net Zero',
      date: '05-04-2001',
      readTime: '5 mins',
      format: 'doc',
    },
    {
      id: 'item-5',
      title: 'UK mid-caps – far from middle of the road',
      date: '05-04-2001',
      readTime: '5 mins',
      format: 'doc',
    },
  ];

  return data;
};
