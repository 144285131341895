// TODO: comment
// @ts-nocheck
// https://github.com/satansdeer/completed/tree/master/src

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import MaterialSelect from '@material-ui/core/Select';
import { OptionItem } from 'api/types';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

interface Props {
  control: any;
  defaultValue?: string;
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  options: OptionItem[];
}

/**
 *
 */
export const Select = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [value, setValue] = React.useState('');

  const handleChange = (ev: any) => {
    setValue(ev.target.value);
  };

  const { options, label, error, name, defaultValue } = props;
  const { helperText, ...rest } = props;

  return (
    <FormControl variant="outlined" className="form-control" error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        labelId={`${name}-label`}
        id={name}
        value={value}
        onChange={handleChange}
        variant="outlined"
        inputRef={ref}
        fullWidth
        {...rest}
        as={
          <MaterialSelect defaultValue={defaultValue}>
            {options
              ? options.map((item, index: number) => {
                  return (
                    <MenuItem value={item.value} key={index}>
                      {item.displayText}
                    </MenuItem>
                  );
                })
              : []}
          </MaterialSelect>
        }
      />

      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
});
