// TODO: comment
import { GetActionMessageCountResponse } from '../types';
import { sendApiRequest } from '../utils';

/**
 * Get the number of received messages that need action required since the users last login
 */
export const getActionRequiredCount = async (
  /**
   * The maximum number of items to return
   */
  max: number = 10
): Promise<GetActionMessageCountResponse> => {
  const response = await sendApiRequest<GetActionMessageCountResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/received/action-required/count-since-last-login`,
    {
      maximumRows: max,
    }
  );

  return response;
};
