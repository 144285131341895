import { IconButton, TextField } from '@material-ui/core';
import { ClearIcon, SearchIcon } from 'icons';
import { useEffect, useState } from 'react';

interface Props {
  /**
   * Can the search field be cleared
   */
  clearable?: boolean;

  /**
   * Delay in ms before search/callback is triggered
   */
  delay?: number;

  /**
   * Input name/id
   */
  id: string;

  /**
   *
   */
  label?: string;

  /**
   *
   */
  onChange(value: string): void;
}

export const SearchInput = ({
  clearable = true,
  delay = 700,
  id,
  label = 'Search',
  onChange,
}: Props) => {
  //
  let timer: any = null;

  //
  const [value, setValue] = useState<string>('');

  // when the value changes we want to trigger any events
  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  /**
   * CLear the search input value
   */
  const clearSearch = () => {
    setValue('');

    try {
      const el = document.getElementById(id);

      if (el) {
        // @ts-ignore
        el.value = '';
      }
    } catch (e) {}
  };

  /**
   * Handles the search event
   */
  const handleSearch = (ev: any) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      // get the value from the search input,
      const value: string = String(ev.target.value).trim();

      setValue(value);
      clearTimeout(timer);
      timer = null;
    }, delay);
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      id={id}
      placeholder={label}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <SearchIcon fontSize="small" style={{ marginRight: 10 }} />
        ),
        endAdornment: clearable ? (
          <IconButton
            size="small"
            color="primary"
            aria-label="clear"
            component="span"
            onClick={() => {
              clearSearch();
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ) : null,
      }}
    />
  );
};
