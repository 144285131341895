// TODO: comment
import { makeStyles } from '@material-ui/core/styles';
import IframeResizer from 'iframe-resizer-react';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    width: '100%',
  },
}));

export interface IFrameProps {
  src: string;
}

export const IFrame: FunctionComponent<IFrameProps> = ({ src }) => {
  const classes = useStyles();

  return (
    <IframeResizer
      className={classes.root}
      src={src}
      style={{ width: '1px', minWidth: '100%', height: '650px' }}
    />
  );
};
