// TODO: comment
export type Keys = {
  [key: string]: string | number;
};

export const formatString = (str: string, keys: Keys = {}): string => {
  Object.keys(keys).forEach((key) => {
    str = str.replace(`{${key}}`, String(keys[key]));
  });

  return str;
};
