// TODO: comment
import { Box, Container } from '@material-ui/core';
import { ErrorIcon } from 'icons';
import React from 'react';
import './NoData.scss';

interface DataErrorProps {
  children?: React.ReactNode;
}

export const DataError = ({ children }: DataErrorProps) => {
  return (
    <Box className="data-error" padding={4} textAlign="center">
      <Container maxWidth="md">
        <ErrorIcon style={{ fontSize: 50 }} />
        {children ? children : <div>Something went wrong</div>}
      </Container>
    </Box>
  );
};
