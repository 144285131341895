// TODO: comment
import { sendApiRequest } from '../utils';
import { sendOTPResponse } from './types';

/**
 * Sends a new one-time password code for the current user/party.
 * @returns
 */
export const sendOTP = async (): Promise<sendOTPResponse> => {
  const response = await sendApiRequest<sendOTPResponse>(
    `${process.env.REACT_APP_API_URL}api/account/login/otp/send`,
    null,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return !!response;
};
