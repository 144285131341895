// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageDocuments from './PageDocuments';
import PageNewDocument from './PageNewDocument';

export const DocumentRoutes = () => {
  return (
    <Fragment>
      <SecureRoute
        exact
        path={['/documents', '/documents/:category']}
        component={PageDocuments}
      />
      <SecureRoute exact path="/document/new" component={PageNewDocument} />
    </Fragment>
  );
};
