// TODO: comment
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import './Progress.scss';

// TODO: move this to scss
const useStylesFacebook = makeStyles((theme) => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props: any) {
  const classes = useStylesFacebook();

  return (
    <div className="progress">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </div>
  );
}

interface Props {
  label?: string;
}

export const Progress = ({ label }: Props) => {
  return (
    <Fragment>
      <FacebookCircularProgress /> {label && <span>{label}</span>}
    </Fragment>
  );
};
