// TODO: comment
import { css } from 'styled-components';
import { mediaQueries } from './mediaQueries';
export interface theme {
  background?: any;
  colors: {
    primary: string;
    secondary: string;
    text: string;
  };
  fontSizes: {
    base: {
      lg: number;
      md: number;
      sm: number;
      xl: number;
    };
  };
  palette?: any;
  typography?: any;
}

//default theme
export const baseTheme: any = {
  typography: {
    fontSize: 16,
    fontFamily: "'SharpSans-medium', Arial, sans-serif",
    lineHeight: 1.6,

    h1: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '32px',
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: '64px',
      },
    },
    h2: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '48px',
      marginBottom: '30px',
    },
    h3: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '32px',
      marginBottom: '30px',
    },
    h4: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '24px',
      marginBottom: '30px',
    },
    h5: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '16px',
      marginBottom: '30px',
    },
    h6: {
      fontFamily: "'SharpSans-semibold', Arial, sans-serif",
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '30px',
    },
    subtitle1: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
    button: {
      textTransform: 'none',
      borderRadius: 10,
    },
  },
  shape: {
    borderRadius: 0,
  },
  colors: {
    primary: '#1a9ce3',
    secondary: '#141e50',
    text: '#ff00ff',
  },
  fontSizes: {
    base: {
      sm: 16,
      md: 14,
      lg: 12,
      xl: 12,
    },
  },

  palette: {
    warning: {
      main: '#fff',
    },
    info: {
      main: '#000',
    },
  },

  overrides: {
    MuiIconButton: {
      // root: { color: '#000' },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 30,
        margin: 0,
        fontSize: 12,
      },

      outlinedSizeLarge: {
        fontSize: 12,
      },
      containedSizeLarge: {
        fontSize: 12,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    MuiTable: {
      root: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#BDBDBD',
        borderCollapse: 'collapse',
      },
    },
    MuiTableCell: {
      root: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#BDBDBD',
      },
    },
    MuiAlert: {
      standardWarning: {
        color: '#fff',
        backgroundColor: '#00205B',
      },
      standardError: {
        color: '#fff',
        backgroundColor: '#EF2B35 ',
      },
      standardSuccess: {
        color: '#fff',
        backgroundColor: '#008164 ',
      },
      standardInfo: {
        color: '#000',
        backgroundColor: '#DDE5ED',
      },
    },
  },

  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
};

//styled components snippets
//fontSize stack
export const fontSizes = (theme: theme) => css`
  ${(props) => mediaQueries('sm')(`font-size: ${theme?.fontSizes?.base?.sm}px`)}
  ${(props) => mediaQueries('md')(`font-size: ${theme?.fontSizes?.base?.md}px`)}
  ${(props) => mediaQueries('lg')(`font-size: ${theme?.fontSizes?.base?.lg}px`)}
  ${(props) => mediaQueries('xl')(`font-size: ${theme?.fontSizes?.base?.xl}px`)}
`;
