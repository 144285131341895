// TODO: comment
import { FundResponse } from 'api/types';
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';

/**
 *
 */
export const getFundData = async (
  /**
   * The fund code
   */
  fundCode: string,
  /**
   * The valuation date
   */
  valuationDate: Date | null | undefined,
  /**
   * The page number of the holdings to return.
   */
  pageNumber: number = 0,
  /**
   * Size of the page of holdings to return. Optional. Defaults to 50.
   */
  pageSize: number = 50,
  /**
   * Holdings sort clause. Optional. One of: assetclass, marketvalue or countryofissue with optional {_asc} or {_desc} suffix.
   */
  pageSortBy: string = '',
  /**
   *
   */
  pageSortOrder: 'asc' | 'desc' = 'asc',
  /**
   * Holdings filter clause. Optional. Case insensitive.
   */
  pageFilter: string = ''
): Promise<FundResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/portfolio-holdings/funds/${fundCode}`;

  const data = {
    //
    valuationDate: valuationDate
      ? (() => {
          const selectedDate = new Date(
            valuationDate.getFullYear(),
            valuationDate.getMonth() + 1,
            0
          );

          return dayjs(selectedDate).format('YYYY-MM-DD');
        })()
      : undefined,
    // the api is not zero based, so adjust it
    pageNumber: pageNumber + 1,
    pageSize,
    pageSortBy: pageSortBy ? `${pageSortBy}_${pageSortOrder}` : undefined,
    pageFilter: pageFilter || undefined,
  };

  const response = await sendApiRequest<FundResponse>(endpoint, data);

  if (response) {
    // adjust the response to page the paging zero based
    response.paging.pageNumber = response.paging.pageNumber - 1;
  }

  return response;
};
