// TODO: comment
import { ArticlePreview } from '@abrdn-latest/core';
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { getArticles } from 'api';
import { OurThinkingModel } from 'api/types';
import { Anchor } from 'components/core';
import React, { Fragment, useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  tile: {
    border: '1px solid #D9D9D6',
  },
}));

export const TilePlaceholder = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.tile}>
      <Box>
        <Skeleton variant="rect" width="100%" height={200} />
      </Box>
      <Box padding={2}>
        <Typography variant="h4">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </Typography>
      </Box>
    </Paper>
  );
};

export const OurThinkingWidget = () => {
  const classes = useStyles();

  const [articles, setArticles] = useState<null | OurThinkingModel[]>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const load = async () => {
      const items = await getArticles();

      if (items) {
        setArticles(items);
      }

      setLoading(false);
    };

    load();
  }, []);

  if (!articles) {
    return null;
  }

  return (
    <Paper>
      <Box paddingY={8}>
        <Container>
          <Typography variant="h3">Our thinking</Typography>
          <Grid container spacing={3} alignItems="stretch">
            {articles
              .slice(0, 2)
              .map((article: OurThinkingModel, index: number) => {
                const link: string = `/our-thinking/${article.id}`;

                if (loading) {
                  return (
                    <Grid item xs={12} sm={4} key={`i-${index}`}>
                      <TilePlaceholder />
                    </Grid>
                  );
                }

                return (
                  <Grid item xs={12} sm={4} key={`i-${index}`}>
                    <ArticlePreview
                      title={article.title}
                      image={article.image}
                      url={link}
                      excerpt={article.excerpt}
                      readTime={article.readTime}
                      date={article.date}
                    />
                  </Grid>
                );
              })}

            <Grid item xs={12} sm={4}>
              {articles
                .slice(2)
                .map((article: OurThinkingModel, index: number) => {
                  const link: string = `/our-thinking/${article.id}`;
                  return (
                    <Fragment key={`a-${index}`}>
                      <Box paddingY={2}>
                        <Typography variant="h5">
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Anchor to={link}>{article.title}</Anchor>
                          )}
                        </Typography>
                        {!loading && (
                          <Box>
                            <Chip
                              size="small"
                              color="primary"
                              variant="outlined"
                              label={article.date}
                            />
                            <Chip
                              size="small"
                              color="primary"
                              variant="outlined"
                              label={`${article.format} ${article.readTime}`}
                            />
                          </Box>
                        )}
                      </Box>
                      <Divider />
                    </Fragment>
                  );
                })}
            </Grid>
          </Grid>

          <Box marginTop={4}>
            <Anchor to={'/our-thinking/'}>View more articles</Anchor>
          </Box>
        </Container>
      </Box>
    </Paper>
  );
};
