// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageFundDetail from '../portfolio-holdings/PageFundDetail';
import PageAccount from './PageAccount';

export const AccountsRoutes = () => {
  return (
    <Fragment>
      {/* <SecureRoute
        path="/accounts/:client/:section/detail/:fundCode"
        component={PageFundDetail}
        exact
      /> */}

      <SecureRoute
        path="/accounts/:clientId/account/:accountId/holdings"
        component={PageFundDetail}
        exact
      />

      <SecureRoute
        path="/accounts/:client/:section"
        component={PageAccount}
        exact
      />
    </Fragment>
  );
};
