// TODO: comment
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@abrdn';
import { Paper } from '@material-ui/core';
import { getPartiesPerPage, setPartiesPerPage } from 'api/constants';
import { GetPartiesResponse, Party } from 'api/types';
import { Pagination } from 'components/pagination';
import { EnhancedTableHead } from 'components/table';
import 'components/table/Table.scss';
import React, { Fragment, useState } from 'react';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    icon: false,
  },
  {
    id: 'reference',
    numeric: false,
    disablePadding: false,
    label: 'Client Reference',
  },
];
interface DocumentsTableProps {
  data: GetPartiesResponse;
  onPagination(page: number, rows: number): void;
  title: string;
}

export const MyAccountsTable = ({
  data,
  onPagination,
  title,
}: DocumentsTableProps) => {
  const [selected, setSelected] = useState<any[]>([]);
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getPartiesPerPage());

  const rows: Array<Party> = data.parties;

  const handleSelectAllClick = (ev: any) => {
    if (ev.target.checked) {
      const newSelecteds = rows.map((n: Party) => {
        return n.id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onPageChange = (items: number) => {
    setRowsPerPage(items);

    onPagination(0, items);

    setPartiesPerPage(items);
  };

  // @ts-ignore
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  return (
    <Fragment>
      <Paper>
        <TableContainer>
          <Table className="accounts-table" size="medium">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              canDelete={false}
            />
            <TableBody>
              {rows.map((document: Party, index: number) => {
                const isItemSelected = isSelected(document.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={document.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className="sender"
                    >
                      {document.preferredName}
                    </TableCell>
                    <TableCell className="description">
                      {document.clientReference}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        onPagination={onPagination}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        startRowIndex={data.pagination.startRowIndex}
        maximumRows={data.pagination.maximumRows}
        minimumTotalRows={data.pagination.minimumTotalRows}
        totalRows={data.pagination.totalRows}
      />
    </Fragment>
  );
};
