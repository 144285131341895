// TODO: comment
export const validCharacters = (value: any) => {
  return String(value).match(/[.:/]+/gi) === null && noHTML(value);
};

export const noHTML = (value: any) => {
  const isNotHTML = String(value).match(/(<[A-Za-z!/?])/gi) === null;
  const isNotEscaped = String(value).match(/(&#)/gi) === null;

  return isNotHTML && isNotEscaped;
};
