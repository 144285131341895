// TODO: comment
import { ReceivedMessage } from '../types';
import { sendApiRequest } from '../utils';

/**
 * Get the received message in the users inbox
 */
export const getReceivedMessage = async (
  /**
   * Message ID
   */
  id: string
): Promise<ReceivedMessage> => {
  const response = await sendApiRequest<ReceivedMessage>(
    `${process.env.REACT_APP_API_URL}api/messages/received/${id}`
  );
  return response;
};
