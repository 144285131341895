// TODO: comment
import {
  STATUS_FAILED,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getMemorableQuestions, setMemorableQuestions } from 'api';
import { MemorableQuestions } from 'api/types';
import { ValidationProvider } from 'components/file-selector';
import { Form, Input, Password, Select } from 'components/form';
import { ReplayIcon } from 'icons';
import { FormMessage } from 'interfaces';
import { toast } from 'material-react-toastify';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { AuthProps, ClickEvent } from './';

interface Props extends AuthProps {
  canRefresh?: boolean;
  isPage?: boolean;
  onLoginClick?: boolean | ClickEvent;
}

export const MemorableQuestionsForm = ({
  hideTitle,
  onComplete,
  onLoginClick = true,
  buttonLabel = 'Save and Continue',
  canRefresh = true,
  persistForm,
  isPage = false,
}: Props) => {
  const { t } = useTranslation(['common']);

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  const [formStatus, setFormStatus] = useState('');
  const [questions, setQuestions] = useState<MemorableQuestions>([]);
  const [q1] = useState<number>(0);
  const [q2] = useState<number>(0);

  const schema = yup.object().shape({
    password: yup.string().required('Current password is required'),
    question_one: yup.string().required('Question is required'),
    answer_one: yup.string().required('Answer is required'),
    question_two: yup
      .string()
      .required('Question is required')
      .notOneOf([yup.ref('question_one'), null], 'Questions must be different'),
    answer_two: yup.string().required('Answer is required'),
  });

  const [data, setData] = useState({
    password: '',
    question_one: '',
    answer_one: '',
    question_two: '',
    answer_two: '',
  });

  const methods = useForm({
    defaultValues: {
      password: data.password,
      question_one: data.question_one,
      answer_one: data.answer_one,
      question_two: data.question_two,
      answer_two: data.answer_one,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { control, register, handleSubmit, errors, reset } = methods;

  const fetchMyAPI = useCallback(async () => {
    try {
      const response = await getMemorableQuestions();

      if (response) {
        setQuestions(response);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    setMemorableQuestions(
      data.password,
      Number(data.question_one),
      data.answer_one,
      Number(data.question_two),
      data.answer_two
    )
      .then((res) => {
        toast.success(
          t('common:forms.memorable-questions.messages.toast-success')
        );

        setMessage({
          type: 'success',
          message: t('common:forms.memorable-questions.messages.success'),
        });

        setFormStatus(STATUS_SENT);

        if (onComplete) {
          onComplete();
        }

        // TODO: possibly clear the form message after x seconds
        // clear the form fields
        reset();
      })
      .catch((err) => {
        setFormStatus(STATUS_FAILED);

        toast.error(t('common:forms.memorable-questions.messages.error'));
      });
  };

  return (
    <Paper>
      <Box padding={4}>
        {!hideTitle && (
          <Typography variant="h4" component="h3">
            {t('common:forms.memorable-questions.title')}
          </Typography>
        )}

        <Box marginBottom={2}>
          <Alert severity="info">
            {t('common:forms.memorable-questions.messages.info')}
          </Alert>
        </Box>

        {message && (
          <Box marginBottom={2}>
            <Alert severity={message.type}>{message.message}</Alert>
          </Box>
        )}

        {(formStatus !== STATUS_SENT || persistForm) && (
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Password
                  ref={register}
                  id="password"
                  label="Current Password"
                  name="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                />

                <Select
                  ref={register}
                  control={control}
                  // @ts-ignore
                  id="question_one"
                  label="Question One"
                  name="question_one"
                  options={questions
                    .filter((item) => {
                      return item.id !== q2;
                    })
                    .map((item) => {
                      return {
                        displayText: item.displayText,
                        value: String(item.id),
                      };
                    })}
                  error={!!errors.question_one}
                  helperText={errors?.question_one?.message}
                />

                <Input
                  ref={register}
                  id="answer_one"
                  label="Answer One"
                  name="answer_one"
                  error={!!errors.answer_one}
                  helperText={errors?.answer_one?.message}
                />

                <Select
                  ref={register}
                  control={control}
                  // @ts-ignore
                  id="question_two"
                  label="Question Two"
                  name="question_two"
                  options={questions
                    .filter((item) => {
                      return item.id !== q1;
                    })
                    .map((item) => {
                      return {
                        displayText: item.displayText,
                        value: String(item.id),
                      };
                    })}
                  error={!!errors.question_two}
                  helperText={errors?.question_two?.message}
                />

                <Input
                  ref={register}
                  id="answer_two"
                  label="Answer Two"
                  name="answer_two"
                  error={!!errors.answer_two}
                  helperText={errors?.answer_two?.message}
                />

                <Box paddingY={2} textAlign={isPage ? 'right' : undefined}>
                  <Button
                    size="large"
                    disabled={formStatus === STATUS_SENDING}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {buttonLabel}
                  </Button>
                </Box>
              </Form>
            </FormProvider>
          </ValidationProvider>
        )}

        {formStatus === STATUS_SENT && isPage === false && (
          <Fragment>
            <Grid container spacing={1} justify="space-between">
              <Grid item sm={true}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/auth/login`}
                  onClick={
                    typeof onLoginClick === 'function'
                      ? onLoginClick
                      : undefined
                  }
                >
                  Login
                </Button>
              </Grid>
              {canRefresh && (
                <Grid item>
                  <IconButton
                    aria-label="reset"
                    onClick={() => {
                      reset();
                      setFormStatus('');
                      setMessage(null);
                    }}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Fragment>
        )}
      </Box>
    </Paper>
  );
};
