// TODO: comment
import { SuccessResponse } from 'api/types';
import { sendApiRequest } from '../utils';

export const forgottenPasswordVerify = async (
  /**
   *
   */
  username: string,
  /**
   *
   */
  questionId: number,
  /**
   *
   */
  answer: string
): Promise<SuccessResponse> => {
  const data: any = {
    username,
    questionId,
    answer,
  };

  const response = await sendApiRequest<SuccessResponse>(
    `${process.env.REACT_APP_API_URL}api/account/forgotten-password-verify`,
    null,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
