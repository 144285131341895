// TODO: comment
import { createMuiTheme } from '@material-ui/core/styles';
import { merge } from 'lodash';
import { baseTheme } from './globalTheme';
import defaultTheme from './theme';
import darkTheme from './theme-dark';

const a = merge(baseTheme, defaultTheme);

export const abrdnDefault = createMuiTheme(a);

const b = merge(baseTheme, darkTheme);

export const abrdnDark = createMuiTheme(b);

export * from './ThemeDark';
export * from './ThemeDefault';
