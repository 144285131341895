// TODO: comment
import { createDocument } from 'api';
import { UploadDocumentsData } from 'api/types';
import { toast } from 'material-react-toastify';

export const uploadDocuments = async (
  /**
   *
   */
  files: UploadDocumentsData[]
): Promise<string[]> => {
  const uploadedIds: string[] = [];

  if (files) {
    for (let counter = 0; counter < files.length; counter++) {
      const file = files[counter].file[0];
      const description = files[counter].description;

      if (file) {
        const filename = file.name;
        const mimeType = file.type;

        try {
          const resp: any = await createDocument(
            file,
            description,
            filename,
            mimeType
          );

          if (resp) {
            uploadedIds.push(resp.id);
          } else {
          }
        } catch (e) {}
      }
    }

    if (uploadedIds.length > 1) {
      toast.success(`Files uploaded`);
    }
  }

  return new Promise((resolve, reject) => {
    if (!files || uploadedIds.length === files.length) {
      resolve(uploadedIds);
    } else {
      reject('Document failed to upload');
    }
  });
};
