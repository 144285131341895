import SvgIcon from '@material-ui/core/SvgIcon';

export const ApplePodcastsIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        fill="#AA1DD3"
        d="M38.8,46.1c-0.1,4-0.5,8-1.8,11.9c-0.6,1.8-1.4,3.5-2.8,4.8c-1.7,1.5-3,1.6-4.7,0c-1.8-1.7-2.6-3.9-3.2-6.2
			c-1.2-4.4-1.5-9-1.4-13.5c0.1-3.8,3.3-6.8,7-6.9c3.6,0,6.7,2.8,7,6.5C38.9,43.8,38.8,45,38.8,46.1z"
      />
      <path
        fill="#AA1DD3"
        d="M24.7,27.1c0-4,3.3-7.2,7.4-7.2c3.6,0,6.8,3.4,6.8,7.1c0,3.9-3.2,7.1-7.1,7.2C27.9,34.2,24.8,31,24.7,27.1z"
      />
      <path
        fill="#AA1DD3"
        d="M60.9,25.9c-1.5-15-15-26.6-30.4-25.9C15.2,0.7,2.9,13.6,3,29c0,15.3,11.4,25.3,19.9,27.5
				c-0.3-1.3-0.5-2.6-0.7-3.9c-0.3-0.4-0.8-0.8-1.5-1.1c-9.6-4.6-15.2-15.6-13.6-26C9.5,11,22.8,1.8,37.2,4.6
				C50.5,7.2,59.5,21,56.5,34.2c-1.9,8.2-6.6,14.1-14.2,17.7c-0.3,0.1-0.7,0.2-0.9,0.4c-0.2,1.4-0.4,2.8-0.7,4.2
				C50.5,54.2,62.5,42.8,60.9,25.9z"
      />
      <path
        fill="#AA1DD3"
        d="M50.3,24.9C48.1,16,40.1,10,30.6,10.3c-8.6,0.3-16.2,7-17.8,15.6C11.1,35,16.6,43,21.6,45.3
				c-0.1-1.6-0.1-3.2-0.1-4.8c-0.1-0.1-0.2-0.2-0.3-0.3c-2.9-2.8-4.3-6.2-4.6-10.2c-0.7-9.6,8.7-17.7,18.1-15.6
				c5.8,1.3,9.8,4.8,11.5,10.6c1.7,5.9,0.2,11-4.1,15.3c0,0-0.1,0.1-0.1,0.1c0,1.3,0,2.6,0,3.9c0,0.4,0,0.7,0,1.1
				C47.7,42.2,52.4,33.8,50.3,24.9z"
      />
    </SvgIcon>
  );
};
