// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSentMessages as api_getSentMessages } from 'api';
import { getMessagesPerPage } from 'api/constants';
import { MessageListStateProps, MessagesRequest } from './types';
export const getSentMessages = createAsyncThunk(
  'message/get-sent',
  async ({ start = 0, max = getMessagesPerPage() }: MessagesRequest) => {
    const messages = await api_getSentMessages(start, max);

    return messages;
  }
);

const initialState: MessageListStateProps = {
  status: '',
  items: {
    pagination: {},
    messages: [],
  },
};

const messageSlice = createSlice({
  name: 'messages-sent',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSentMessages.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getSentMessages.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getSentMessages.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });
  },
});

export const selectSentMessages = (state: any) => {
  return state['messages-sent'].items;
};

export const selectSentStatus = (state: any): string => {
  return String(state['messages-sent'].status);
};

export default messageSlice.reducer;
