// cookie tracking name
const COOKIE_NAME: string = 'OptanonConsent';

function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const getOneTrustCookies = (): string[] => {
    const oneTrust = getCookie(COOKIE_NAME);

    if (oneTrust === '') {
        return [];
    }

  const obj = JSON.parse(
    '{"' + oneTrust.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );

  return obj && obj.groups
    ? obj.groups.split(',').map((group: string) => {
        return group;
      })
    : [];
};

export const canSetEssentialCookies = (): boolean => {
  return true;
};

export const canSetPerformanceCookies = (): boolean => {
  // C0002
  return getOneTrustCookies().indexOf('C0002:1') > -1;
};

export const canSetFunctionalCookies = (): boolean => {
  // C0003
  return getOneTrustCookies().indexOf('C0003:1') > -1;
};

export const canSetTargetingCookies = (): boolean => {
  // C0004
  return getOneTrustCookies().indexOf('C0004:1') > -1;
};
