// TODO: comment
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { CloseIcon, KeyboardArrowUpIcon, MinimizeIcon } from 'icons';
import React, { Fragment, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ResizeObserver from 'resize-observer-polyfill';
import { ThemeDark } from 'styles';
import './PopupApp.scss';

interface Props {
  /**
   *
   */
  children: React.ReactNode;
  /**
   *
   */
  disableScreen?: boolean;
  /**
   *
   */
  maxHeight?: number;
  /**
   *
   */
  onClose?: any;
  /**
   *
   */
  onStateChange?(state: PopupState): void;
  /**
   *
   */
  showUIClose?: boolean;
  /**
   *
   */
  showUIMinimise?: boolean;
  /**
   *
   */
  state: PopupState;
  /**
   *
   */
  title: string;
}

export type PopupState = 'open' | 'closed' | 'minimised';

export const PopupApp = ({
  state,
  title,
  children,
  showUIClose = true,
  showUIMinimise = true,
  onClose,
  onStateChange,
  disableScreen = false,
  maxHeight = 500,
}: //state,
Props) => {
  const [appState, setAppState] = useState<PopupState>(state);

  /**
   * handle closing of the modal
   */
  const handleClose = () => {
    setAppState('closed');
    //setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const scrollbar = React.useRef(null);

  const resizeObserver = React.useRef<ResizeObserver>(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      // your code to handle the size change

      if (scrollbar.current) {
        // @ts-ignore
        if (scrollbar.current.getScrollTop() === 0) {
          // @ts-ignore
          scrollbar.current.scrollTop(1);
        }
      }
    })
  );

  const resizedContainerRef = React.useCallback(
    (container: HTMLDivElement) => {
      if (container !== null) {
        resizeObserver.current.observe(container);
      }
      // When element is unmounted, ref callback is called with a null argument
      // => best time to cleanup the observer
      else {
        if (resizeObserver.current) resizeObserver.current.disconnect();
      }
    },
    [resizeObserver.current]
  );

  // watch for the modals state/props changing
  useEffect(() => {
    setAppState(state);
  }, [state]);

  useEffect(() => {
    if (onStateChange) {
      onStateChange(appState);
    }
  }, [appState]);

  if (state === 'closed') {
    // return null;
  }

  return (
    <Modal
      className={`popupapp popupapp--${appState}`}
      open={state !== 'closed'}
      onClose={handleClose}
      disableEnforceFocus
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        invisible: !disableScreen,
      }}
      disableBackdropClick={false}
      hideBackdrop={!disableScreen}
      keepMounted={true}
      disableScrollLock={!disableScreen}
    >
      <Slide
        direction="up"
        in={state !== 'closed'}
        onExited={() => {
          onClose();
        }}
      >
        <Paper elevation={3} className={`popupapp__inner ${appState}`}>
          <ThemeDark>
            <Paper className="popupapp__bar">
              <button
                className="popupapp__title"
                onClick={() => {
                  if (showUIMinimise) {
                    const state =
                      appState === 'minimised' ? 'open' : 'minimised';

                    setAppState(state);
                  }
                }}
              >
                <span>{title}</span>
                {showUIMinimise && (
                  <Fragment>
                    {appState === 'minimised' ? (
                      <KeyboardArrowUpIcon fontSize="small" />
                    ) : (
                      <MinimizeIcon fontSize="small" />
                    )}
                  </Fragment>
                )}
              </button>

              {showUIClose && (
                <div className="popupapp__actions">
                  <IconButton size="small" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
            </Paper>
          </ThemeDark>
          <Paper className="popupapp__main">
            <Scrollbars
              ref={scrollbar}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={maxHeight}
              autoHide={false}
              thumbMinSize={30}
            >
              <Box padding={4}>
                <div ref={resizedContainerRef}>{children}</div>
              </Box>
            </Scrollbars>
          </Paper>
        </Paper>
      </Slide>
    </Modal>
  );
};
