// TODO: comment
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { forwardRef, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  exact?: boolean;
  icon?: any;
  iconEnd?: any;
  isTopLevel?: boolean;
  onClick?: any;
  primary: string;
  to?: string;
}

export const ListItemLink = ({
  icon,
  iconEnd,
  primary,
  to = '',
  exact,
  isTopLevel = false,
  onClick,
}: Props) => {
  const isActive = isTopLevel
    ? (match: any, location: any) => {
        const locationBase = location.pathname.replace(/^\//, '').split('/')[0];

        const toBase = to.replace(/^\//, '').split('/')[0];

        return locationBase === toBase;
      }
    : undefined;

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => {
        return (
          <NavLink
            to={to}
            exact={exact}
            {...itemProps}
            onClick={onClick}
            isActive={isActive}
          />
        );
      }),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {iconEnd ? <ListItemIcon>{iconEnd}</ListItemIcon> : null}
      </ListItem>
    </li>
  );
};
