// TODO: comment
// @ts-nocheck
// https://github.com/satansdeer/completed/tree/master/src

import React from 'react';

export const Form = ({ children, ...rest }: any) => {
  return (
    <form {...rest} noValidate>
      {children}
    </form>
  );
};
