// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActionRequiredMessages as api_getActionRequiredMessages } from 'api';
import { getMessagesPerPage } from 'api/constants';
import { MessageListStateProps, MessagesRequest } from './types';
export const getActionRequiredMessages = createAsyncThunk(
  'message/action-required',
  async ({ start = 0, max = getMessagesPerPage() }: MessagesRequest) => {
    const messages = await api_getActionRequiredMessages(start, max);

    return messages;
  }
);

const initialState: MessageListStateProps = {
  status: '',
  items: {
    pagination: {},
    messages: [],
  },
};

const messageSlice = createSlice({
  name: 'messages-action-required',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActionRequiredMessages.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getActionRequiredMessages.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getActionRequiredMessages.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });
  },
});

export const selectActionRequiredMessages = (state: any) => {
  return state['messages-action-required'].items;
};

export const selectActionRequiredStatus = (state: any): string => {
  return String(state['messages-action-required'].status);
};

export default messageSlice.reducer;
