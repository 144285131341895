// TODO: comment
import { configureStore } from '@reduxjs/toolkit';
import { partyReducer, userReducer } from 'redux/reducers/account';
// import { configReducer } from 'redux/reducers/config';
import {
  documentDeletedReducer,
  documentReducer,
  recentDocuments,
} from 'redux/reducers/documents';
import {
  DELETED_MESSSAGES_NAME,
  messageActionRequiredReducer,
  messageDeletedReducer,
  messageReceivedReducer,
  messageReducer,
  messageSentReducer,
} from 'redux/reducers/messages';
//import { holdingsReducer } from 'redux/reducers/portfolio-holdings';

const reducer = {
  // config: configReducer,
  // holdings: holdingsReducer,
  user: userReducer,
  party: partyReducer,
  messages: messageReducer,
  documents: documentReducer,
  'messages-received': messageReceivedReducer,
  'messages-sent': messageSentReducer,
  'messages-action-required': messageActionRequiredReducer,
  [DELETED_MESSSAGES_NAME]: messageDeletedReducer,
  'documents-deleted': documentDeletedReducer,
  'recent-documents': recentDocuments,
};

export default configureStore({
  // @ts-ignore
  reducer,
  devTools: true,
});
