import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PdfIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d="M8.40059 18.1996H7.60059V13.1996L9.00059 13.0996C9.20059 13.0996 9.40059 13.0996 9.60059 13.1996C9.80059 13.2996 9.90059 13.3996 10.1006 13.4996C10.2006 13.5996 10.3006 13.7996 10.4006 13.9996C10.5006 14.1996 10.5006 14.3996 10.5006 14.6996C10.5006 14.9996 10.5006 15.1996 10.4006 15.3996C10.3006 15.5996 10.2006 15.7996 10.1006 15.8996C10.0006 15.9996 9.80059 16.0996 9.60059 16.1996C9.40059 16.2996 9.20059 16.2996 9.00059 16.2996H8.40059V18.1996ZM8.40059 13.9996V15.2996H9.00059C9.20059 15.2996 9.30059 15.1996 9.50059 15.0996C9.60059 14.9996 9.70059 14.7996 9.70059 14.5996C9.70059 14.3996 9.60059 14.1996 9.50059 14.0996C9.30059 13.9996 9.20059 13.8996 9.00059 13.8996L8.40059 13.9996Z" fill="black"/>
    <path d="M12.5008 18.3999H12.4008L10.8008 18.2999V12.9999L12.4008 12.8999C12.7008 12.8999 13.1008 12.8999 13.4008 13.0999C13.7008 13.1999 14.0008 13.3999 14.2008 13.6999C14.4008 13.8999 14.6008 14.1999 14.8008 14.5999C14.9008 14.8999 15.0008 15.2999 15.0008 15.6999C15.0008 16.0999 14.9008 16.4999 14.8008 16.7999C14.7008 17.0999 14.5008 17.3999 14.2008 17.6999C14.0008 17.8999 13.7008 18.0999 13.4008 18.2999C13.1008 18.2999 12.8008 18.3999 12.5008 18.3999ZM11.7008 17.3999H12.4008C12.6008 17.3999 12.8008 17.3999 13.0008 17.2999C13.2008 17.1999 13.4008 17.0999 13.5008 16.8999C13.6008 16.6999 13.8008 16.4999 13.8008 16.2999C13.9008 16.0999 13.9008 15.7999 13.9008 15.5999C13.9008 15.3999 13.9008 15.0999 13.8008 14.8999C13.7008 14.6999 13.6008 14.3999 13.5008 14.2999C13.0008 13.8999 12.8008 13.7999 13.0008 13.8999C13.2008 13.9999 12.6008 13.7999 12.4008 13.7999H11.7008V17.3999Z" fill="black"/>
    <path d="M16.7002 18.5997H15.7002V12.8997L19.0002 12.6997V13.6997L16.7002 13.7997V15.1997H18.9002V16.1997H16.7002V18.5997Z" fill="black"/>
    <path d="M26.8004 9.4999L24.5004 6.8999C24.3004 6.6999 24.1004 6.5999 23.8004 6.5999H23.7004H21.6004C21.6004 5.6999 21.0004 4.8999 20.1004 4.8999L6.30039 5.9999C5.50039 5.9999 4.90039 6.6999 4.90039 7.6999V24.2999C4.90039 25.1999 5.50039 25.9999 6.20039 25.9999L20.2004 26.9999C20.9004 26.9999 21.5004 26.3999 21.6004 25.6999H26.0004C26.6004 25.6999 27.0004 25.2999 27.0004 24.6999V9.9999C27.0004 9.7999 26.9004 9.5999 26.8004 9.4999ZM19.7004 24.9999L6.90039 23.9999V7.9999L19.7004 6.9999V24.9999ZM21.7004 23.6999V8.5999H23.1004V9.9999C23.1004 10.1999 23.2004 10.3999 23.3004 10.4999C23.4004 10.5999 23.7004 10.6999 23.9004 10.6999H25.0004V23.6999H21.7004Z" fill="black"/>
  </SvgIcon>
);
