// TODO: comment
import { Box, Button, Popover, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InfoOutlinedIcon } from 'icons';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: { fontSize: 13, padding: theme.spacing(2) },
    button: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      borderRadius: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
      },
    },
    popover: {
      maxWidth: 400,
    },
  })
);

interface Props {
  content: string;
  title: string;
}

export const InfoPopper = ({ title, content }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        className={classes.button}
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<InfoOutlinedIcon />}
      >
        {title}
      </Button>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box minWidth={240}>
          <Typography className={classes.typography}>{content}</Typography>
        </Box>
      </Popover>
    </div>
  );
};
