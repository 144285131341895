export const LightBulbIcon = (props: any) => {
  return (
    <svg {...props} width="48" height="48" viewBox="0 0 48 48">
      <path d="M29.3101 31.9049C30.2851 31.5599 30.9301 30.6449 30.9301 29.6249V27.8249C30.9151 27.0899 31.2301 26.3999 31.7851 25.9349C34.8601 23.4599 36.6301 19.7249 36.6001 15.7649C36.4801 8.90988 31.1101 3.29988 24.2551 2.89488C24.0451 2.87988 23.8501 2.87988 23.6401 2.87988L23.0251 2.89488C16.1701 3.29988 10.8001 8.90988 10.6801 15.7649C10.6501 19.7099 12.4201 23.4599 15.4951 25.9349C16.0501 26.3999 16.3651 27.1049 16.3501 27.8249V29.6249C16.3501 30.6449 17.0101 31.5599 17.9701 31.9049" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" fillOpacity={0} />
      <path d="M19.6802 42.0298C19.8302 42.9448 20.2952 43.7698 20.9852 44.3848C22.4702 45.7048 24.8552 45.7348 26.3102 44.3848C27.0002 43.7698 27.4652 42.9448 27.6152 42.0298" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" fillOpacity={0} />
      <path d="M18.2549 35.2051H29.3549" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M19.5303 38.6851H27.8553" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M26.8502 8.50488L21.2852 17.1599" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M28.1552 17.1597L21.6602 26.7447" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M21.3149 17.1299H27.8549" stroke="#72246C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
  );
}
