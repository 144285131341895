// TODO: comment
import { AbrdnBox } from '@abrdn';
import { useWidth } from '@abrdn-latest/use';
import { Box, Grid, Typography } from '@material-ui/core';
import { Anchor } from 'components/core';
import {
  CommunicateSecurelyIcon,
  DocumentLibraryIcon,
  ViewPortfolioIcon,
} from 'icons';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Quick = () => {
  const content = {
    title: `Getting you started...`,
    items: [
      {
        title: 'Explore our Funds',
        copy: 'View the portfolio holdings of our publicly available funds',
        icon: <ViewPortfolioIcon style={{ fontSize: 64 }} />,
        url: '/portfolio-holdings',
        cta: null,
      },
      {
        title: 'Send us a Message',
        copy: 'Connect with your abrdn service team via your secure mailbox',
        icon: <CommunicateSecurelyIcon style={{ fontSize: 64 }} />,
        url: '/messages/inbox',
        cta: null,
      },
      {
        title: 'Document Library',
        copy: 'Receive your regular reporting content and exchange documents with us',
        icon: <DocumentLibraryIcon style={{ fontSize: 64 }} />,
        url: '/documents',
        cta: null,
      },
    ],
  };

  let instance: any;

  //
  const { isMobile } = useWidth();

  return (
    <Box>
      <Typography variant="h3">{content.title}</Typography>

      {isMobile ? (
        <Swiper
          pagination={true}
          onSwiper={(swiper) => {
            if (!instance) {
              instance = swiper;
            }
          }}
          spaceBetween={10}
          slidesPerView={1}
          allowTouchMove
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            800: {
              slidesPerView: 3,
            },
          }}
        >
          {content.items.map(
            ({ title, copy, icon = null, cta = null, url }, index: number) => {
              return (
                <SwiperSlide key={`slide-${index}`}>
                  <AbrdnBox elevation={1} className="quicklink-box">
                    {icon}
                    <Typography variant="h5">
                      {url ? <Anchor to={url}>{title}</Anchor> : title}
                    </Typography>
                    <Typography paragraph={!!cta}>{copy}</Typography>
                    {cta}
                  </AbrdnBox>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      ) : (
        <Grid container spacing={3} direction="row" alignItems="center">
          {content.items.map(
            ({ title, copy, icon = null, cta = null, url }, index: number) => {
              return (
                <Grid item xs={6} md={3} key={`i-${index}`}>
                  <AbrdnBox elevation={1} className="quicklink-box">
                    {icon}
                    <Typography
                      variant="h5"
                      style={{ minHeight: !!icon ? undefined : 40 }}
                    >
                      {url ? <Anchor to={url}>{title}</Anchor> : title}
                    </Typography>
                    <Box minHeight={75}>
                      <Typography paragraph={!!cta}>{copy}</Typography>
                    </Box>
                    {cta}
                  </AbrdnBox>
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </Box>
  );
};
