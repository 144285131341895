// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';
import { FundSummaryResponse } from './types';

/**
 *
 */
export const getFundSummary = async (
  /**
   * The fund code.
   */
  fundCode: string,
  /**
   * The valuation date. Optional. Defaults to latest available.
   */
  valuationDate?: Date | null | undefined,
  /**
   * Size of the instrument asset class. Optional.
   */
  instrumentAssetClassSize?: number | null | undefined,
  /**
   * Size of the country of issue. Optional.
   */
  countryOfIssueSize?: number | null | undefined
): Promise<FundSummaryResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/portfolio-holdings/funds/${fundCode}/summary`;

  const data: any = {};

  if (valuationDate) {
    data.valuationDate = (() => {
      const selectedDate = new Date(
        valuationDate.getFullYear(),
        valuationDate.getMonth() + 1,
        0
      );

      return dayjs(selectedDate).format('YYYY-MM-DD');
    })();
  }

  if (instrumentAssetClassSize) {
    data.instrumentAssetClassSize = instrumentAssetClassSize;
  }

  if (countryOfIssueSize) {
    data.countryOfIssueSize = countryOfIssueSize;
  }

  try {
    const response = await sendApiRequest<FundSummaryResponse>(endpoint, data);

    return response;
  } catch (e) {
    return {
      fundObjective: null,
      instrumentAssetClassSummary: [],
      countryOfIssueSummary: [],
    };
  }
};
