// TODO: comment
import { getStoredFavourites } from '.';

/**
 *
 */
export const getSavedFundList = async (): Promise<string[]> => {
  const data = await getStoredFavourites();

  return new Promise((resolve, reject) => {
    resolve(data);
  });
};
