// TODO: comment
import { Box } from '@material-ui/core';
import { ErrorOutlineOutlinedIcon } from 'icons';
import React from 'react';
import './NoData.scss';

interface NoDataProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

export const NoData = ({ children, icon }: NoDataProps) => {
  const Icon = icon || ErrorOutlineOutlinedIcon;

  return (
    <Box className="nodata" padding={4} textAlign="center">
      {/* @ts-ignore */}
      <Icon style={{ fontSize: 50 }} />
      {children ? children : <div>No Data</div>}
    </Box>
  );
};
