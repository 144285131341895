// TODO: comment
import { useRouter } from '@abrdn-latest/use';
import { Box, Paper, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert } from '@material-ui/lab';
import { Confirmation } from 'components/confirmation';
import { canSetEssentialCookies } from 'components/cookies';
import React, { Fragment, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MemorableQuestionsForm, useSecurity } from './';
import { ChangePasswordForm } from './ChangePasswordForm';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const COOKIE_NAME: string = 'authActioned';

interface Props {}

let instance: any;

export const SecurityModal = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  const { authState, authMethods } = useSecurity();

  const [showPopup, setShowPopup] = useState(authState.authMessage !== null);

  const router = useRouter();

  /**
   * handle closing of the modal
   */
  const handleClose = () => {
    setOpen(false);
  };

  // watch for the modals state/props changing

  const handleMemorableQuestionsSuccess = () => {
    if (authState.screens.passwordResetRequired) {
      if (instance) {
        instance.slideTo(1);
      }
    } else {
      handleClose();
    }

    authMethods.setScreens({
      passwordResetRequired: authState.screens.passwordResetRequired,
      memorableQuestionsResetRequired: false,
    });
  };

  const handleChangePasswordSuccess = () => {
    //
    handleClose();

    setShowPopup(false);

    authMethods.setScreens({
      passwordResetRequired: false,
      memorableQuestionsResetRequired:
        authState.screens.memorableQuestionsResetRequired,
    });
  };

  useEffect(() => {
    if (
      authState.screens.passwordResetRequired ||
      authState.screens.memorableQuestionsResetRequired
    ) {
      if (!authState.isFullyAuthenticated) {
        return;
      }

      let page = 0;

      if (authState.screens.memorableQuestionsResetRequired) {
        page = 0;
      }

      if (authState.screens.passwordResetRequired) {
        page = 1;
      }

      if (open !== true) {
        setOpen(true);
      }

      setTimeout(() => {
        if (instance) {
          instance.slideTo(page, 0);
        }
      }, 0);
    } else {
      if (open) {
        setOpen(false);
      }
    }
  }, [authState.screens, authState.isFullyAuthenticated]);

  if (
    showPopup &&
    !authState.screens.memorableQuestionsResetRequired &&
    !authState.screens.passwordResetRequired
  ) {
    if (cookies[COOKIE_NAME]) {
      return null;
    }

    return (
      <Confirmation
        rejectText="Dismiss"
        confirmText="Take action"
        onConfirm={() => {
          router.history.push('/account/settings');
        }}
        onReject={() => {
          // set a cookie
          if (canSetEssentialCookies()) {
            setCookie(COOKIE_NAME, 'on', { path: '/' });
          }
        }}
        isOpen
      >
        <Box textAlign="center">
          <Typography paragraph>{authState.authMessage}</Typography>
        </Box>
      </Confirmation>
    );
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        disableBackdropClick
        disableEscapeKeyDown
        keepMounted
      >
        <Paper>
          <Box padding={2}>
            {authState.authMessage !== null && authState.isFullyAuthenticated && (
              <Fragment>
                <Box paddingX={4} paddingTop={3} paddingBottom={1}>
                  <Alert severity="info">{authState.authMessage}</Alert>
                </Box>
              </Fragment>
            )}

            <Swiper
              onSwiper={(swiper) => {
                if (!instance) {
                  instance = swiper;
                }
              }}
              autoHeight
              spaceBetween={24}
              slidesPerView={1}
              allowTouchMove={false}
            >
              {/* page 0 */}
              <SwiperSlide>
                {authState.screens.memorableQuestionsResetRequired && (
                  <MemorableQuestionsForm
                    onComplete={handleMemorableQuestionsSuccess}
                    canRefresh={false}
                    persistForm
                  />
                )}
              </SwiperSlide>
              {/* page 1 */}
              <SwiperSlide>
                {authState.screens.passwordResetRequired && (
                  <ChangePasswordForm
                    onComplete={handleChangePasswordSuccess}
                  />
                )}
              </SwiperSlide>
            </Swiper>
          </Box>
        </Paper>
      </Dialog>
    </Fragment>
  );
};
