// TODO: comment
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Popover,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { HoldingFilters } from 'api/types';
import { ExpandMoreIcon } from 'icons';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeDefault } from 'styles';
import { CheckedState } from 'types';
import './Filter.scss';

interface Props {
  displayAsAccordion?: boolean;
  filters: HoldingFilters;
  label: string;
  onChange(filters: string[]): void;
  selected: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: 12,
      // @ts-ignore
      fontWeight: theme.typography.fontWeightRegular,
    },
    button: {
      borderRadius: 2,
      fontSize: 12,
    },
  })
);

export const Filter = ({
  label,
  filters,
  selected = [],
  displayAsAccordion = false,
  onChange,
}: Props) => {
  const classes = useStyles();

  const [state, setState] = useState<CheckedState>({});

  const objectToArray = (o: CheckedState) => {
    // convert the data to an array
    const identifiers = Object.keys(o);
    // filter out the inactive filters
    return identifiers.filter((id) => o[id]);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // track the state of all filters
    const combined = { ...state, [ev.target.name]: ev.target.checked };

    // update the state
    setState(combined);

    // trigger the change event, so we can re-search
    onChange(objectToArray(combined));
  };

  useEffect(() => {
    const newState: CheckedState = {};

    selected.forEach((value) => {
      newState[value] = true;
    });

    setState(newState);
  }, [selected]);

  if (!filters) {
    return null;
  }

  if (displayAsAccordion) {
    if (!filters || filters.length === 0) {
      return null;
    }

    return (
      <>
        <Accordion defaultExpanded={selected.length > 0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{label}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormGroup>
                {filters.map((item: string, index: number) => {
                  const isChecked: boolean = state[item] === true;

                  return (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          color="primary"
                          checked={isChecked}
                          onChange={handleChange}
                          name={item}
                          value={item}
                        />
                      }
                      label={item}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div className="filter">
          <Button
            className={classes.button}
            size="large"
            variant="outlined"
            {...bindTrigger(popupState)}
            endIcon={<ExpandMoreIcon />}
            disabled={!filters || filters.length === 0}
          >
            {label}
          </Button>
          <ThemeDefault>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Scrollbars autoHeight autoHeightMax={400}>
                <Box p={2}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {filters.map((item: string) => {
                        const isChecked: boolean = state[item] === true;

                        return (
                          <FormControlLabel
                            key={item}
                            control={
                              <Checkbox
                                color="primary"
                                checked={isChecked}
                                onChange={handleChange}
                                name={item}
                                value={item}
                              />
                            }
                            label={item}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </Box>
              </Scrollbars>
            </Popover>
          </ThemeDefault>
        </div>
      )}
    </PopupState>
  );
};
