import { Box, Container, Grid, List, ListItem, Paper, Typography } from '@material-ui/core';

import { EmbeddedVideo } from '@abrdn-latest/core';
import { ExternalAnchor } from 'components/core';
import { Fragment } from 'react';
import { GREY_LIGHT } from '@abrdn-latest/config';
import { PictureAsPdfIcon } from 'icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  border: {
    border: '1px solid #D9D9D6',
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    textDecoration: 'none !important',
    marginLeft: 20,
    paddingLeft: 10,
  },
}));

export const ClimateInsights = () => {
  const classes = useStyles();

  const documents = [
    {
      title: 'ESG - Our House Scores',
      url: 'https://www.abrdn.com/docs?editionId=f5a42b66-a61e-4359-a4bc-23dfd7092c01',
    },
  ];

  return (
    <Paper style={{ backgroundColor: GREY_LIGHT }}>
      <Container>
        <Box paddingY={8}>
          <Typography variant="h4">Sustainable Investing</Typography>
          <Grid container spacing={3} wrap="wrap">
            <Grid item xs={12} md={6}>
              <Typography paragraph>
                ESG considerations are a critical part of our decision-making
                process across asset classes. This happens at all stages from
                research through to investment decision; as well as continued
                active ownership throughout. In addition, we offer clients
                looking for more focused sustainability investments a range of
                solutions.
              </Typography>
              <Typography paragraph>
                We have been evolving our approach to Sustainable investing
                since 1992, with our first ethical fund launched in 1994.
                Clients can be assured in our sustainable investing approach,
                which offers:
              </Typography>
              <List>
                <ListItem className={classes.listItem}>
                  Embedded ESG expertise – our global Sustainability Group works closely with ESG experts located throughout different regions, asset classes and business functions; to offer comprehensive insight.
                </ListItem>
                <ListItem className={classes.listItem}>
                  Proprietary process, research and tools offer investment critical insight - with added rigour, based on our own set of proprietary toolkit; complementing a broad range of third-party sources.
                </ListItem>
                <ListItem className={classes.listItem}>
                  An active ownership approach - which seeks to enhance and protect the value of portfolios by actively engaging with companies we invest in and voting on policies to encourage progress in sustainability.
                </ListItem>
              </List>



              {documents && (
                <Fragment>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ marginTop: 40 }}
                  >
                    Key documents
                  </Typography>
                  {documents.map(({ title, url }, index: number) => (
                    <Box key={`doc-${index}`} marginY={3}>
                      <Paper className={classes.border}>
                        <Box padding={3}>
                          <Grid
                            container
                            spacing={3}
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item xs={10}>
                              <Typography variant="h5" style={{ margin: 0 }}>
                                <ExternalAnchor
                                  to={url}
                                  label={title}
                                  icon={false}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Box textAlign="right">
                                <PictureAsPdfIcon />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <EmbeddedVideo
                title="Climate Change: Hydrogen"
                src="https://www.youtube.com/embed/6nTN3Ed1bMQ"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};
