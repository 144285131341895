import React from 'react';
import Select, { components, OptionProps, SingleValueProps, ValueType } from 'react-select';
import AttachmentIcon from '@material-ui/icons/Attachment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ExcelIcon, PdfIcon } from 'icons';
import { Box } from '@material-ui/core';

const { Option, DropdownIndicator, SingleValue } = components;

const options = [
  { value: 'pdf', label: 'PDF', icon: <PdfIcon /> },
  { value: 'xlsx', label: 'XLSX', icon: <ExcelIcon /> },
];

type OptionType = (typeof options)[0];

const FOCUSED_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.04)';
const SELECTED_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.16)';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '230px',
    borderRadius: '3px',
    border: '1px solid #d9d9d6',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#d9d9d6',
      cursor: 'pointer',
    },
    '&:focus': {
      borderColor: '#d9d9d6',
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    '& > div': {
      padding: '2px 0',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#000',
    '&:hover': { color: '#000' },
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '4px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '5px 8px',
    cursor: 'pointer',
    color: '#000',
    background: state.isSelected ? SELECTED_BACKGROUND_COLOR :
                  state.isFocused ? FOCUSED_BACKGROUND_COLOR : undefined,
    '&:hover': {
      background: state.isSelected ? SELECTED_BACKGROUND_COLOR : FOCUSED_BACKGROUND_COLOR,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  }),
};

const customComponents = {
  Placeholder: ExtendedPlaceholder,
  SingleValue: ExtendedSingleValue,
  Option: ExtendedOption,
  DropdownIndicator: ExtendedDropdownIndicator,
  IndicatorSeparator: null,
};

function ExtendedOption(props: OptionProps<OptionType, false>) {
  return (
    <Option {...props}>
      {props.data.icon}
      <span style={{ marginLeft: 8 }}>{props.data.label}</span>
    </Option>
  );
}

function ExtendedDropdownIndicator(props: any) {
  return (
    <DropdownIndicator {...props}>
      <div style={{
        transition: 'transform 0.3s ease',
        transform: props.selectProps.menuIsOpen
          ? 'translate(0, 10%) rotate(0deg)'
          : 'translate(0, -5%) rotate(180deg)',
      }}>
        <KeyboardArrowUpIcon />
      </div>
    </DropdownIndicator>
  );
}

function ExtendedPlaceholder() {
  return (
    <Box display="flex" alignItems="center">
      <AttachmentIcon style={{ marginRight: 8 }} />
      <span>Download</span>
    </Box>
  );
}

function ExtendedSingleValue(props: SingleValueProps<OptionType>) {
  return (
    <SingleValue {...props}>
      {props.data.icon}
      <span style={{ paddingLeft: 8 }}>{props.children}</span>
    </SingleValue>
  );
}

type Props = { onChange?(v: ValueType<OptionType, false>): void };

export function FileTypeSelect({ onChange }: Props) {
  return (
    <Select
      options={options}
      styles={customStyles}
      components={customComponents}
      isSearchable={false}
      onChange={onChange}
    />
  );
}
