// TODO: comment
import {
  STATUS_FAILED,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { login, userStatus } from 'api';
import { OneTimePasswordForm, useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { ValidationProvider } from 'components/file-selector';
import { Form, Input, Password } from 'components/form';
import { Progress } from 'components/ui';
import { FormMessage } from 'interfaces';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AuthProps, ClickEvent } from './';

interface Props extends AuthProps {
  onRegisterClick?: boolean | ClickEvent;
  onResetClick?: boolean | ClickEvent;
  registered?: boolean;
}

export const LoginForm = (props: Props) => {
  const {
    hideTitle,
    onRegisterClick = true,
    onResetClick = true,
    onComplete,
    registered,
  } = props;

  const { t } = useTranslation(['common']);

  const { authState, authMethods } = useSecurity();

  // router
  const router = useRouter();

  const TEMP_PASSWORD_MESSAGE: FormMessage = {
    type: 'info',
    message: 'We have sent a temporary password to your registered email',
  };

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  useEffect(() => {
    if (registered) {
      setMessage(TEMP_PASSWORD_MESSAGE);
    }
  }, [registered]);

  //
  const [formStatus, setFormStatus] = useState('');

  // standard login
  const loginSchema = yup.object().shape({
    login_username: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    login_password: yup.string().required('Password is required'),
  });

  const [data, setData] = useState({
    login_username: process.env.REACT_APP_USERNAME || '',
    login_password: process.env.REACT_APP_PASSWORD || '',
  });

  const methods = useForm({
    defaultValues: {
      login_username: data.login_username,
      login_password: data.login_password,
    },
    mode: 'onChange',
    resolver: yupResolver(loginSchema),
  });

  const { register, handleSubmit, errors } = methods;

  const onLoginSubmit = async (data: any) => {
    // store the data
    setData(data);

    // set the form status
    setFormStatus(STATUS_SENDING);

    try {
      // try and log the user in
      const res = await login(data.login_username, data.login_password);

      if (res) {
        await userStatus();

        // NOTE: this may not be necessary, and the form is always redirected, or hidden in some way
        // set the form message
        setMessage({
          type: 'success',
          message: t('common:forms.login.messages.success'),
        });

        // update the form status
        setFormStatus(STATUS_SENT);

        // TODO: this may only need to be called once the user has completed the mfa flow
        // handle any complete methods
        if (onComplete) {
          onComplete();
        }

        // calll the onLogin procedure
        authMethods.onLogin(res);

        // TODO: possible move this to useEffect
        if (!res.oneTimePasswordRequired) {
          toast.success(t('common:forms.login.messages.toast-success'));
        }
      }
    } catch (e: any) {
      // fail the form
      setFormStatus(STATUS_FAILED);

      // update with an error message
      setMessage({
        type: 'error',
        message: t('common:forms.login.messages.error'),
      });
    }
  };

  // NOTE: this may no longer be needed as we're no longer using the slider to show the auth forms
  // when something changes, trigger the resize event.
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  useEffect(() => {
    // if the user is fully authenticated, this is either through a combination of completion of
    // a one time password (if enabled) or a succesful login
    if (
      authState.isFullyAuthenticated &&
      router.location.pathname === '/auth/login'
    ) {
      // redirect the user to the homepage
      router.history.replace('/');
    }
  }, [authState.isFullyAuthenticated]);

  // watch for screen changes
  useEffect(() => {}, [authState.screens]);

  return (
    <Paper>
      <Box padding={4}>
        {!hideTitle && !authState.isAuthenticated && (
          <Typography variant="h4" component="h3">
            {t('common:forms.login.title')}
          </Typography>
        )}

        {message && (
          <Box marginBottom={2}>
            <Alert severity={message.type}>{message.message}</Alert>
          </Box>
        )}

        {/* Standard login */}

        {!authState.isAuthenticated && (
          <Fragment>
            {formStatus !== STATUS_SENT && (
              <ValidationProvider schema={loginSchema}>
                <FormProvider {...methods}>
                  <Form onSubmit={handleSubmit(onLoginSubmit)}>
                    <Input
                      ref={register}
                      id="login_username"
                      type="email"
                      label="Email"
                      name="login_username"
                      error={!!errors.login_username}
                      helperText={errors?.login_username?.message}
                    />
                    <Password
                      ref={register}
                      id="login_password"
                      label="Password"
                      name="login_password"
                      error={!!errors.login_password}
                      helperText={errors?.login_password?.message}
                    />
                    <Box paddingY={2}>
                      <Button
                        size="large"
                        disabled={formStatus === STATUS_SENDING}
                        variant="contained"
                        type="submit"
                        color="primary"
                      >
                        Login {formStatus === STATUS_SENDING && <Progress />}
                      </Button>
                    </Box>
                  </Form>
                </FormProvider>
              </ValidationProvider>
            )}

            {formStatus !== STATUS_SENT && (onRegisterClick || onResetClick) && (
              <Grid container spacing={2} justify="space-between">
                {onRegisterClick && (
                  <Grid item>
                    <Typography variant="caption" paragraph>
                      Don't have an account?{' '}
                      <Anchor
                        to="/auth/register"
                        onClick={
                          typeof onRegisterClick === 'function'
                            ? onRegisterClick
                            : undefined
                        }
                      >
                        Sign up
                      </Anchor>
                    </Typography>
                  </Grid>
                )}
                {onResetClick && (
                  <Grid item>
                    <Typography variant="caption" paragraph>
                      <Anchor
                        to="/auth/forgotten-password"
                        onClick={
                          typeof onResetClick === 'function'
                            ? onResetClick
                            : undefined
                        }
                      >
                        Forgotten your password?
                      </Anchor>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Fragment>
        )}

        {/* OTP */}

        {authState.screens.oneTimePasswordRequired &&
          authState.isAuthenticated && <OneTimePasswordForm {...props} />}
      </Box>
    </Paper>
  );
};
