// TODO: comment
import { GetPartiesResponse } from 'api/types';
import { getPartiesPerPage } from '../constants';
import { sendApiRequest } from '../utils';

/**
 * Get a list of the users accounts
 * @param start
 * @param max
 * @returns
 */
export const getAccounts = async (
  /**
   * Row offset of accounts
   */
  start: number = 0,
  /**
   * Maximum items to return in the request
   */
  max: number = getPartiesPerPage()
): Promise<GetPartiesResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/parties`;

  const data = {
    startRowIndex: start,
    maximumRows: max,
  };

  const response = await sendApiRequest<GetPartiesResponse>(endpoint, data);
  return response;
};
