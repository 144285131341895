// TODO: comment
import { Box, Container, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Component, ErrorInfo, Fragment } from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // console.error('@@', error.message);
    // console.error('@@', errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <Container>
            <Box marginY={4}>
              <Alert severity="warning">
                <Typography
                  variant="h4"
                  component="h2"
                  style={{ marginBottom: 15 }}
                >
                  Something went wrong.
                </Typography>
                <Typography>There was an issue with the application</Typography>
              </Alert>
            </Box>
          </Container>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
