// TODO: comment
import { sendApiRequest, withQuery } from '../utils';

export const getClientAccountHoldingsDownload = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId: string,
  /**
   * The valuation date. Optional. Defaults to latest available
   */
  valuationDate?: string,
  /**
   * The download format. Optional. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
): Promise<any> => {
  const response = await sendApiRequest<any>(
    getClientAccountHoldingsDownloadUri(
      clientId,
      accountId,
      valuationDate,
      format
    )
  );
  return response;
};

/**
 * Downloads Account Holding details for the specified client/account/date range in the specified format.
 */
export const getClientAccountHoldingsDownloadUri = (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId: string,
  /**
   * The valuation date. Optional. Defaults to latest available
   */
  valuationDate?: string,
  /**
   * The download format. Optional. Defaults to XLSX (Excel).
   */
  format: string = 'XLSX'
) => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}/holdings/download`;

  const data: any = {
    valuationDate,
    format,
  };

  return withQuery(endpoint, data);
};
