// TODO: comment
import { OurThinkingModel } from './types';

export const getArticle = async (articleId: string) => {
  const data: OurThinkingModel = {
    id: articleId,
    title: 'Attractive income from listed alternative investments',
    image: '',
    date: '05-04-2001',
    readTime: '5 mins',
    format: 'doc',
    excerpt:
      'Tony Foster, Investment Director with the Diversified Assets team, retires in April 2021. He looks back at some of the turning points in his fund',
  };

  return data;
};
