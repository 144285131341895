// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest, withQuery } from '../utils';

/**
 *
 * @param clientId "Downloads Client Holdings transactions for the specified client/account/date range in the specified format.
 * If {accountId} is missing the download contains transactions from all accounts for the client."
 * @param accountId
 * @param startDate
 * @param endDate
 * @returns
 */
export const getClientAccountTransactionsDownload = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId?: string | null | undefined,
  /**
   * Gets or sets the start date.
   */
  startDate?: Date | null | undefined,
  /**
   * Gets or sets the end date.
   */
  endDate?: Date | null | undefined,
  /**
   *
   */
  format: string = 'XLSX'
): Promise<any> => {
  const response = await sendApiRequest<any>(
    getClientAccountTransactionsDownloadUri(
      clientId,
      accountId,
      startDate,
      endDate
    )
  );

  return response;
};

/**
 * Downloads Client Holdings transactions for the specified client/account/date range in the specified format.
 * If {accountId} is missing the download contains transactions from all accounts for the client.
 */
export const getClientAccountTransactionsDownloadUri = (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId?: string | null | undefined,
  /**
   * Gets or sets the start date.
   */
  startDate?: Date | null | undefined,
  /**
   * Gets or sets the end date.
   */
  endDate?: Date | null | undefined,
  /**
   * Gets or sets the format. Defaults to XLSX (Excel)
   */
  format: string = 'XLSX'
) => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/transactions/download`;

  const data: any = {
    accountId: accountId || undefined,
    format: format.toUpperCase(),
  };

  if (startDate) {
    data.startDate = dayjs(startDate).format('YYYY-MM-DD');
  }

  if (endDate) {
    data.endDate = dayjs(endDate).format('YYYY-MM-DD');
  }

  return withQuery(endpoint, data);
};
