// TODO: comment
import { Subjects } from '../types';
import { sendApiRequest } from '../utils';

/**
 * Get a list of possible message subjects for new message creation
 */
export const getMessageSubjects = async (): Promise<Subjects> => {
  const response = await sendApiRequest<Subjects>(
    `${process.env.REACT_APP_API_URL}api/messages/subjects`,
    null,
    {},
    true
  );
  return response;
};
