// TODO: comment
import { MemorableQuestions } from 'api/types';
import { sendApiRequest } from '../utils';

export const getMemorableQuestions = async (): Promise<MemorableQuestions> => {
  const response = await sendApiRequest<MemorableQuestions>(
    `${process.env.REACT_APP_API_URL}api/account/memorable-questions`
  );

  return response;
};
