// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageAccount from './PageAccount';
import PageSettings from './PageSettings';

export const AccountRoutes = () => {
  return (
    <Fragment>
      <SecureRoute exact path="/account" component={PageAccount} />
      <SecureRoute path="/account/settings" component={PageSettings} />
    </Fragment>
  );
};
