// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';
import { AccountDetail } from './types';

/**
 * Gets detailed account information for the specified client/account, optionally at the specified valuation date.
 */
export const getClientAccountDetail = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,

  /**
   * Gets or sets the account identifier.
   */
  accountId: string,

  /**
   * Gets or sets the valuation date.
   */
  valuationDate?: Date | null | undefined,

  /**
   *
   */
  currencyCode?: string
): Promise<AccountDetail> => {
  // endpoint
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}`;

  // data to post
  const data: any = {};

  if (valuationDate) {
    data.valuationDate = dayjs(valuationDate).format('YYYY-MM-DD');
  }

  if (currencyCode) {
    data.currencyCode = currencyCode;
  }

  // send the request, and wait for the response
  const response = await sendApiRequest<AccountDetail>(endpoint, data);

  return response;
};
