// TODO: comment
import {
  STATUS_FAILED,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { changePassword } from 'api';
import { ValidationProvider } from 'components/file-selector';
import { Form, Password } from 'components/form';
import { ReplayIcon } from 'icons';
import { FormMessage } from 'interfaces';
import { toast } from 'material-react-toastify';
import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { AuthProps, ClickEvent } from './';
interface Props extends AuthProps {
  isPage?: boolean;
  onLoginClick?: boolean | ClickEvent;
}

export const ChangePasswordForm = ({
  hideTitle,
  onComplete,
  onLoginClick = true,
  persistForm,
  buttonLabel = 'Update my Password',
  isPage = false,
}: Props) => {
  const { t } = useTranslation(['common']);

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  const [formStatus, setFormStatus] = useState('');

  const schema = yup.object().shape({
    reset_password_current: yup
      .string()
      .required('Current Password is required'),
    reset_password: yup.string().required('Password is required'),
    reset_repeat: yup
      .string()
      .required('Password repeat is required')
      .oneOf([yup.ref('reset_password'), null], 'Passwords must match'),
  });

  const [data, setData] = useState({
    reset_password_current: '',
    reset_password: '',
    reset_repeat: '',
  });

  const methods = useForm({
    defaultValues: {
      reset_password_current: data.reset_password_current,
      reset_password: data.reset_password,
      reset_repeat: data.reset_repeat,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, reset } = methods;

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    changePassword(data.reset_password_current, data.reset_password).then(
      (res) => {
        if (res.success) {
          toast.success(
            t('common:forms.change-password.messages.toast-success')
          );

          setMessage({
            type: 'success',
            message: t('common:forms.change-password.messages.success'),
          });

          setFormStatus(STATUS_SENT);

          if (onComplete) {
            onComplete();
          }

          // TODO: possibly clear the form message after x seconds
          reset();
        } else {
          setFormStatus(STATUS_FAILED);

          setMessage({
            type: 'error',
            message: res.errorMessage ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: res.errorMessage,
                }}
              />
            ) : (
              t('common:forms.change-password.messages.error')
            ),
          });
        }
      }
    );
  };

  return (
    <Paper>
      <Box padding={4}>
        {!hideTitle && (
          <Typography variant="h4" component="h3">
            {t('common:forms.change-password.title')}
          </Typography>
        )}

        {message && (
          <Box marginBottom={2}>
            <Alert severity={message.type}>{message.message}</Alert>
          </Box>
        )}

        {(formStatus !== STATUS_SENT || persistForm) && (
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Password
                  ref={register}
                  id="reset_password_current"
                  label="Current Password"
                  name="reset_password_current"
                  error={!!errors.reset_password_current}
                  helperText={errors?.reset_password_current?.message}
                />

                {!isPage && (
                  <FormHelperText>
                    Note: This may be your temporary password
                  </FormHelperText>
                )}

                <Password
                  ref={register}
                  id="reset_password"
                  label="Password"
                  name="reset_password"
                  error={!!errors.reset_password}
                  helperText={errors?.reset_password?.message}
                />

                <Password
                  ref={register}
                  id="reset_repeat"
                  label="Repeat Password"
                  name="reset_repeat"
                  error={!!errors.reset_repeat}
                  helperText={errors?.reset_repeat?.message}
                />

                <Box paddingY={2} textAlign={isPage ? 'right' : undefined}>
                  <Button
                    size="large"
                    disabled={formStatus === STATUS_SENDING}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {buttonLabel}
                  </Button>
                </Box>
              </Form>
            </FormProvider>
          </ValidationProvider>
        )}

        {formStatus === STATUS_SENT && isPage === false && (
          <Fragment>
            <Grid container spacing={1} justify="space-between">
              <Grid item sm={true}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/auth/login`}
                  onClick={
                    typeof onLoginClick === 'function'
                      ? onLoginClick
                      : undefined
                  }
                >
                  Login
                </Button>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="reset"
                  onClick={() => {
                    reset();
                    setFormStatus('');
                    setMessage(null);
                  }}
                >
                  <ReplayIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Box>
    </Paper>
  );
};
