import { AbrdnBox, Balls } from '@abrdn';
import {
  ApplePodcastsIcon,
  ESGPodcastIcon,
  ESGReportIcon,
  SpotifyIcon,
} from 'icons';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { EmbeddedVideo, Stack } from '@abrdn-latest/core';
import { useRouter, useWidth } from '@abrdn-latest/use';

import { Anchor } from 'components/core';
import { GREY_MEDIUM } from '@abrdn-latest/config';
import React from 'react';

const handlePodcastClick = (ev: React.MouseEvent) => {
  ev.preventDefault();
};

const handleReportClick = (ev: React.MouseEvent) => {
  ev.preventDefault();
};

// copy
const content = {
  header: {
    title: 'Sustainable Investing',
    subtitle: 'Invest today. Change tomorrow.',
  },
  video: {
    title: 'Ethical, Social and Governance',
    src: 'https://aberdeen-asset.kulu.net/view/qcNHlWDvy6P?inline=true',
  },
  items: [
    {
      title: 'Active Ownership',
      copy: 'Read our latest Active Ownership Report which provides a summary of our research, company engagement and voting activities during the quarter',

      cta: (
        <Button
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          fullWidth
          href={
            'https://www.abrdn.com/docs?editionId=715e3d3b-fe96-42b9-b6cb-2caa171275a0'
          }
        >
          Read the Report
        </Button>
      ),

      icon: <ESGReportIcon style={{ fontSize: 50 }} />,
    },
    {
      title: 'Sustainability Inspires podcast',
      copy: 'Explore key Sustainable Investing themes such as ESG, sustainability and climate change - and how, together, we can invest for a better future.',

      cta: (
        <Stack spacing={2} direction="column">
          <Button
            variant="contained"
            color="primary"
            size="large"
            target="_blank"
            fullWidth
            href={
              'https://podcasts.apple.com/gb/podcast/sustainability-inspires-podcast/id1560985640#xd_co_f=OGIzMTg4YTItNGE0Zi00NTQ5LTg3NTgtOGI4N2ViMzYyNmI5~'
            }
            startIcon={<ApplePodcastsIcon />}
          >
            Listen on Apple Podcasts
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            target="_blank"
            fullWidth
            href={'https://open.spotify.com/show/0IUZ5zx3DYQYcGtNQA11Xd'}
            startIcon={<SpotifyIcon />}
          >
            Listen on Spotify
          </Button>
        </Stack>
      ),
      icon: <ESGPodcastIcon style={{ fontSize: 50 }} />,
    },
  ],
};

/**
 *
 * @returns ReactNode
 */
export const EthicalSocialGovernance = () => {
  const { isMobile } = useWidth();

  const { pathname } = useRouter();

  const isHome = pathname === '/';

  //

  return (
    <Paper style={{ backgroundColor: GREY_MEDIUM }}>
      <Container>
        <Box paddingY={8}>
          {/* Title/Header area */}
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              {isHome && (
                <Typography variant="h3">
                  <Anchor to="/esg/">{content.header.title}</Anchor>
                </Typography>
              )}
              <Typography variant="h4" style={{ margin: 0, marginBottom: 16 }}>
                {content.header.subtitle}
              </Typography>
            </Grid>
            {/* If the page is big enough, add the ball graphic */}
            {!isMobile && isHome && (
              <Grid item>
                <Balls cols={13} rows={5} />
              </Grid>
            )}
          </Grid>
          {/* video */}
          <Grid container spacing={3} wrap="wrap">
            <Grid item xs={12} md={6}>
              <EmbeddedVideo
                title={content.video.title}
                src={content.video.src}
              />
            </Grid>

            {content.items.map(
              ({ title, copy, icon = null, cta = null }, index: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{ display: 'flex' }}
                    key={`i-${index}`}
                  >
                    <AbrdnBox>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        style={{ marginTop: -15 }}
                      >
                        <Grid item xs>
                          <Typography
                            variant="h4"
                            style={{ margin: 0, fontSize: 21 }}
                          >
                            {title}
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginRight: -10 }}>
                          {icon}
                        </Grid>
                      </Grid>

                      <Box minHeight={150} marginTop={1}>
                        <Typography paragraph style={{ fontSize: 15 }}>
                          {copy}
                        </Typography>
                      </Box>
                      {cta}
                    </AbrdnBox>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};
