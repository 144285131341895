// TODO: comment
import { useDidMountEffect } from '@abrdn-latest/use';
import { Chip, Menu, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
interface Props {
  currencies: string[];
  onChange?(currencyCode: string): void;
}

export const CurrencySelector = ({ currencies, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [list, setList] = useState<string[]>(currencies);
  const [selected, setSelected] = useState<string>(
    currencies.length ? currencies[0] : ''
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const currentList = JSON.stringify(list);
    const newList = JSON.stringify(currencies);

    // if the list differs
    if (currentList !== newList && selected !== currencies[0]) {
      setSelected(currencies.length ? currencies[0] : '');
    }

    // const chosen = currencies.length ? currencies[0] : '';

    // if (chosen !== selected) {
    //   setSelected(chosen);
    // }

    setList(currencies);
  }, [currencies]);

  useDidMountEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected]);

  if (list.length === 0 || !selected) {
    return null;
  }

  if (list.length === 1) {
    return <Chip label={selected} />;
  }

  return (
    <span>
      <Chip
        aria-haspopup="true"
        onClick={handleClick}
        label={selected}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {list.map((value) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => {
              if (selected !== value) {
                setSelected(value);
              }
              handleClose();
            }}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};
