// https://stackblitz.com/edit/react-clamp-lines?file=Clamp.js

import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
  /**
   *
   */
  children: React.ReactNode;
  /**
   * The number of lines to clamp to
   */
  count: number;
}

export const LineClamp = ({ count, children }: Props) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: '-webkit-box',
      '-webkit-line-clamp': count,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  }));

  const classes = useStyles();

  return <span className={classes.root}>{children}</span>;
};
