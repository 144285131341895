// TODO: comment
import dayjs from 'dayjs';
import { sendApiRequest } from '../utils';
import { AccountTransactions } from './types';

/**
 * Gets a paginated list of account transactions for the specified client/account/date range.
 */
export const getClientAccountTransactions = async (
  /**
   * Gets or sets the client identifier.
   */
  clientId: string,
  /**
   * Gets or sets the account identifier.
   */
  accountId: string,
  /**
   * Gets or sets the start date.
   */
  startDate: Date | null | undefined,
  /**
   * Gets or sets the end date.
   */
  endDate: Date | null | undefined,

  /**
   * Gets or sets the page sort by clause. Optional. One of: transactiondate, transactiontype or fundname with optional _asc or _desc suffix
   */
  pageSortBy: 'transactiondate' | 'transactiontype' | 'fundname' | '' = '',

  /**
   * Gets or sets the page sort by clause. Optional. One of: assetclass, marketvalue or countryofissue with optional _asc or _desc suffix.
   */
  pageSortOrder: 'asc' | 'desc' = 'asc',

  /**
   * Gets or sets the page filter. Optional. Case insensitive free text
   */
  pageFilter?: string,

  /**
   * The start index of the rows to return (zero-based)
   */
  startRowIndex: number = 0,
  /**
   * The maximum rows to return.
   */
  maximumRows: number = 50
): Promise<AccountTransactions> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/client-holdings/clients/${clientId}/accounts/${accountId}/transactions`;

  const data: any = {
    startRowIndex,
    maximumRows,
    pageSortBy: pageSortBy ? `${pageSortBy}_${pageSortOrder}` : undefined,
    pageFilter: pageFilter || undefined,
  };

  if (startDate) {
    data.startDate = dayjs(startDate).format('YYYY-MM-DD');
  }

  if (endDate) {
    data.endDate = dayjs(endDate).format('YYYY-MM-DD');
  }

  const response = await sendApiRequest<AccountTransactions>(endpoint, data);

  return response;
};
