import { isApiSupported } from '@abrdn-latest/utils';
import { useEffect, useRef } from 'react';

export const useEventListener = (
  eventType: string,
  callback: any,
  element: any = window
) => {
  // Create a ref that stores handler
  const savedHandler = useRef<any>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = callback;
  }, [callback]);

  useEffect(() => {
    // Make sure element supports addEventListener

    const isSupported = isApiSupported('addEventListener', element);

    if (!isSupported) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const handler = (e: any) => savedHandler.current(e);

    // Add event listener
    element.addEventListener(eventType, handler);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventType, handler);
    };
  }, [eventType, element]);
};
