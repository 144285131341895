// TODO: comment
import { Box } from '@material-ui/core';
import { LoopIcon } from 'icons';
import React from 'react';
import './NoData.scss';

interface LoadingProps {
  children?: React.ReactNode;
}

export const Loading = ({ children }: LoadingProps) => {
  return (
    <Box className="loading" padding={4} textAlign="center">
      <LoopIcon className="loading__icon" style={{ fontSize: 50 }} />
      <Box>{children ? children : 'Loading...'}</Box>
    </Box>
  );
};
