// TODO: comment
import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Disclaimer = () => {
  const { t } = useTranslation(['common']);

  return (
    <Container maxWidth="md">
      <Box marginTop={6}>
        <Typography paragraph variant="caption">
          {t('common:pages.holdings.disclaimer')}
        </Typography>
      </Box>
    </Container>
  );
};
