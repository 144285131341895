// TODO: comment
import { getMessagesPerPage } from '../constants';
import { GetSentMessagesResponse } from '../types';
import { sendApiRequest } from '../utils';
/**
 * Get an array of sent messages
 */
export const getSentMessages = async (
  /**
   * Row offset of accounts
   */
  start: number = 0,
  /**
   * Maximum items to return in the request
   */
  max: number = getMessagesPerPage()
): Promise<GetSentMessagesResponse> => {
  const response = await sendApiRequest<GetSentMessagesResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/sent`,
    {
      startRowIndex: start,
      maximumRows: max,
    }
  );
  return response;
};
