// TODO: comment
import { sendApiRequest } from '../utils';

/**
 * Represents the response to a request to reply to an existing received message
 */
export interface ReplyToReceivedMessageResponse {
  /**
   * Gets or sets the identifier of the new message
   */
  id: string;
}

export const createReply = async (
  /**
   *
   */
  id: string,

  /**
   * Gets or sets the subject
   */
  subject: string,

  /**
   * The body of the message
   */
  body: string,

  /**
   * Gets or sets the document identifiers to attach to this message
   */
  documentIds?: string[]
): Promise<ReplyToReceivedMessageResponse> => {
  const response = await sendApiRequest<ReplyToReceivedMessageResponse>(
    `${process.env.REACT_APP_API_URL}api/messages/received/${id}/reply`,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        subject,
        body,
        documentIds,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
