// TODO: comment
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { PageErrorIcon } from 'icons';
import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {}

export const Error403 = ({}: Props) => {
  return (
    <Fragment>
      <Box paddingY={6}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Typography variant="h2" component="h1">
                Sorry it looks like you don't have access to this page
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <PageErrorIcon style={{ width: 150, height: 150 }} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h2">
            Error code: 403
          </Typography>

          <Typography variant="h4" component="h3">
            We're sorry but it does not look like you have access to this
            specific url
          </Typography>
          <Typography paragraph>
            We apologise for any inconvenience caused, you may find what you are
            looking for on your homepage, or by contacting us on 0800 123 123
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="large"
            to={`/`}
            component={RouterLink}
          >
            Back to home
          </Button>
        </Container>
      </Box>
    </Fragment>
  );
};
