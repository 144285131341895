// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageMessages from './PageMessages';
import PageNewMessage from './PageNewMessage';

export const MessageRoutes = () => {
  return (
    <Fragment>
      <SecureRoute exact path="/message/new" component={PageNewMessage} />
      <SecureRoute exact path="/messages" component={PageMessages} />
      <SecureRoute exact path="/messages/:mailbox/" component={PageMessages} />
      <SecureRoute
        exact
        path="/messages/:mailbox/:messageId"
        component={PageMessages}
      />
    </Fragment>
  );
};
