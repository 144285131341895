// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageOurThinking from './PageOurThinking';
import PageOurThinkingArticle from './PageOurThinkingArticle';

export const OurThinkingRoutes = () => {
  return (
    <Fragment>
      <SecureRoute exact path="/our-thinking" component={PageOurThinking} />

      {process.env.REACT_APP_FEATURE_OUR_THINKING && (
        <SecureRoute
          path="/our-thinking/:article"
          component={PageOurThinkingArticle}
        />
      )}
    </Fragment>
  );
};
