import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from '@material-ui/core';
import clsx from 'clsx';
import { ExpandMoreIcon } from 'icons';
import React, { createContext, useContext, useState } from 'react';

interface Context {
  expanded?: boolean;
  setExpanded(state: boolean): void;
}

const AccordionContext = createContext<Context>({
  setExpanded: (state: boolean) => {},
});

/**
 *
 * @returns
 */
export const useAccordion = (): Context => useContext(AccordionContext);

/**
 *
 */
export const AccordionProvider = AccordionContext.Provider;

interface AccordionProps {
  /**
   *
   */
  children: React.ReactNode;

  /**
   *
   */
  defaultExpanded?: boolean;
}

export const Accordion = ({
  children,
  defaultExpanded = true,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <AccordionProvider value={{ expanded, setExpanded }}>
      <Box marginBottom={2}>{children}</Box>
    </AccordionProvider>
  );
};

interface SummaryProps {
  /**
   *
   */
  children: React.ReactNode;

  /**
   *
   */
  expandIcon?: React.ReactNode;
}

const useAccordionSummaryStyles = makeStyles((theme) => ({
  root: {
    transition: 'all .3s',
    marginRight: -theme.spacing(2),
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
  collapsed: {
    transform: 'rotate(0deg)',
  },
}));

export const AccordionSummary = ({
  children,
  expandIcon = ExpandMoreIcon,
}: SummaryProps) => {
  const classes = useAccordionSummaryStyles();

  const { expanded, setExpanded } = useAccordion();

  const handleClick = (ev: React.MouseEvent) => {
    setExpanded(!expanded);
  };

  const Icon = expandIcon;

  return (
    <Box p={4} component={Paper}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item style={{ flexGrow: 1 }}>
          {children}
        </Grid>
        <Grid item>
          <Box marginLeft={1}>
            <IconButton
              onClick={handleClick}
              className={clsx(
                classes.root,
                expanded ? classes.expanded : classes.collapsed
              )}
            >
              {/* @ts-ignore */}
              <Icon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useAccordionToggleStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
}));

interface ToggleProps {
  /**
   *
   */
  children: React.ReactNode;
}

export const AccordionToggle = ({ children }: ToggleProps) => {
  const classes = useAccordionToggleStyles();
  const { expanded, setExpanded } = useAccordion();

  return (
    <Box
      onClick={() => {
        setExpanded(!expanded);
      }}
      className={classes.root}
    >
      {children}
    </Box>
  );
};

const useAccordionDetailStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

interface DetailProps {
  /**
   *
   */
  children: React.ReactNode;
}

/**
 *
 */
export const AccordionDetail = ({ children }: DetailProps) => {
  const classes = useAccordionDetailStyles();

  const { expanded } = useAccordion();

  return (
    <Collapse in={expanded}>
      <Box className={classes.root} component={Paper}>
        {children}
      </Box>
    </Collapse>
  );
};
