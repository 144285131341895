// TODO: comment
import { CountryList } from 'api/types';
import { reverseLookup } from 'utils';
import { sendApiRequest } from '../utils';

/**
 * Get a list of countries for user registration
 */
export const getCountryList = async (): Promise<CountryList> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/registration/countries`;

  const response = await sendApiRequest<CountryList>(endpoint, null, {}, true);

  return response;
};

/**
 *
 * @param value
 * @returns
 */
export const getCountryValue = async (value: string): Promise<string> => {
  const countries = await getCountryList();

  return reverseLookup(countries, value, 'code', 'displayText');
};
