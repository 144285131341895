// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import { RiskWarning } from './components';
import PageFundDetail from './PageFundDetail';
import PagePortfolioHoldings from './PagePortfolioHoldings';

export const PortfolioHoldingsRoutes = () => (
  <Fragment>
    <SecureRoute
      exact
      path="/portfolio-holdings"
      component={PagePortfolioHoldings}
    />
    <SecureRoute
      path="/portfolio-holdings/:fundCode"
      component={PageFundDetail}
    />

    <SecureRoute
      path="/portfolio-holdings/"
      component={() => {
        return <RiskWarning />;
      }}
    />
  </Fragment>
);
