export const getLocalStorageItem = <T>(name: string, defaultValue: T): T => {
  if (typeof Storage !== 'undefined') {
    const item = localStorage.getItem(name);

    if (item) {
      return JSON.parse(item);
    }
  }

  return defaultValue;
};

export const setLocalStorageItem = (name: string, value: unknown) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem(name, JSON.stringify(value));
  }
};

export const deleteLocalStorageItem = (name: string) => {
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem(name);
  }
};

export const getSessionStorageItem = <T>(name: string, defaultValue: T): T => {
  if (typeof Storage !== 'undefined') {
    const item = sessionStorage.getItem(name);

    if (item) {
      return JSON.parse(item);
    }
  }

  return defaultValue;
};

export const setSessionStorageItem = (name: string, value: unknown) => {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(name, JSON.stringify(value));
  }
};

export const deleteSessionStorageItem = (name: string) => {
  if (typeof Storage !== 'undefined') {
    sessionStorage.removeItem(name);
  }
};

export const deleteSessionStorageItems = () => {
  if (typeof Storage !== 'undefined') {
    sessionStorage.clear();
  }
};
