// TODO: comment
import { sendApiRequest } from '../utils';
import { isAuthenticatedResponse } from './types';

export const login = async (
  /**
   *
   */
  username: string,
  /**
   *
   */
  password: string
): Promise<isAuthenticatedResponse> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/login`;

  const response = await sendApiRequest<isAuthenticatedResponse>(
    endpoint,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
