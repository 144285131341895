// TODO: comment
import { Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './Splash.scss';

interface Props {
  alt: string;
  fadeOut?: boolean;
  logo?: string;
}

export const Splash = ({ alt, logo, fadeOut }: Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        setVisible(false);
      }, 1500);
    }
  }, [fadeOut]);

  return (
    <Fade in={visible}>
      <Paper className="app-loader">
        <img alt={alt} src={logo} width={304} height={66} />
      </Paper>
    </Fade>
  );
};
