// TODO: comment
import { handleResponse } from '../utils';

export const getAppSettings = async (): Promise<any> => {
  const response = await fetch(
    process.env.NODE_ENV === 'development'
      ? 'config.development.json'
      : 'config.json'
  ).then(handleResponse);

  return response;
};
