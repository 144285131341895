import SvgIcon from '@material-ui/core/SvgIcon';

export const SpotifyIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <path
        fill="#1ED760"
        d="M32,0C14.3,0,0,14.3,0,32c0,17.7,14.3,32,32,32c17.7,0,32-14.3,32-32C64,14.3,49.7,0,32,0L32,0L32,0z
        M46.7,46.2c-0.6,0.9-1.8,1.2-2.7,0.7c-7.5-4.6-17-5.6-28.1-3.1c-1.1,0.2-2.1-0.4-2.4-1.5c-0.2-1.1,0.4-2.1,1.5-2.4
       c12.2-2.8,22.6-1.6,31.1,3.6C47,44,47.3,45.2,46.7,46.2L46.7,46.2z M50.6,37.4c-0.7,1.2-2.3,1.5-3.4,0.8c-8.6-5.3-21.7-6.8-31.9-3.7
       c-1.3,0.4-2.7-0.3-3.1-1.7c-0.4-1.3,0.3-2.7,1.7-3.1c11.6-3.5,26.1-1.8,35.9,4.3C50.9,34.7,51.3,36.3,50.6,37.4L50.6,37.4L50.6,37.4
       z M50.9,28.4c-10.3-6.1-27.3-6.7-37.2-3.7c-1.6,0.5-3.3-0.4-3.7-2c-0.5-1.6,0.4-3.3,2-3.7c11.3-3.4,30.1-2.8,42,4.3
       c1.4,0.8,1.9,2.7,1,4.1C54.2,28.7,52.3,29.2,50.9,28.4L50.9,28.4L50.9,28.4z"
      />
    </SvgIcon>
  );
};
