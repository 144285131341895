// TODO: comment

import * as yup from 'yup';

import { AuthProps, ClickEvent } from './';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { Form, Input } from 'components/form';
import { FormProvider, useForm } from 'react-hook-form';
import React, { Fragment, useEffect, useState } from 'react';
import {
  STATUS_FAILED,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { forgottenPassword, forgottenPasswordVerify } from 'api';

import Alert from '@material-ui/lab/Alert';
import { Anchor } from 'components/core';
import { FormMessage } from 'interfaces';
import { Link } from 'react-router-dom';
import { ReplayIcon } from 'icons';
import { ValidationProvider } from 'components/file-selector';
import { formatString } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props extends AuthProps {
  onLoginClick?: boolean | ClickEvent;
}

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: 'none',
  },
}));

export const ForgottenPasswordForm = ({
  hideTitle,
  onComplete,
  onLoginClick = true,
}: Props) => {
  const { t } = useTranslation(['common']);

  const DEFAULT_BUTTON_LABEL: string = 'Reset via Email';

  const classes = useStyles();

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  // form status
  const [formStatus, setFormStatus] = useState<string>('');

  // submit button label
  const [buttonLabel, setButtonLabel] = useState<string>(DEFAULT_BUTTON_LABEL);
  const [questionLabel, setQuestionLabel] = useState<string>('');

  const schema = yup.object().shape({
    forgot_username: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    forgot_question: yup.number(),
    forgot_answer: yup.string().when('forgot_question', {
      is: (question: number) => {
        return question > 0;
      },
      then: yup.string().required('Please answer your memorable question'),
    }),
  });

  const [data, setData] = useState({
    forgot_username: '',
    forgot_question: 0,
    forgot_answer: '',
  });

  const methods = useForm({
    defaultValues: {
      forgot_username: data.forgot_username,
      forgot_question: data.forgot_question,
      forgot_answer: data.forgot_answer,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, reset, setValue } = methods;

  const onReset = () => {
    reset();
    setFormStatus('');
    setMessage(null);
    setValue('forgot_question', 0);
    setQuestionLabel('');
    setButtonLabel(DEFAULT_BUTTON_LABEL);
  };

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    if (questionLabel) {
      forgottenPasswordVerify(
        data.forgot_username,
        data.forgot_question,
        data.forgot_answer
      )
        .then((res) => {
          toast.success(
            t('common:forms.forgotten-password.messages.toast-success')
          );

          setFormStatus(STATUS_SENT);

          setMessage({
            type: 'success',
            message: formatString(
              t('common:forms.forgotten-password.messages.success'),
              {
                email: data.forgot_username,
              }
            ),
          });

          if (onComplete) {
            onComplete();
          }
        })
        .catch((err) => {
          setFormStatus(STATUS_FAILED);

          setMessage({
            type: 'error',
            message: t('common:forms.forgotten-password.messages.error'),
          });
        });
    } else {
      forgottenPassword(data.forgot_username)
        .then((res) => {
          if (res.passwordReset) {
            toast.success(
              t('common:forms.forgotten-password.messages.toast-success')
            );

            setFormStatus(STATUS_SENT);

            setMessage({
              type: 'success',
              message: formatString(
                t('common:forms.forgotten-password.messages.success'),
                {
                  email: data.forgot_username,
                }
              ),
            });

            if (onComplete) {
              onComplete();
            }
          }

          if (res.verificationRequired && res.memorableQuestion) {
            toast.success('Identity check');

            setFormStatus('');

            setValue('forgot_question', res.memorableQuestion.id);
            setQuestionLabel(res.memorableQuestion.displayText);
            setButtonLabel('Confirm identity and reset password');

            setMessage({
              type: 'info',
              message: t('common:forms.memorable-questions.messages.info'),
            });
          }
        })
        .catch((err: any) => {
          setFormStatus(STATUS_FAILED);

          setMessage({
            type: 'error',
            message: t('common:forms.forgotten-password.messages.error'),
          });
        });
    }
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  const supportEmail = 'ConnectHelp@abrdn.com';

  /**
   * email the support team
   * @param ev
   */
  const handleEmailSupport = (ev: React.MouseEvent) => {
    ev.preventDefault();

    // login assistance email subject
    const subject = `Connect login assistance`;

    // newline break
    const BREAK = '%0D%0A';

    // login assistace email body
    const body = `Dear Sir/Madam,${BREAK}${BREAK}I'm experiencing trouble logging in to Connect Portal`;

    window.location.href =
      'mailto:' + supportEmail + '?subject=' + subject + '&body=' + body;
  };

  return (
    <Paper>
      <Box padding={4}>
        {!hideTitle && (
          <Typography variant="h4" component="h3">
            {t('common:forms.forgotten-password.title')}
          </Typography>
        )}

        {message && (
          <Box marginBottom={2}>
            <Alert severity={message.type}>{message.message}</Alert>
          </Box>
        )}

        {formStatus !== STATUS_SENT && (
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  ref={register}
                  id="forgot_username"
                  type="email"
                  label="Email"
                  name="forgot_username"
                  error={!!errors.forgot_username}
                  helperText={errors?.forgot_username?.message}
                />

                <Box className={classes.hidden}>
                  <Input
                    ref={register}
                    id="forgot_question"
                    type="number"
                    label="Memorable question Id"
                    name="forgot_question"
                    error={!!errors.forgot_question}
                    helperText={errors?.forgot_question?.message}
                  />
                </Box>

                <Box
                  className={questionLabel === '' ? classes.hidden : undefined}
                >
                  <Input
                    ref={register}
                    id="forgot_answer"
                    type="text"
                    label={questionLabel}
                    name="forgot_answer"
                    error={!!errors.forgot_answer}
                    helperText={errors?.forgot_answer?.message}
                  />
                </Box>

                <Box paddingY={2}>
                  <Button
                    size="large"
                    disabled={formStatus === STATUS_SENDING}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {buttonLabel}
                  </Button>
                </Box>
              </Form>
            </FormProvider>
          </ValidationProvider>
        )}

        <Typography paragraph variant="caption">
          If you require assistance please email{' '}
          <Anchor onClick={handleEmailSupport} style={{ font: 'inherit' }}>
            {supportEmail}
          </Anchor>
        </Typography>

        {formStatus !== STATUS_SENT && (
          <Typography variant="caption" paragraph>
            <Anchor
              to="/auth/login"
              onClick={
                typeof onLoginClick === 'function' ? onLoginClick : undefined
              }
            >
              Back to login
            </Anchor>
          </Typography>
        )}

        {formStatus === STATUS_SENT && (
          <Fragment>
            <Grid container spacing={1} justify="space-between">
              <Grid item sm={true}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/auth/login`}
                  onClick={
                    typeof onLoginClick === 'function'
                      ? onLoginClick
                      : undefined
                  }
                >
                  Back to login
                </Button>
              </Grid>
              <Grid item>
                <IconButton aria-label="reset" onClick={onReset}>
                  <ReplayIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Box>
    </Paper>
  );
};
