import { TableCell, TableHead, TableRow, TableSortLabel } from '@abrdn';
import { TextField } from '@material-ui/core';
import { ArrowDropDownIcon, SearchIcon } from 'icons';
import React, { Fragment } from 'react';
import { HeadCell } from './';

/**
 *
 */
type Order = 'asc' | 'desc';

interface Props {
  /**
   *
   */
  cells: HeadCell[];

  /**
   *
   */
  onFilterRequest?: (field: string, value: string) => void;

  /**
   *
   */
  onSortRequest?: (event: React.MouseEvent<unknown>, property: string) => void;

  /**
   *
   */
  order: Order;

  /**
   *
   */
  orderBy: string;
}

export const TableHeader = ({
  cells,
  onFilterRequest,
  onSortRequest,
  order,
  orderBy,
}: Props) => {
  /**
   *
   */
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      if (onSortRequest) {
        onSortRequest(event, property);
      }
    };

  const createSearchHandler = (property: string) => (event: any) => {
    if (onFilterRequest) {
      onFilterRequest(property, event.target.value);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map(
          ({
            id,
            label,
            disablePadding = false,
            numeric = false,
            sortable = false,
            filterable = false,
            style = {},
          }) => {
            if (filterable) {
              style.paddingLeft = 0;
              style.paddingRight = 0;
            }

            return (
              <TableCell
                key={id}
                align={numeric ? 'right' : 'left'}
                padding={disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === id ? order : false}
                style={style}
              >
                <Fragment>
                  {filterable && (
                    <div>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id={id}
                        label={label}
                        onChange={createSearchHandler(id)}
                        autoComplete="off"
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              fontSize="small"
                              style={{ marginRight: 10, color: 'inherit' }}
                            />
                          ),
                        }}
                      />
                    </div>
                  )}

                  {sortable && (
                    <TableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      <b>{label}</b>
                    </TableSortLabel>
                  )}

                  {!filterable && !sortable && label}
                </Fragment>
              </TableCell>
            );
          }
        )}
      </TableRow>
    </TableHead>
  );
};
