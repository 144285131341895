import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import PageAuth from './PageAuth';

export const AuthRoutes = () => {
  return (
    <Fragment>
      <Route exact path="/auth/:view?" component={PageAuth} />
    </Fragment>
  );
};
