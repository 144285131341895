// TODO: comment
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { PageMaintenanceIcon } from 'icons';
import React, { Fragment } from 'react';

interface Props {}

export const Error500 = ({}: Props) => {
  return (
    <Fragment>
      <Box paddingY={6}>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Typography variant="h2" component="h1">
                Sorry our website is temporarily unavailable
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <PageMaintenanceIcon style={{ width: 150, height: 150 }} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h2">
            Error code: 500
          </Typography>

          <Typography variant="h4" component="h3">
            Our website is currently undergoing maintenance
          </Typography>
          <Typography paragraph>
            We're carrying out some scheduled maintenance on our website and
            we'll be back up and running as soon as we can.
          </Typography>

          <Typography paragraph>
            We apologise for any inconvenience caused.
          </Typography>
        </Container>
      </Box>
    </Fragment>
  );
};
