// TODO: comment
import { AllFilters, FundlistingProps } from 'api/types';
import { createContext, useContext } from 'react';
import { SearchFilters } from '../components';

interface Context {
  //allFunds: FundlistingProps | null;
  clearFilters(): void;
  filters: AllFilters | null;
  funds: FundlistingProps | null;
  //loadFilters(): void;
  setAssetFunds(assetFundClass: string[]): void;
  setCountry(country: string): void;
  setFundRanges(fundRange: string[]): void;
  setInvestorType(investorType: string): void;
  setProductTypes(productType: string[]): void;
  setSearch(search: string): void;
  setViewAs(country: string, investorType: string): void;
  //startup(): void;
  state: SearchFilters;
  status: string;
}

const PortfolioHoldingsContext = createContext<Context>({
  status: '',
  state: {
    country: '',
    countryDisplay: '',
    investorType: '',
    investorTypeDisplay: '',
    search: '',
    assetFundClass: [],
    fundRange: [],
    productType: [],
  },
  //allFunds: null,
  funds: null,
  filters: null,
  // loadFilters: async () => {
  //   return null;
  // },
  // startup: async () => {
  //   return null;
  // },
  setViewAs: (country: string, investorType: string): void => {},
  setCountry: (country: string): void => {},
  setInvestorType: (investorType: string): void => {},
  setSearch: (search: string): void => {},
  setAssetFunds: (assetFundClass: string[]): void => {},
  setFundRanges: (fundRange: string[]): void => {},
  setProductTypes: (productType: string[]): void => {},
  clearFilters: (): void => {},
});

export const usePortfolioHoldings = (): Context =>
  useContext(PortfolioHoldingsContext);

export const PortfolioHoldingsProvider = PortfolioHoldingsContext.Provider;
