// TODO: comment
import { Button, IconButton } from '@material-ui/core';
import { favouriteFund } from 'api';
import { StarBorderIcon, StarIcon } from 'icons';
import React, { useEffect, useState } from 'react';
import './FundListing.scss';
interface Props {
  fundCode: string;
  onChange?(): void;
  saved?: boolean;
  showLabel?: boolean;
}

export const SaveFund = ({
  fundCode,
  saved = false,
  showLabel,
  onChange,
}: Props) => {
  const [isSaved, setSaved] = useState(saved);

  useEffect(() => {
    if (saved !== isSaved) {
      setSaved(saved);
    }
  }, [saved]);

  const handleClick = async () => {
    const result = await favouriteFund(fundCode, !isSaved);

    setSaved(result);

    if (onChange) {
      onChange();
    }
  };

  if (showLabel) {
    return (
      <Button
        color="primary"
        onClick={handleClick}
        startIcon={isSaved ? <StarIcon /> : <StarBorderIcon />}
      >
        {isSaved ? 'Remove from Favorites' : 'Add to Favorites'}
      </Button>
    );
  }

  return (
    <IconButton
      size="small"
      color="primary"
      aria-label={isSaved ? 'Remove from Favorites' : 'Add to Favorites'}
      onClick={handleClick}
    >
      {isSaved ? <StarIcon /> : <StarBorderIcon />}
    </IconButton>
  );
};
