// TODO: comment
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DeletedState {
  ids: string[];
}

const initialState = { ids: [] } as DeletedState;

const documentSlice = createSlice({
  name: 'documents-deleted',
  initialState,
  reducers: {
    addDeletedDocumentId(state, action: PayloadAction<string>) {
      state.ids.push(action.payload);
    },
  },
});

export const { addDeletedDocumentId } = documentSlice.actions;

export const selectDeletedIds = (state: any) => {
  return state['documents-deleted'].ids;
};

export default documentSlice.reducer;
