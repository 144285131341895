// TODO: comment
import { sendApiRequest } from '../utils';
import { FundlistingProps } from './types';

/**
 *
 */
export const getFundList = async (
  /**
   * One or more fund codes to filter the funds.
   */
  fundCode?: string[],
  /**
   * Fund name to filter the funds.
   */
  fundName?: string,
  /**
   * One or more fund ranges to filter the funds.
   */
  fundRange?: string[],
  /**
   * One or more product types to filter the funds.
   */
  productType?: string[],
  /**
   * One or more fund asset classes to filter the funds.
   */
  fundAssetClass?: string[],
  /**
   * Investor Type
   */
  investorType?: string,
  /**
   * Country
   */
  countryCode?: string
): Promise<FundlistingProps> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/portfolio-holdings/funds`;

  const data = {
    fundCode: fundCode || undefined,
    fundName: fundName || undefined,
    fundRange: fundRange || undefined,
    productType: productType || undefined,
    fundAssetClass: fundAssetClass || undefined,
    investorType: investorType ? investorType : undefined,
    countryCode: countryCode ? countryCode : undefined,
  };

  const response = await sendApiRequest<FundlistingProps>(endpoint, data);

  return response;
};
