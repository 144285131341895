import { AccountsWidget } from 'pages/client-holdings';
import { EthicalSocialGovernance } from '../esg/components';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { OurThinkingWidget } from 'pages/our-thinking/components';
import { useTranslation } from 'react-i18next';

const PageHome = () => {
  // language translations
  const { t } = useTranslation(['common']);

  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.home')}</title>
      </Helmet>

      {/* Homepage banner */}
      <AccountsWidget />

      {/* ESG Banner and articles */}
      <EthicalSocialGovernance />

      {/* Our Thinking / news articles */}
      {process.env.REACT_APP_FEATURE_OUR_THINKING && <OurThinkingWidget />}
    </Fragment>
  );
};

export default PageHome;
