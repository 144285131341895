import { getUserDetails } from 'api';
import { hash } from 'utils';

// TODO: comment
export const removeFromFavourites = async (
  /**
   *
   */
  userId: string,
  /**
   *
   */
  fundCode: string
) => {
  if (typeof Storage !== 'undefined') {
    const stored = await getStoredFavourites();

    // remove the possible item from favourites
    const items: string[] = stored.filter((item) => item !== fundCode);

    // save to local storage
    window.localStorage.setItem(userId, JSON.stringify(items));
  }
};

export const addToFavourites = async (
  /**
   *
   */
  userId: string,
  /**
   *
   */
  fundCode: string
) => {
  if (typeof Storage !== 'undefined') {
    const stored = await getStoredFavourites();

    // remove the possible item from favourites - to avoid duplicates
    const items: string[] = stored.filter((item) => item !== fundCode);

    // add the item in
    items.push(fundCode);

    // save to local storage
    window.localStorage.setItem(userId, JSON.stringify(items));
  }
};

export const getStoredFavourites = async (): Promise<string[]> => {
  const userDetails = await getUserDetails();

  const userId: string = hash(userDetails.emailAddress);

  if (typeof Storage !== 'undefined') {
    // TODO: get this list based on some kind of logged in user key
    const savedList: null | string = window.localStorage.getItem(userId);

    if (savedList) {
      return JSON.parse(savedList);
    }
  }

  return [];
};

/**
 *
 */
export const favouriteFund = async (
  /**
   *
   */
  fundCode: string,
  /**
   *
   */
  save: boolean
): Promise<boolean> => {
  // get the user details
  const userDetails = await getUserDetails();

  const userId: string = hash(userDetails.emailAddress);

  save
    ? addToFavourites(userId, fundCode)
    : removeFromFavourites(userId, fundCode);

  return new Promise((resolve, reject) => {
    resolve(save);
  });
};
