import { dequal as deepEqual } from 'dequal';
import {
  DependencyList,
  EffectCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

type UseEffectReturn = ReturnType<typeof useEffect>;

const checkDeps = (deps: DependencyList) => {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.'
    );
  }

  // if (deps.every(isPrimitive)) {
  //   throw new Error(
  //     'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
  //   );
  // }
};

const isPrimitive = (val: unknown): boolean => {
  return val == null || /^[sbn]/.test(typeof val);
};

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a momoized version of the value as long as it remains deeply equal
 */
export const useDeepCompareMemoize = <T>(value: T) => {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!deepEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [signalRef.current]);
};

export const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
): UseEffectReturn => {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }

  return useEffect(callback, useDeepCompareMemoize(dependencies));
};

export const useDeepCompareEffectNoCheck = (
  callback: EffectCallback,
  dependencies: DependencyList
): UseEffectReturn => {
  return useEffect(callback, useDeepCompareMemoize(dependencies));
};
