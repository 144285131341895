// TODO: comment
import commonDe from 'i18n/locales/de/common.json';
import clientEn from 'i18n/locales/en/client.json';
import commonEn from 'i18n/locales/en/common.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
const resources = {
  en: {
    common: commonEn,
    client: clientEn,
  },
  de: {
    common: commonDe,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
