// TODO: comment
import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DocumentUploadIcon } from 'icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useMessageComposerDispatch,
  useMessageComposerState,
} from 'utils/message-context';
import '../PageMessages.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
  })
);

export const MessageHeader = () => {
  const dispatch = useMessageComposerDispatch();
  const { state } = useMessageComposerState();
  const classes = useStyles();

  const hancleClick = (ev: React.MouseEvent) => {
    // @ts-ignore
    dispatch({
      type: state === 'open' ? 'minimised' : 'open',
    });

    ev.preventDefault();
  };

  return (
    <Box>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component={RouterLink}
        to={'/message/new'}
        endIcon={<DocumentUploadIcon />}
        onClick={hancleClick}
        size="large"
      >
        New message
      </Button>
    </Box>
  );
};
