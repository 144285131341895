// TODO: comment
import { ThemeOptions } from '@material-ui/core/styles';

const darkTheme: ThemeOptions = {
  palette: {
    type: 'dark',
    text: {
      primary: '#fff',
    },
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#0057B7',
    },
    background: {
      default: '#000',
      paper: '#000',
    },
  },
};

export default darkTheme;
