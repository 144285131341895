// TODO: comment
import { useApp } from '@abrdn-latest/providers';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Anchor } from 'components/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeDefault } from 'styles';
import './HeaderDesktop.scss';
import { PrimaryNavigation } from './Navigation';

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    transition: 'color .8s, backgroundColor .8s',
  },

  homepage: {
    marginBottom: -theme.spacing(13),
  },

  solid: {
    borderBottom: '1px solid #000000',
  },

  transparent: {
    backgroundColor: 'transparent',
    color: '#fff',
  },
}));

export const HeaderDesktop = () => {
  const { t } = useTranslation(['common']);

  const classes = useStyles();

  const [elevation, setElevation] = useState<number>(0);

  const showBelow: number = 10;

  // get the app settings, used for general styling etc
  const { isHomepage = false } = useApp();

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (elevation === 0) {
        setElevation(20);
      }
    } else {
      if (elevation) {
        setElevation(0);
      }
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }
  });

  const isScrolled = elevation > 0;

  const logo = {
    alt: t('title'),
    src: `images/abrdn${isHomepage && !isScrolled ? '-light' : ''}.svg`,
  };

  return (
    <ThemeDefault>
      <Paper
        className={clsx(
          classes.sticky,

          isHomepage ? classes.homepage : null,
          isHomepage
            ? isScrolled
              ? classes.solid
              : classes.transparent
            : classes.solid
        )}
        elevation={elevation}
      >
        <Container
          maxWidth={'xl'}
          // maxWidth={isHomepage ? 'xl' : 'lg'}
          // style={{ transition: 'max-width .3s' }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Box maxWidth={165}>
                {isHomepage ? (
                  <img
                    className="header-desktop__image"
                    {...logo}
                    width={304}
                    height={66}
                  />
                ) : (
                  <Anchor to="/">
                    <img
                      className="header-desktop__image"
                      {...logo}
                      width={304}
                      height={66}
                    />
                  </Anchor>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={10}>
              <nav className="nav nav--main nav--desktop">
                <PrimaryNavigation />
              </nav>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </ThemeDefault>
  );
};
