// TODO: comment
import { Box } from '@material-ui/core';
import React from 'react';
interface Props {
  cols?: number;
  pallette?: string[];
  rows?: number;
}

export const Balls = ({
  rows = 12,
  cols = 6,
  pallette = ['#00205B', '#A7A8A9', '#0057B7', '#00C1D4'],
}: Props) => {
  const size: number = 9;

  const vals: number[] = Array.from({ length: rows * cols }, () =>
    Math.round(Math.random())
  );

  let y: number = 0;

  return (
    <Box>
      <svg
        width={cols * (size * 2)}
        height={rows * (size * 2)}
        viewBox={`0 0 ${cols * (size * 2)} ${rows * (size * 2)}`}
        fill="none"
      >
        {vals.map((item, index: number) => {
          const x: number = index % cols;

          if ((index + 1) % cols === 0) {
            y++;
          }

          const px: number = x * (size * 2) + size;
          const py: number = y * (size * 2) + size;

          if (!item) {
            return null;
          }

          return (
            <circle
              key={`c-${index}`}
              r={size}
              cx={px}
              cy={py}
              fill={pallette[Math.floor(Math.random() * pallette.length)]}
            />
          );
        })}
      </svg>
    </Box>
  );
};
