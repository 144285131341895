// TODO: comment
import { TableCell, TableHead, TableRow } from '@abrdn';
import { Checkbox } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    margin: 0,
  },
}));
interface EnhancedTableHeadProps {
  canDelete: boolean;
  headCells: Array<any>;
  numSelected: number;
  onSelectAllClick(ev: any): void;
  rowCount: number;
}

export const EnhancedTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  headCells,
  canDelete = true,
}: EnhancedTableHeadProps) => {
  return (
    <TableHead>
      <TableRow>
        {canDelete && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={clsx(headCell.className || undefined, {
              icon: headCell.icon,
            })}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
