// TODO: comment

import 'components/table/Table.scss';
import './DocumentsTable.scss';

import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DeleteIcon,
  DescriptionIcon,
  DescriptionIconUnread,
  GetAppIcon,
} from 'icons';
import { Document, Pagination } from 'api/types';
import { EnhancedTableHead, useToolbarStyles } from 'components/table';
import React, { Fragment, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@abrdn';
import { getDocumentsPerPage, setDocumentsPerPage } from 'api/constants';
import {
  selectDeletedIds,
  selectDocumentCategories,
} from 'redux/reducers/documents';

import { Anchor } from 'components/core';
import { Confirmation } from 'components/confirmation';
import { Pagination as PaginationView } from 'components/pagination';
import clsx from 'clsx';
import { downloadFile } from 'api/utils';
import { getDocumentCategory } from 'pages/documents/documents.util';
import { getDocumentUri } from 'api';
import { getFormattedDate } from 'utils';
import { useSelector } from 'react-redux';
import { Progress } from '../../../components/ui';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Report Name',
    icon: false,
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: false,
    label: 'Account',
    icon: false,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },

  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    className: 'doc-date',
  },
  {
    id: 'published',
    numeric: false,
    disablePadding: false,
    label: 'Published',
    className: 'doc-date',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: '',
    className: 'doc-download',
  },
];

interface EnhancedTableToolbarProps {
  canDelete: boolean;
  numSelected: number;
  onDelete(): void;
  title: string;
}

const EnhancedTableToolbar = ({
  numSelected,
  onDelete = () => {},
  title = 'Documents',
  canDelete,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();

  if (numSelected > 0 && canDelete) {
    return (
      <TableBody>
        <TableRow>
          <TableCell
            colSpan={headCells.length + 1}
            className={clsx(classes.root, {})}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {numSelected > 0 && canDelete ? (
                  <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {numSelected} selected
                  </Typography>
                ) : (
                  <Typography
                    className={classes.title}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                  >
                    {title}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Tooltip title="Delete">
                  <IconButton aria-label="delete" onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return null;
};

interface DocumentsProps {
  documents: Document[];
  pagination: Pagination;
}

interface DocumentsTableProps {
  canDelete?: boolean;
  data: DocumentsProps;
  onDelete(selected: Array<string>): void;
  onPagination(page: number, rows: number): void;
  title: string;
}

export const DocumentsTable = ({
  data,
  onDelete,
  onPagination,
  title,
  canDelete = true,
}: DocumentsTableProps) => {
  const deletedDocuments = useSelector(selectDeletedIds);

  const categories = useSelector(selectDocumentCategories);

  const [selected, setSelected] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(getDocumentsPerPage());
  const [downloadingDocuments, setDownloadingDocuments] = useState<string[]>([]);

  const rows: Array<Document> = data.documents;

  const handleSelectAllClick = (ev: any) => {
    if (ev.target.checked) {
      const newSelecteds = rows
        .filter((n: Document) => {
          return n.canDelete;
        })
        .map((n: Document) => {
          return n.id;
        });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const [confirmMessageOpen, setConfirmMessageOpen] = useState(false);

  const handleDelete = () => {
    setConfirmMessageOpen(true);
  };

  const onConfirm = () => {
    onDelete(selected);
    setConfirmMessageOpen(false);
    setSelected([]);
  };
  const onCancel = () => {
    setConfirmMessageOpen(false);
  };

  const handleClick = (ev: React.SyntheticEvent, document: Document) => {
    if (document.canDelete) {
      const selectedIndex = selected.indexOf(document.id);

      let newSelected: any[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, document.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const onPageChange = (items: number) => {
    setRowsPerPage(items);
    setPage(0);

    onPagination(0, items);

    setDocumentsPerPage(items);
  };

  // @ts-ignore
  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const [readOverrides, setReadOverrides] = React.useState<string[]>([]);

  const handleDownload = async (document: Document) => {
    if (downloadingDocuments.includes(document.id)) {
      return;
    }

    setDownloadingDocuments(docs => [...docs, document.id]);

    // download the file
    downloadFile(
      getDocumentUri(document.id),
      `${document.filename}`,
      <Progress label={`Downloading ${document.filename}`} />
    ).then(
      () => setDownloadingDocuments(docs => docs.filter(id => id !== document.id))
    );

    //
    setReadOverrides([...readOverrides, document.id]);
  };

  return (
    <Fragment>
      <Confirmation
        isOpen={confirmMessageOpen}
        confirmText="Yes, Delete"
        onConfirm={onConfirm}
        rejectText="No, cancel"
        onReject={onCancel}
      >
        <Typography paragraph>
          Are you sure you want to delete <b>{selected.length}</b> documents
        </Typography>
      </Confirmation>
      <Paper>
        <TableContainer component={Paper}>
          <Table className="doc-table" size="medium">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={
                rows.filter((item) => {
                  return item.canDelete;
                }).length
              }
              canDelete={canDelete}
            />

            <EnhancedTableToolbar
              title={title}
              canDelete={canDelete}
              numSelected={selected.length}
              onDelete={handleDelete}
            />

            <TableBody>
              {rows.map((document: Document, index) => {
                const isItemSelected = isSelected(document.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                const isDeleted = deletedDocuments.indexOf(document.id) > -1;

                const unread =
                  !document.read &&
                  !(readOverrides.indexOf(document.id) > -1) &&
                  typeof document.read === undefined;

                return (
                  <TableRow
                    onClick={(ev: React.SyntheticEvent) => {
                      if (ev.target && !isDeleted) {
                        const nodeType: string = (ev.target as Element)
                          .nodeName;

                        if (nodeType === 'A') {
                          return null;
                        }

                        //
                        if (nodeType === 'INPUT') {
                          handleClick(ev, document);
                        }
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={document.id}
                    selected={isItemSelected}
                    className={clsx({
                      deleted: isDeleted,
                      unread,
                    })}
                  >
                    {canDelete && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          disabled={!document.canDelete}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" id={labelId} scope="row">
                      <span className="doc-filename">
                        {unread ? (
                          <DescriptionIconUnread />
                        ) : (
                          <DescriptionIcon />
                        )}

                        <span className="doc-filename__title">
                          {document.description || document.filename}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className="account">
                      {document.accountName || ''}
                    </TableCell>
                    <TableCell className="description">
                      {getDocumentCategory(document.typeCode, categories)}
                    </TableCell>
                    <TableCell className="doc-date">
                      {getFormattedDate(document.createdDate, false)}
                    </TableCell>
                    <TableCell className="doc-date">
                      {getFormattedDate(document.publishedDate, false)}
                    </TableCell>
                    <TableCell className="doc-download">
                      <Anchor
                        onClick={async (ev: React.MouseEvent) => {
                          ev.preventDefault();

                          handleDownload(document);
                        }}
                      >
                        <GetAppIcon />
                      </Anchor>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <PaginationView
        onPagination={onPagination}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        startRowIndex={data.pagination.startRowIndex}
        maximumRows={data.pagination.maximumRows}
        minimumTotalRows={data.pagination.minimumTotalRows}
        totalRows={data.pagination.totalRows}
      />
    </Fragment>
  );
};
