// TODO: comment
import {
  STATUS_DATA_LOADING,
  STATUS_FAILED,
  STATUS_INIT_ERROR,
  STATUS_SENDING,
  STATUS_SENT,
} from '@abrdn-latest/config';
import { useRouter } from '@abrdn-latest/use';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { getCountryList, getInvestorTypes, registerAccount } from 'api';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { ValidationProvider } from 'components/file-selector';
import { Form, Input, Select } from 'components/form';
import { Progress } from 'components/ui';
import { ReplayIcon } from 'icons';
import { FormMessage } from 'interfaces';
import { toast } from 'material-react-toastify';
import { TermsAndConditions } from 'pages/terms-and-conditions/PageTermsAndConditions';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as validation from 'validation';
import * as yup from 'yup';
import { AuthProps, ClickEvent } from './';

interface Props extends AuthProps {
  onLoginClick?: boolean | ClickEvent;
}

export const RegisterForm = ({
  hideTitle,
  onComplete,
  onLoginClick = true,
}: Props) => {
  const { t } = useTranslation(['common']);

  const { authState } = useSecurity();

  // router
  const router = useRouter();

  // form messaging
  const [message, setMessage] = useState<FormMessage | null>(null);

  const [formStatus, setFormStatus] = useState('');

  const [countryList, setCountryList] = useState<any[] | null>(null);
  const [investorTypes, setInvestorTypes] = useState<any[] | null>(null);

  const fetchMyAPI = useCallback(async () => {
    setMessage({
      type: 'info',
      message: `Loading form data`,
    });

    setFormStatus(STATUS_DATA_LOADING);

    try {
      const response = await getCountryList();

      if (response) {
        setCountryList(response);
      }

      const types = await getInvestorTypes();
      if (types) {
        setInvestorTypes(types);
      }

      setFormStatus('');
      setMessage(null);
    } catch (e) {
      setFormStatus(STATUS_INIT_ERROR);

      setMessage({
        type: 'error',
        message: t('common:forms.register.messages.loading-error'),
      });
    }
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  const schema = yup.object().shape({
    register_email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    register_firstname: yup
      .mixed()
      .required('Firstname is required')
      .test('notblank', 'Firstname is required', (value) => {
        return String(value) !== '';
      })
      .test(
        'validCharacters',
        'Please enter valid characters only',
        validation.validCharacters
      ),
    register_lastname: yup
      .mixed()
      .required('Lastname is required')
      .test('notblank', 'Lastname is required', (value) => {
        return String(value) !== '';
      })
      .test(
        'validCharacters',
        'Please enter valid characters only',
        validation.validCharacters
      ),
    register_accepted: yup
      .boolean()
      .required('You must accept the terms and conditions')
      .oneOf([true], 'The terms and conditions must be accepted.'),
    register_country: yup.string().required('Country is a required field'),
    register_investortype: yup
      .string()
      .required('Investor Type is a required field'),
  });

  const [data, setData] = useState({
    register_email: '',
    register_firstname: '',
    register_lastname: '',
    register_accepted: false,
    register_country: '',
    register_investortype: '',
  });

  const methods = useForm({
    defaultValues: {
      register_email: data.register_email,
      register_firstname: data.register_firstname,
      register_lastname: data.register_lastname,
      register_accepted: data.register_accepted,
      register_country: data.register_country,
      register_investortype: data.register_investortype,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { control, register, handleSubmit, errors, reset } = methods;

  const onSubmit = async (data: any) => {
    setData(data);

    setFormStatus(STATUS_SENDING);

    const getRegion = (): string => {
      if (!countryList) {
        return '';
      }

      const match: any | undefined = countryList.find((item: any) => {
        return item.code === data.register_country;
      });

      if (match) {
        return match.regionCode;
      }

      return '';
    };

    registerAccount(
      data.register_email,
      data.register_firstname,
      data.register_lastname,
      getRegion(),
      data.register_country,
      data.register_investortype,
      data.register_accepted
    ).then((res) => {
      if (res.success) {
        toast.success(t('common:forms.register.messages.toast-success'));

        setMessage({
          type: 'success',
          message: t('common:forms.register.messages.success'),
        });

        setFormStatus(STATUS_SENT);

        if (onComplete) {
          onComplete();
        }
      } else {
        setFormStatus(STATUS_FAILED);

        setMessage({
          type: 'error',
          message: res.errorMessage ? (
            <div
              dangerouslySetInnerHTML={{
                __html: res.errorMessage,
              }}
            />
          ) : (
            t('common:forms.register.messages.error')
          ),
        });
      }
    });
  };

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [message, errors]);

  // terms and conditions
  const [termsOpen, setTermsOpen] = React.useState<boolean>(false);

  const handleTermsClose = () => {
    setTermsOpen(false);
  };

  const handleClick = (ev: React.MouseEvent) => {
    if (ev) {
      ev.preventDefault();
    }

    setTermsOpen(true);
  };

  if (authState.isFullyAuthenticated) {
    router.history.replace('/');
  }

  return (
    <Fragment>
      <Paper>
        <Box padding={4}>
          {!hideTitle && (
            <Typography variant="h4" component="h3">
              {t('common:forms.register.title')}
            </Typography>
          )}

          <Typography paragraph align="center">
            abrdn Connect is a servicing tool for our Institutional clients, and
            provides all users with information on our publicly available funds
          </Typography>

          {message && (
            <Box marginBottom={2}>
              <Alert severity={message.type}>{message.message}</Alert>
            </Box>
          )}

          {formStatus !== STATUS_SENT &&
            formStatus !== STATUS_INIT_ERROR &&
            formStatus !== STATUS_DATA_LOADING && (
              <ValidationProvider schema={schema}>
                <FormProvider {...methods}>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      ref={register}
                      id="register_email"
                      type="email"
                      label="Email"
                      name="register_email"
                      error={!!errors.register_email}
                      helperText={errors?.register_email?.message}
                    />

                    <Input
                      ref={register}
                      id="register_firstname"
                      type="text"
                      label="Firstname"
                      name="register_firstname"
                      error={!!errors.register_firstname}
                      helperText={errors?.register_firstname?.message}
                    />

                    <Input
                      ref={register}
                      id="register_lastname"
                      type="text"
                      label="Lastname"
                      name="register_lastname"
                      error={!!errors.register_lastname}
                      helperText={errors?.register_lastname?.message}
                    />

                    {countryList && countryList.length > 0 && (
                      <Select
                        ref={register}
                        control={control}
                        // @ts-ignore
                        id="register_country"
                        label="Country"
                        name="register_country"
                        options={countryList.map((item: any, index: number) => {
                          return {
                            displayText: item.displayText,
                            value: item.code,
                          };
                        })}
                        error={!!errors.register_country}
                        helperText={errors?.register_country?.message}
                      />
                    )}

                    {investorTypes && investorTypes.length > 0 && (
                      <Select
                        ref={register}
                        control={control}
                        // @ts-ignore
                        id="register_investortype"
                        label="Investor Type"
                        name="register_investortype"
                        options={investorTypes.map(
                          (item: any, index: number) => {
                            return {
                              displayText: item.displayText,
                              value: item.code,
                            };
                          }
                        )}
                        error={!!errors.register_investortype}
                        helperText={errors?.register_investortype?.message}
                      />
                    )}

                    <div>
                      <div>
                        <Controller
                          name="register_accepted"
                          control={control}
                          render={(props: any) => (
                            <Checkbox
                              color="primary"
                              {...props}
                              id="register_accepted"
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />

                        <label htmlFor="register_accepted">
                          I have read and accepted the{' '}
                          <Anchor
                            to="/terms-and-conditions"
                            onClick={handleClick}
                          >
                            Terms &amp; Conditions
                          </Anchor>
                        </label>
                      </div>
                      <FormHelperText
                        error={!!errors?.register_accepted?.message}
                      >
                        {errors?.register_accepted?.message}
                      </FormHelperText>
                    </div>

                    <Box paddingY={2}>
                      <Button
                        size="large"
                        disabled={formStatus === STATUS_SENDING}
                        variant="contained"
                        type="submit"
                        color="primary"
                      >
                        Register {formStatus === STATUS_SENDING && <Progress />}
                      </Button>
                    </Box>
                  </Form>
                </FormProvider>
              </ValidationProvider>
            )}

          {formStatus !== STATUS_SENT && (
            <Typography variant="caption" paragraph>
              <Anchor
                to="/auth/login"
                onClick={
                  typeof onLoginClick === 'function' ? onLoginClick : undefined
                }
              >
                Back to login
              </Anchor>
            </Typography>
          )}

          {formStatus === STATUS_SENT && (
            <Fragment>
              <Grid container spacing={1} justify="space-between">
                <Grid item sm={true}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/auth/login`}
                    onClick={
                      typeof onLoginClick === 'function'
                        ? onLoginClick
                        : undefined
                    }
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="reset"
                    onClick={() => {
                      reset();
                      setFormStatus('');
                      setMessage(null);
                    }}
                  >
                    <ReplayIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Box>
      </Paper>

      <Dialog
        open={termsOpen}
        onClose={handleTermsClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" disableTypography>
          <Typography variant="h4" component="h2" style={{ margin: 0 }}>
            Terms &amp; Conditions
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TermsAndConditions />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTermsClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
