// TODO: comment
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { getCountryValue, getInvestorTypeValue } from 'api';
import { useSecurity } from 'authentication';
import { Anchor } from 'components/core';
import { DataList } from 'components/data-list';
import { HelpBox } from 'components/help';
import { Breadcrumbs } from 'components/navigation';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, selectUserDetails } from 'redux/reducers/account';
import { delayFire, getSpecificFormattedDate } from 'utils';
import {
  useMessageComposerDispatch,
  useMessageComposerState,
} from 'utils/message-context';
import { AccountsListing } from './components';

const PageAccount = () => {
  const { t } = useTranslation(['common']);

  const { settings } = useSecurity();

  const dispatch = useDispatch();
  const details = useSelector(selectUserDetails);

  const [investorType, setInvestorType] = useState<string>('');
  const [country, setCountry] = useState<string>('');

  useEffect(() => {
    const load = async () => {
      const investorType = await getInvestorTypeValue(details.investorType);
      setInvestorType(investorType);

      const country = await getCountryValue(details.country);
      setCountry(country);
    };

    load();
  }, [details]);

  const messageDispatch = useMessageComposerDispatch();
  const { state } = useMessageComposerState();

  // when the user is fully authenticated, we need to fire a request to get the users details
  useEffect(() => {
    delayFire(() => {
      dispatch(getUserDetails());
    });
  }, []);

  const handleClick = (ev: React.MouseEvent) => {
    // @ts-ignore

    messageDispatch({
      type: state === 'open' ? 'minimised' : 'open',
    });

    ev.preventDefault();
  };

  // return the view
  return (
    <Fragment>
      <Helmet>
        <title>{t('common:titles.account')}</title>
      </Helmet>

      <Breadcrumbs
        trail={[{ label: 'Home', href: '/' }, { label: 'My Details' }]}
      />

      <Box paddingY={6}>
        <Container>
          <Typography variant="h3" component="h1">
            {t('common:titles.account')}
          </Typography>

          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box padding={3}>
                  <Typography variant="h4" component="h2">
                    Details
                  </Typography>

                  <DataList
                    items={{
                      Title: details.title,
                      Forename: details.forename,
                      Surname: details.surname,
                      Salutation: details.salutation,
                      'Preferred Name': details.preferredName,
                      Email: details.emailAddress,
                    }}
                  />

                  {details.lastLoginDate && (
                    <Box marginTop={6}>
                      <DataList
                        items={{
                          'Last Login': getSpecificFormattedDate(
                            details.lastLoginDate
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box padding={3}>
                  <Typography variant="h4" component="h2">
                    Locale
                  </Typography>
                  <DataList
                    items={{
                      Region: details.region,
                      Country: country,
                      'Investor Type': investorType,
                    }}
                  />
                </Box>
              </Paper>
              <HelpBox title="Need to update your details?">
                <Typography variant="body2">
                  If any of your details appear incorrect or have changed,
                  please{' '}
                  <Anchor onClick={handleClick} to="/message/new">
                    send us a message
                  </Anchor>{' '}
                  and we will update them for you.
                </Typography>
              </HelpBox>
            </Grid>
          </Grid>
        </Container>

        {!settings['ClientHoldings.Enabled'] && (
          <Box paddingTop={6}>
            <Container>
              <AccountsListing />
            </Container>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

export default PageAccount;
