// TODO: comment
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserDetails as getUserDetailsAPI } from 'api';
import { MyDetailsResponse } from 'api/types';

export const getUserDetails = createAsyncThunk(
  'user/details',
  async (_, { getState }) => {
    const result = await getUserDetailsAPI();
    return result;
  }
);

export interface userState {
  data: MyDetailsResponse;
  status: string;
}

const initialState: userState = {
  status: '',
  data: {
    country: '',
    countryId: 0,
    emailAddress: '',
    forename: '',
    investorType: '',
    logon: '',
    partyReference: '',
    preferredName: '',
    region: '',
    salutation: '',
    surname: '',
    title: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state, action) => {});
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {});
  },
});

export const selectUser = (state: { user: userState }) => state.user;

export const selectUserDetails = (state: any) => {
  return state.user.data as MyDetailsResponse;
};

export default userSlice.reducer;
