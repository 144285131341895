// TODO: comment
import { sendApiRequest } from 'api/utils';

/**
 * Represents the response to a request to create a new message
 */
export interface CreateMessageResponse {
  /**
   * Gets or sets the identifier of the new message
   */
  id: string;
}

export const createMessage = async (
  /**
   * The subject identifier
   */
  subjectId: string,

  /**
   * The body of the message
   */
  body: string,

  /**
   * Gets or sets the document identifiers to attach to this message
   */
  documentIds?: string[]
): Promise<CreateMessageResponse> => {
  const response = await sendApiRequest<CreateMessageResponse>(
    `${process.env.REACT_APP_API_URL}api/messages`,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        subjectId,
        body,
        documentIds,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
