// TODO: comment
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const DELETED_MESSSAGES_NAME: string = 'messages-deleted';

interface DeletedState {
  ids: string[];
}

const initialState = { ids: [] } as DeletedState;

const messageSlice = createSlice({
  name: DELETED_MESSSAGES_NAME,
  initialState,
  reducers: {
    addDeletedMessageId(state, action: PayloadAction<string>) {
      state.ids.push(action.payload);
    },
  },
});

export const { addDeletedMessageId } = messageSlice.actions;

export const selectDeletedIds = (state: any) => {
  return state[DELETED_MESSSAGES_NAME].ids;
};

export default messageSlice.reducer;
