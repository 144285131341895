// TODO: comment
import { sendApiRequest } from '../utils';
import { isAuthenticatedResponse } from './types';

/**
 * Verifies the supplied one-time password.
 * @param code
 * @returns
 */
export const verifyOTP = async (
  /**
   *
   */
  code: string
): Promise<isAuthenticatedResponse> => {
  const response = await sendApiRequest<isAuthenticatedResponse>(
    `${process.env.REACT_APP_API_URL}api/account/login/otp/verify`,
    null,
    {
      method: 'POST',
      body: JSON.stringify({
        code,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );

  return response;
};
