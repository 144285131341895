// TODO: comment
import { Grid, Paper, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Fund, FundlistingProps } from 'api/types';
import { Anchor } from 'components/core';
import React, { Fragment, useEffect, useState } from 'react';
import { CheckedState } from 'types';
import { SaveFund } from './';

interface Props {
  funds: FundlistingProps;
  // invested: string[];
  onChange(filters: string[]): void;
  onFundSave?(): void;
  saved: string[];
  selected: string[];
  small?: boolean;
  title: string;
}

const toObject = (arr: string[]): CheckedState => {
  const obj: any = {};

  arr.forEach((value: string) => {
    obj[value] = true;
  });

  return obj;
};

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
    border: '1px solid #D9D9D6',
    padding: theme.spacing(1),
  },
}));

export const FundListing = ({
  small = false,
  title,
  funds,
  selected,
  onChange,
  onFundSave,
  saved,
}: // invested,
Props) => {
  // maximum number of items that can be checked
  const MAX_SELECTED: number = 5;

  const [checked, setChecked] = useState<CheckedState>(toObject(selected));

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    // track the state of all filters
    const combined = { ...checked, [ev.target.name]: ev.target.checked };

    // update the state
    setChecked(combined);

    // convert the data to an array
    const identifiers = Object.keys(combined);
    // filter out the inactive filters
    const active = identifiers.filter((id) => combined[id]);

    // trigger the change event, so we can re-search
    onChange(active);
  };

  useEffect(() => {
    setChecked(toObject(selected));
  }, [selected]);

  const classes = useStyles();

  if (!funds || (funds && funds.length === 0)) {
    return null;
  }

  return (
    <Fragment>
      <Typography paragraph className="fund-listing-title">
        <span>{title}</span>
      </Typography>
      {funds.map(({ fundCode, fundName }: Fund, index: number) => {
        // should the fund be selected
        const isChecked: boolean = checked[fundCode] === true;

        // disable the field if it's not checked (so it can be deselected) and the max selected limit has been reached
        const isDisabled: boolean =
          !isChecked && selected.length >= MAX_SELECTED;

        return (
          <Paper key={`fund-${fundCode}`} className={classes.row}>
            {small ? (
              <Fragment>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={true}>
                    <Typography
                      component="h3"
                      variant="h5"
                      style={{ margin: 0 }}
                    >
                      <Anchor
                        to={`/portfolio-holdings/${fundCode}`}
                        color="primary"
                      >
                        {fundName}
                      </Anchor>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isChecked}
                      onChange={handleChange}
                      name={fundCode}
                      value={fundCode}
                      disabled={isDisabled}
                    />

                    {!process.env.REACT_APP_FEATURE_FUTURE && (
                      <SaveFund
                        fundCode={fundCode}
                        saved={saved.includes(fundCode)}
                        onChange={onFundSave}
                      />
                    )}
                  </Grid>
                </Grid>
                {process.env.REACT_APP_FEATURE_FUTURE && (
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>[ICAV]</Typography>
                    </Grid>

                    {typeof Storage !== 'undefined' && (
                      <Grid item>
                        <SaveFund
                          fundCode={fundCode}
                          saved={saved.includes(fundCode)}
                          onChange={onFundSave}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Fragment>
            ) : (
              <Grid
                container
                spacing={2}
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Checkbox
                    color="primary"
                    checked={isChecked}
                    onChange={handleChange}
                    name={fundCode}
                    value={fundCode}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={true}>
                  <Typography component="h3" variant="h5" style={{ margin: 0 }}>
                    <Anchor
                      to={`/portfolio-holdings/${fundCode}`}
                      color="primary"
                    >
                      {fundName}
                    </Anchor>
                  </Typography>
                </Grid>

                {process.env.REACT_APP_FEATURE_FUTURE && (
                  <Grid item>
                    <Typography>[ICAV]</Typography>
                  </Grid>
                )}

                {typeof Storage !== 'undefined' && (
                  <Grid item>
                    <SaveFund
                      fundCode={fundCode}
                      saved={saved.includes(fundCode)}
                      onChange={onFundSave}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {/* {process.env.REACT_APP_FEATURE_HOLDINGS_INVESTMENTS &&
              invested.includes(fundCode) && (
                <Box padding={2} className="invested">
                  <AccountBalanceWalletIcon />
                  <Typography display="inline">Invested</Typography>
                </Box>
              )} */}
          </Paper>
        );
      })}
    </Fragment>
  );
};
