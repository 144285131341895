// TODO: comment
import {
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from '@abrdn-latest/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDocuments } from 'api';
import { getDocumentsPerPage } from 'api/constants';
import { DocumentsRequest } from './documentsSlice';

export interface RecentDocumentsState {
  items: any;
  status: any;
}

export const getRecentDocuments = createAsyncThunk(
  'documents/get-recent',
  async ({
    start = 0,
    max = getDocumentsPerPage(),
    documentTypeCode,
  }: DocumentsRequest) => {
    const items = await getDocuments(start, max, documentTypeCode);

    return items;
  }
);

const initialState: RecentDocumentsState = {
  status: STATUS_LOADING,
  items: {
    pagination: {},
    documents: [],
  },
};

export const documentsSlice = createSlice({
  name: 'documents-recent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecentDocuments.pending, (state, action) => {
      state.status = STATUS_LOADING;
    });
    builder.addCase(getRecentDocuments.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = STATUS_SUCCESS;
    });
    builder.addCase(getRecentDocuments.rejected, (state, action) => {
      state.status = STATUS_FAILED;
    });
  },
});

export const selectRecentDocuments = (state: any) => {
  return state['recent-documents'].items.documents;
};

export const selectRecentDocumentsStatus = (state: any) => {
  return state['recent-documents'].status;
};

export default documentsSlice.reducer;
