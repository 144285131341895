// TODO: comment
import { InvestorTypeList } from 'api/types';
import { reverseLookup } from 'utils';
import { sendApiRequest } from '../utils';

/**
 * Get a list of investor types for user registration
 */
export const getInvestorTypes = async (): Promise<InvestorTypeList> => {
  const endpoint: string = `${process.env.REACT_APP_API_URL}api/account/registration/investor-types`;

  const response = await sendApiRequest<InvestorTypeList>(
    endpoint,
    null,
    {},
    true
  );
  return response;
};

/**
 *
 * @param value
 * @returns
 */
export const getInvestorTypeValue = async (value: string): Promise<string> => {
  const investorTypes = await getInvestorTypes();

  return reverseLookup(investorTypes, value, 'code', 'displayText');
};
