// TODO: comment
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseIcon } from 'icons';
import React, { Fragment, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './SlideOut.scss';

interface Props {
  /**
   * The body of the popup, this will most likely be paragraph copy
   */
  children: React.ReactNode;
  /**
   *
   */
  onClose: any;
  /**
   *
   */
  open: boolean;
  /**
   *
   */
  title: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: '#F8F8FA',
  },
}));

export const SlideOut = ({ title, open = false, children, onClose }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(open);

  const classes = useStyles();

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  return (
    <Fragment>
      <Drawer
        className="slideout"
        anchor="right"
        open={drawerOpen}
        onClose={onClose}
        PaperProps={{ className: classes.paper }}
      >
        <Paper>
          <Box className="slideout__head" paddingX={3} paddingY={2}>
            <IconButton
              aria-label="Close"
              onClick={() => {
                onClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Paper>

        <Box padding={2}>
          <Scrollbars autoHeight autoHeightMax={560}>
            <Box padding={2}>{children}</Box>
          </Scrollbars>
        </Box>

        {false && (
          <Paper className="slideout__buttons">
            <Box padding={2}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} sm={6}>
                  <Button
                    size="large"
                    variant="outlined"
                    fullWidth
                    onClick={() => {}}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button size="large" variant="contained" fullWidth>
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}
      </Drawer>
    </Fragment>
  );
};
