// TODO: comment
import { SecureRoute } from 'authentication';
import React, { Fragment } from 'react';
import PageESG from './PageESG';

export const ESGRoutes = () => {
  return (
    <Fragment>
      <SecureRoute exact path="/esg" component={PageESG} />
    </Fragment>
  );
};
